import { clientPrjApp } from '../config/FirebaseProject';
import { getCurrentTimestamp } from '../utils/DateTime';

// Create new share item
export const createNewShare = async (data) => {
  const createdDoc = await clientPrjApp.dbPrj.collection('sharedItems').add({
    ...data,
    modified: getCurrentTimestamp(),
  });

  return createdDoc;
};

// Update share item
export const updateShare = async (id, data) => {
  await clientPrjApp.dbPrj
    .collection('sharedItems')
    .doc(id)
    .update({
      ...data,
      modified: getCurrentTimestamp(),
    });
};

// Get list of shared items
export const getSharedItems = async (itemId, tableId) => {
  const rows = [];
  const tableName = 'sharedItems';

  const dataSnap = await clientPrjApp.dbPrj
    .collection(tableName)
    .where('itemId', '==', itemId)
    .where('tableId', '==', tableId)
    .get();

  dataSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      id: doc.id,
    });
  });

  return rows;
};
