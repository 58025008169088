import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteTable } from '../../../services/Tables';

// Delete a table
const DeleteTable = ({ rowData, handleSuccess, handleDismiss }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = () => {
    setIsSubmitted(true);

    const saveData = {
      tableId: rowData.id,
    };

    deleteTable(saveData)
      .then(() => {
        handleSuccess();
        setIsSubmitted(false);
      })
      .catch(() => {
        // something went wrong
        setIsSubmitted(false);
      });
  };

  const handleClose = () => {
    handleDismiss();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">Delete table</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are ya sure? All data from this table will be deleted and any references from other tables
          will be disconnected.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" disabled={isSubmitted} onClick={handleSubmit}>
          Delete table
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteTable.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default DeleteTable;
