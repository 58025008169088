import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteTableData } from '../../../services/Tables';

// Delete table rows
const DeleteRows = ({ handleSuccess, handleDismiss, tableId, collectionName, selectedRows }) => {
  const numOfRows = selectedRows.length || 0;
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = () => {
    setIsSubmitted(true);

    const selectedIds = [];
    selectedRows.forEach((r) => {
      selectedIds.push(r.id);
    });

    deleteTableData(selectedIds, collectionName, tableId)
      .then(() => {
        handleSuccess();
        setIsSubmitted(false);
      })
      .catch(() => {
        // something went wrong
        setIsSubmitted(false);
      });
  };

  const handleClose = () => {
    handleDismiss();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">Delete Selected Row(s)?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {numOfRows === 1 ? '1 row' : `${numOfRows} rows`}? This
          action can not be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" disabled={isSubmitted} onClick={handleSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteRows.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  tableId: PropTypes.string.isRequired,
  collectionName: PropTypes.string.isRequired,
  selectedRows: PropTypes.any.isRequired,
};

export default DeleteRows;
