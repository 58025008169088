import grey from '@material-ui/core/colors/grey';

// Style for data layout
const Style = () => ({
  wrapper: {
    minHeight: '50vh',
    padding: 0,
  },
  innerWrapper: {
    position: 'relative',
    padding: 0,
    '& .ag-header-cell': {
      backgroundColor: grey[50],
    },
    '& .ag-header-cell.ag-column-hover': {
      backgroundColor: grey[50],
    },
    '& .ag-header-cell.ag-column-hover.ag-header-active': {
      backgroundColor: grey[50],
    },
    '& .open-data': {
      cursor: 'pointer',
    },
    '& .open-data img': {
      display: 'none',
      paddingTop: 15,
      marginLeft: -10,
    },
    '& .ag-row-hover .open-data img': {
      display: 'block',
    },
    '& .ag-theme-material .ag-checkbox-input-wrapper': {
      fontSize: '16px',
      lineHeight: '16px',
      width: '16px',
      height: '16px',
    },
    '& .ag-theme-material .ag-status-bar': {
      border: 'none',
      paddingLeft: 0,
      paddingRight: 0,
      lineHeight: 1,
    },
    '& .ag-theme-material .ag-status-name-value': {
      paddingBottom: '8px',
    },
  },
});

export default Style;
