import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import formPopupStyle from '../../../../theme/styles/components/FormPopup';

const useStyles = makeStyles(formPopupStyle);

const HideFields = ({ handleSuccess, handleDismiss, gridApi }) => {
  const classes = useStyles();

  const [columnsList, setColumnsList] = useState([]);

  useEffect(() => {
    const columns = [];
    const gridColumns = gridApi.columnApi.getColumnState();

    gridColumns.forEach((obj) => {
      if (obj.colId !== 'rowCheck' && obj.colId !== 'fullView') {
        columns.push({
          id: obj.colId,
          name: gridApi.columnApi.getColumn(obj.colId).colDef.headerName,
          value: obj.hide,
          disabled: obj.rowGroup,
        });
      }
    });

    setColumnsList(columns);
  }, []);

  const handleClose = () => {
    handleDismiss();
  };

  const handleChange = (event) => {
    const colId = event.target.name;
    const valueToChange = !event.target.checked;

    const updatedColumnsList = [];
    columnsList.forEach((obj) => {
      updatedColumnsList.push({
        id: obj.id,
        name: obj.name,
        value: obj.id === colId ? valueToChange : obj.value,
        disabled: obj.disabled,
      });
    });

    setColumnsList(updatedColumnsList);
    handleSuccess(colId, valueToChange);
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">
        Hide Fields
        <IconButton
          aria-label="close"
          size="small"
          className={classes.viewToolCloseButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />

      <DialogContent className={classes.DialogContent}>
        <List>
          {columnsList.map((object) => (
            <ListItem
              button
              className={classes.rowItem}
              key={`colm-${object.id}`}
              disabled={object.disabled}
            >
              <ListItemText primary={object.name} />
              <ListItemSecondaryAction className={classes.rowActionContainer}>
                <Switch
                  checked={!object.value}
                  onChange={handleChange}
                  color="primary"
                  name={object.id}
                  disabled={object.disabled}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <Divider />

      <DialogActions className={classes.DialogActions}>
        <Button type="submit" color="primary">
          Hide all
        </Button>
        <Button type="submit" color="primary">
          Show all
        </Button>
      </DialogActions>
    </Dialog>
  );
};

HideFields.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  gridApi: PropTypes.any.isRequired,
};

export default HideFields;
