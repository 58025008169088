import React from 'react';
import Typography from '@material-ui/core/Typography';
import Main from '../../layout/Main';

const NotFound = () => {
  const pageHeading = 'Page Not Found';

  return (
    <Main pageHeading={pageHeading}>
      <Typography component="p">The page you&apos;re trying to access was not found.</Typography>
    </Main>
  );
};

export default NotFound;
