import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import projectStyle from '../../theme/styles/components/Project';

const useStyles = makeStyles(projectStyle);

// Placeholder for projects page
const ProjectPlaceholder = () => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.projectItem}>
        <CardHeader
          avatar={<Skeleton variant="circle" width={40} height={40} />}
          title={<Skeleton variant="text" />}
          titleTypographyProps={{ variant: 'body1' }}
          subheader={<Skeleton variant="text" />}
        />
        <Divider />
        <CardContent className={classes.placeholderContent}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Skeleton variant="text" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.placeholderActions}>
          <Skeleton variant="rect" width={100} height={40} />
          <Skeleton variant="rect" width={100} height={40} />
        </CardActions>
      </Card>
    </>
  );
};

export default ProjectPlaceholder;
