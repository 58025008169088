/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import axios from 'axios';
import { completeVideoUpload, getVideoUploadURL, startVideoUpload } from '../services/Video';

const uploadMultipartFile = async (selectedFile, params, progressCallback, uploadId) => {
  try {
    const FILE_CHUNK_SIZE = 1024 * 1024 * 5; // 5MB
    const fileSize = selectedFile.size;
    const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
    const promisesArray = [];
    let start;
    let end;
    let blob;
    let completedBytes = 0;

    for (let index = 1; index < NUM_CHUNKS + 1; index++) {
      start = (index - 1) * FILE_CHUNK_SIZE;
      end = index * FILE_CHUNK_SIZE;
      blob = index < NUM_CHUNKS ? selectedFile.slice(start, end) : selectedFile.slice(start);

      // (1) Generate presigned URL for each part
      const getUploadUrlResp = await getVideoUploadURL({
        fileName: params.fileName,
        partNumber: index,
        uploadId,
        projectId: params.projectId,
      });

      const { presignedUrl } = getUploadUrlResp;

      // (2) Puts each file part into the storage server
      const uploadResp = await axios.put(presignedUrl, blob, {
        headers: { 'Content-Type': selectedFile.type },
        onUploadProgress: (evt) => {
          const uploadedBytes = evt.loaded + completedBytes;
          progressCallback(uploadedBytes);
        },
      });

      // Updating the uploaded blob size
      completedBytes += blob.size;

      promisesArray.push(uploadResp);
    }

    const resolvedArray = await Promise.all(promisesArray);

    const uploadPartsArray = [];
    resolvedArray.forEach((resolvedPromise, index) => {
      uploadPartsArray.push({
        ETag: resolvedPromise.headers.etag,
        PartNumber: index + 1,
      });
    });

    // (3) Calls the completeVideoUpload endpoint in the backend server
    const completeUploadRes = await completeVideoUpload({
      fileName: params.fileName,
      parts: uploadPartsArray,
      uploadId,
      projectId: params.projectId,
    });

    return completeUploadRes;
  } catch (err) {
    return false;
  }
};

const videoUploadUtil = async (selectedFile, params, progressCallback) => {
  const result = await startVideoUpload(params);
  const uploadRes = await uploadMultipartFile(
    selectedFile,
    params,
    progressCallback,
    result.uploadId
  );
  return uploadRes;
};

export const convertBytesToGBs = (bytes) => parseFloat(bytes / 1073741824).toFixed(2);

export default videoUploadUtil;
