import axios from 'axios';
import { auth } from '../config/Firebase';
import { clientPrjApp } from '../config/FirebaseProject';
import { getCurrentTimestamp } from '../utils/DateTime';

// Create new video
export const createVideo = async (data) => {
  await clientPrjApp.dbPrj.collection('videos').add({
    ...data,
    modified: getCurrentTimestamp(),
  });
};

// Update video
export const updateVideo = async (id, data) => {
  await clientPrjApp.dbPrj
    .collection('videos')
    .doc(id)
    .update({
      ...data,
      modified: getCurrentTimestamp(),
    });
};

// Video details
export const getVideoDetails = async (id) => {
  const video = await clientPrjApp.dbPrj.collection('videos').doc(id).get();
  return { ...video.data(), id };
};

// Start video upload
export const startVideoUpload = async (params) => {
  const snapConfig = await clientPrjApp.dbPrj.collection('configs').doc('cfUrl').get();
  const snapData = snapConfig.data();

  const apiEndpoint = snapData.url;
  const result = await axios.get(`${apiEndpoint}/api/video/start-upload`, {
    params,
  });
  return result.data;
};

// Get upload URL
export const getVideoUploadURL = async (params) => {
  const snapConfig = await clientPrjApp.dbPrj.collection('configs').doc('cfUrl').get();
  const snapData = snapConfig.data();

  const apiEndpoint = snapData.url;
  const result = await axios.get(`${apiEndpoint}/api/video/get-upload-url`, {
    params,
  });
  return result.data;
};

// Complete upload
export const completeVideoUpload = async (params) => {
  const snapConfig = await clientPrjApp.dbPrj.collection('configs').doc('cfUrl').get();
  const snapData = snapConfig.data();

  const apiEndpoint = snapData.url;
  const result = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/video/complete-upload`,
    withCredentials: true,
    data: {
      params,
    },
  });
  return result.data;
};

// Create M3U8 and MP4 Job for Original Video File
export const createAWSOrgVidJobs = async (data) => {
  const snapConfig = await clientPrjApp.dbPrj.collection('configs').doc('cfUrl').get();
  const snapData = snapConfig.data();

  const apiEndpoint = snapData.url;
  const result = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/video/create-job-org`,
    withCredentials: true,
    data: { ...data, operator: clientPrjApp.dbPrjAuth.currentUser?.uid || auth.currentUser.uid },
  });
  return result.data;
};

// Create M3U8 and MP4 Job to Trim Video File
export const createAWSTrimVidJobs = async (data) => {
  const snapConfig = await clientPrjApp.dbPrj.collection('configs').doc('cfUrl').get();
  const snapData = snapConfig.data();

  const apiEndpoint = snapData.url;
  const result = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/video/create-job-trim`,
    withCredentials: true,
    data: { ...data, operator: clientPrjApp.dbPrjAuth.currentUser?.uid || auth.currentUser.uid },
  });
  return result.data;
};
