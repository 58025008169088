// Style for sidebar
const Style = (theme) => ({
  tableDrawer: {
    background: theme.palette.grey[50],
  },
  logoContainer: {
    width: 260,
    textAlign: 'left',
    padding: '24px',
  },
  logo: {
    maxWidth: '100px',
    height: 'auto',
  },
  sidebarTitle: {
    fontWeight: 600,
  },
  sidebarItem: {
    fontWeight: '400',
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0',
    marginBottom: '18px',
  },
  separator: {
    marginTop: '20px',
    marginBottom: '32px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    width: '216px',
  },
  tableGroup: {
    width: 260,
    position: 'relative',
    padding: '0 24px',
  },
  listItem: {
    padding: '3px 16px 3px 16px',
    borderRadius: '8px',
    marginBottom: '8px',
    '& svg': {
      color: theme.palette.grey[700],
      marginRight: '16px',
    },
  },

  listItemContainer: {
    '&:hover': {
      '& $actionButton': {
        color: theme.palette.grey[600],
      },
    },
  },
  itemActionContainer: {
    right: 25,
  },
  actionButton: {
    padding: '5px 4px',
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.grey[600],
    },
    '& svg': {
      fontSize: '1rem',
    },
  },
  actionAddButton: {
    padding: 0,
  },
});

export default Style;
