const shareTypes = {
  1: 'Link',
  Link: 1,
  2: 'API',
  API: 2,
};
const shareStatus = {
  1: 'Published',
  Published: 1,
  2: 'Unpublished',
  Unpublished: 2,
};
const sharePrivacy = {
  1: 'Public',
  Public: 1,
  2: 'Password',
  Password: 2,
};

export { shareTypes, shareStatus, sharePrivacy };
