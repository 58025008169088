import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import videojs from 'video.js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import { auth } from '../../../config/Firebase';
import { createDateString, convertSecondsToTime } from '../../../utils/DateTime';
import { createNewShare, getSharedItems, updateShare } from '../../../services/ShareItem';
import { getProjectConfig } from '../../../services/Projects';
import { ReactComponent as ShareIcon } from '../../../assets/images/share.svg';
import { ReactComponent as InIcon } from '../../../assets/images/in.svg';
import { ReactComponent as OutIcon } from '../../../assets/images/out.svg';
import { ReactComponent as EyeIcon } from '../../../assets/images/eye.svg';
import ShareRow from './ShareRow';
import SelectColumns from '../../share/SelectColumns';
import SelectMarkers from '../../share/SelectMarkers';
import Markers from '../video/Markers';
import VideoPlayer from '../video/Player';
import formPopupStyle from '../../../theme/styles/components/FormPopup';
import PlaylistPlayer from '../video/PlaylistPlayer';
import PlaylistItems from '../../playliist/PlaylistItems';
import { getVideoMarkers } from '../../../services/Markers';
import UserMenu from '../../layout/UserMenu';
import getRandString from '../../../utils/RandString';

import {
  getTableDetailsFromTableName,
  saveTableData,
  getTableDetails,
} from '../../../services/Tables';
import { getTableColumns } from '../../../services/Columns';
import { sharePrivacy, shareStatus, shareTypes } from '../../../config/ShareConfigs';

const useStyles = makeStyles(formPopupStyle);

// Full view details of row
const FullDetail = ({ row, handleDismiss, projectId, tableId }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState('');
  const [videoCurrentTime, setVideoCurrentTime] = useState(0);
  const [playerElement, setPlayerElement] = useState(null);
  const [playlistPlayerElement, setPlaylistPlayerElement] = useState(null);
  const [playlistItems, setPlaylistItems] = useState(row.data.plItemsDetail);
  const [showPlayer, setShowPlayer] = useState(true);
  const [sharedAmount, setSharedAmount] = useState(0);
  const [markersAmount, setMarkersAmount] = useState(0);
  const [addMarkerActive, setAddMarkerActive] = useState(false);
  const [addShareActive, setAddShareActive] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [selectColumnModel, setSelectColumnModel] = useState(false);
  const [selectMarkerModel, setSelectMarkerModel] = useState(false);
  const [reloadRows, setReloadRows] = useState(false);
  const [allMarkers, setAllMarkers] = useState([]);
  const sessionUserName = auth.currentUser.displayName || '';

  const defaultShare = {
    shareDate: createDateString(new Date()),
    type: 1,
    name: '',
    privacy: 1,
    sharedBy: sessionUserName,
    status: 1,
  };

  const [newShare, setNewShare] = useState(defaultShare);

  const videoId = row.data.id;
  const videoName = row.data.name;
  const videoTotalTimeSec = row.data.durationSeconds || 0;
  const videoData = row.data;

  const defaultNewMarker = {
    name: '',
    startTime: 0,
    startTimeAdded: true,
    endTime: videoTotalTimeSec,
    endTimeAdded: !!videoTotalTimeSec,
    duration: videoTotalTimeSec,
    track: false,
    category: '',
  };

  const handleShareNameChange = (e) =>
    setNewShare({
      ...newShare,
      name: e.target.value,
    });

  const handlePrivacyChange = (event) =>
    setNewShare({
      ...newShare,
      privacy: event.target.value,
    });

  const handleInfoModel = (e, action) => {
    e.preventDefault();
    if (action === 'markers') {
      setSelectMarkerModel(true);
    } else {
      setSelectColumnModel(true);
    }
  };

  const isVideoShare = row.collectionName === 'videos';

  const setAllSelectedMarkers = (data) => {
    const defaultSelectedMarkers = data.map((c) => c.name);
    setSelectedMarkers(defaultSelectedMarkers);
  };

  const getData = () => {
    const retData = {};

    if (isVideoShare) {
      retData.displayName = row.data.displayName;
      retData.name = row.data.name;
      retData.hlsLink = row.data.hlsLink;
      retData.id = row.data.id;
      retData.image = row.data.image;
    }
    row.colsArray.forEach((col) => {
      if (selectedColumns.includes(col.name)) {
        retData[col.name] = row.data[col.name] || '';
      }
    });

    return retData;
  };

  const getSelectedMarkersInfo = () => {
    const retData = [];
    allMarkers.forEach((m) => {
      if (selectedMarkers.includes(m.name)) {
        retData.push({
          displayName: m.displayName,
          durationSeconds: m.durationSeconds,
          endTime: m.endTime,
          endTimeSeconds: m.endTimeSeconds,
          startTime: m.startTime,
          startTimeSeconds: m.startTimeSeconds,
          id: m.id,
          name: m.name,
        });
      }
    });
    return retData;
  };

  const getSharedCols = () => {
    const retData = [];
    row.colsArray.forEach((col) => {
      if (selectedColumns.includes(col.name)) {
        retData.push({ name: col.name, displayName: col.displayName, type: col.type });
      }
    });
    return retData;
  };

  const setAllSelectedCols = () => {
    const defaultSelectedCols = row.colsArray.map((c) => c.name);
    setSelectedColumns(defaultSelectedCols);
  };

  const createShare = async () => {
    const rand = getRandString(4);
    const tableName = await getTableDetails(tableId);
    const sharedColumns = getSharedCols();

    const payload = {
      itemId: row.data.id,
      tableId,
      tableName: tableName.displayName,
      displayName: newShare.name,
      name: `${newShare.name}_${rand}`,
      data: getData(),
      sharedColumns,
      sharedColumnsCount: sharedColumns.length,
      privacy: sharePrivacy[newShare.privacy],
      type: shareTypes[newShare.type],
      sharedBy: newShare.sharedBy,
      isVideoShare,
      status: shareStatus[newShare.status],
      shareDate: createDateString(newShare.shareDate),
      rand,
    };

    if (isVideoShare) {
      const sharedMarkers = getSelectedMarkersInfo();
      payload.sharedMarkers = getSelectedMarkersInfo();
      payload.sharedMarkersCount = sharedMarkers.length;
    }

    const createdShare = await createNewShare(payload);
    const projConfigs = await getProjectConfig();
    await updateShare(createdShare.id, {
      url: `${projConfigs.FireBaseWebAppUrl}/share/${createdShare.id}`,
    });

    setNewShare(defaultShare);

    if (isVideoShare) {
      setSelectedMarkers([]);
    } else {
      setAllSelectedCols();
    }
    setReloadRows(!reloadRows);
  };

  const hanldeSelectedColumnChange = (updatedCols) => {
    setSelectedColumns(updatedCols);
  };

  const hanldeSelectedMarkerChange = (updatedMarkers) => {
    setSelectedMarkers(updatedMarkers);
  };

  const [reloadMarkerList, setReloadMarkerList] = useState(1);
  const [newMarker, setNewMarker] = useState(defaultNewMarker);

  const handleSaveMarker = async () => {
    const rand = getRandString(4);

    const saveData = {
      displayName: newMarker.name,
      name: `${videoName}_${newMarker.name}_${rand}`,
      notes: '',
      type: newMarker.track ? 'Track' : 'Note',
      category: newMarker.category,
      startTime: convertSecondsToTime(newMarker.startTime),
      endTime: convertSecondsToTime(newMarker.endTime),
      duration: convertSecondsToTime(newMarker.duration),
      track: newMarker.track ? 'Yes' : 'No',
      video: { ...videoData, value: videoId, label: videoName },
      trimVideo: '',
    };

    const getTableId = await getTableDetailsFromTableName('markers');
    const resColumns = await getTableColumns(getTableId[0].id);
    const allColumns = [];

    resColumns.forEach((obj) => {
      allColumns.push({
        id: obj.id,
        type: obj.type,
        name: obj.name,
        refType: obj.refType,
        relation: obj.relation,
      });
    });

    saveTableData(saveData, 'markers', allColumns, {
      startTimeSeconds: newMarker.startTime || 0,
      endTimeSeconds: newMarker.endTime,
      durationSeconds: newMarker.duration,
    })
      .then(() => {
        setNewMarker({
          ...defaultNewMarker,
          startTime: videoCurrentTime,
          track: newMarker.track,
          category: newMarker.category,
        });
        setReloadMarkerList(reloadMarkerList + 1);
      })
      .catch(() => {
        setNewMarker(defaultNewMarker);
      });
  };

  const handleNewMarkerName = (e) => {
    setNewMarker({
      ...newMarker,
      name: e.target.value,
    });
  };
  const handleNewMarkerCategory = (e) => {
    setNewMarker({
      ...newMarker,
      category: e.target.value,
    });
  };
  const handleTrackChange = (e) => {
    setNewMarker({
      ...newMarker,
      track: e.target.checked,
      category: e.target.checked ? newMarker.category : '',
    });
  };
  const handleStartTime = () => {
    setNewMarker({
      ...newMarker,
      startTime: videoCurrentTime,
      startTimeAdded: true,
      duration: newMarker.endTime - videoCurrentTime,
    });
  };
  const handleStartTimeInc = (byVal) => {
    videojs('video-player').currentTime(newMarker.startTime + byVal);

    setNewMarker({
      ...newMarker,
      startTime: newMarker.startTime + byVal,
      startTimeAdded: true,
      duration: newMarker.endTime - newMarker.startTime + byVal,
    });
  };
  const handleEndTime = () => {
    setNewMarker({
      ...newMarker,
      endTime: videoCurrentTime,
      endTimeAdded: true,
      duration: videoCurrentTime - newMarker.startTime,
    });
  };
  const handleEndTimeInc = (byVal) => {
    videojs('video-player').currentTime(newMarker.endTime + byVal);

    setNewMarker({
      ...newMarker,
      endTime: newMarker.endTime + byVal,
      endTimeAdded: true,
      duration: newMarker.endTime + byVal - newMarker.startTime,
    });
  };

  const seekToTime = (duration) => {
    if (playerElement) {
      playerElement.currentTime(duration);
    }
  };

  const openAddMarker = () => {
    setAddMarkerActive(true);
  };

  const closeAddMarker = () => {
    setAddMarkerActive(false);
  };

  const openAddShare = () => {
    setAddShareActive(true);
  };

  const closeAddShare = () => {
    setAddShareActive(false);
  };

  useEffect(() => {
    if (playerElement) {
      if (row.collectionName === 'markers' || row.collectionName === 'playlistItems') {
        seekToTime(row.data.startTimeSeconds);
      }
    }
  }, [playerElement]);

  useEffect(() => {
    getSharedItems(row.data.id, tableId).then((data) => {
      setSharedAmount(data.length);
    });
    getVideoMarkers(row.data.id).then((data) => {
      setAllMarkers(data);
      setMarkersAmount(data.length);
      setAllSelectedMarkers(data);
    });
    setAllSelectedCols();
  }, [reloadRows, reloadMarkerList]);

  const handleClose = () => {
    handleDismiss();
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const toggleInfo = () => {
    if (tabValue === 'info') {
      setTabValue(null);
    } else {
      setTabValue('info');
    }
  };
  const toggleMarkers = () => {
    if (tabValue === 'markers') {
      setTabValue(null);
    } else {
      setTabValue('markers');
    }
  };
  const toggleShare = () => {
    if (tabValue === 'share') {
      setTabValue(null);
    } else {
      setTabValue('share');
    }
  };
  const togglePlItems = () => {
    if (tabValue === 'plItems') {
      setTabValue(null);
    } else {
      setTabValue('plItems');
    }
  };

  const handleUpdatePlayerCurrentTime = (val) => {
    setVideoCurrentTime(val);
  };

  const showPlayerCollectionNames = ['video', 'videos', 'markers', 'playlistItems'];
  const withCreate = tabValue === 'markers' || tabValue === 'share';
  return (
    <Dialog
      open
      fullScreen
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title" className={classes.videoDialogTitle}>
        <Grid container direction="column">
          <Grid container justify="space-between">
            <Grid item xs={6} sm={6}>
              <Grid container className={classes.titleContainer}>
                <IconButton
                  edge="start"
                  size="small"
                  onClick={handleClose}
                  aria-label="close"
                  className={classes.dialogCloseButton}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h1" color="textPrimary" className={classes.title}>
                  {row.data.displayName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Grid container className={classes.headerToolsContainer} justify="flex-end">
                <IconButton
                  aria-label="info"
                  className={`${classes.infoButton} ${tabValue === 'info' ? 'active' : ''}`}
                  onClick={toggleInfo}
                >
                  <InfoIcon />
                </IconButton>
                {(row.collectionName === 'video' || row.collectionName === 'videos') && (
                  <IconButton
                    aria-label="markers"
                    className={`${classes.markersButton} ${tabValue === 'markers' ? 'active' : ''}`}
                    onClick={toggleMarkers}
                  >
                    <LocationOnIcon />
                    <span className={classes.headerButtonLabel}>{markersAmount}</span>
                  </IconButton>
                )}
                {row.collectionName === 'playlists' && (
                  <IconButton
                    aria-label="plItems"
                    className={`${classes.markersButton} ${tabValue === 'plItems' ? 'active' : ''}`}
                    onClick={togglePlItems}
                  >
                    <PlaylistPlayIcon />
                    <span className={classes.headerButtonLabel}>{playlistItems.length}</span>
                  </IconButton>
                )}
                <IconButton
                  aria-label="share"
                  className={`${classes.shareButton} ${tabValue === 'share' ? 'active' : ''}`}
                  onClick={toggleShare}
                >
                  <ShareIcon />
                  <span className={classes.headerButtonLabel}>{sharedAmount}</span>
                </IconButton>
                <UserMenu
                  extraClasses={{ userIcon: classes.userIcon, userMenu: classes.userMenu }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.videoDialogContent}>
        <Grid container className={classes.mainSection}>
          <Grid container direction="column" className={classes.leftColumn}>
            {showPlayerCollectionNames.includes(row.collectionName) && (
              <div className={`${classes.videoPlayer} ${withCreate ? 'with-create' : ''}`}>
                <VideoPlayer
                  src={row.data.hlsLink}
                  poster={row.data.image}
                  updateCurrentTime={handleUpdatePlayerCurrentTime}
                  playerElement={(elem) => setPlayerElement(elem)}
                />
              </div>
            )}
            {row.collectionName === 'playlists' && playlistItems.length > 0 && (
              <div className={`${classes.videoPlayer} ${withCreate ? 'with-create' : ''}`}>
                {showPlayer && (
                  <PlaylistPlayer
                    playlistItems={playlistItems}
                    setPlaylistPlayerElement={setPlaylistPlayerElement}
                  />
                )}
              </div>
            )}
            {tabValue === 'markers' && (
              <Grid
                container
                direction="column"
                className={`${classes.addMarkerContainer} ${addMarkerActive ? 'active' : ''}`}
              >
                <Grid item xs={12} className={classes.mobileTimeCodeColumn}>
                  <Grid container justify="space-between" wrap="nowrap">
                    <Grid container direction="column" alignItems="center" wrap="nowrap">
                      <Grid item xs={12} className={classes.timeIconContainer}>
                        <div className={classes.inIconContainer}>
                          <InIcon />
                        </div>
                        <div className={classes.timeArrowsContainer}>
                          <ExpandLessIcon />
                          <ExpandMoreIcon />
                        </div>
                        <TextField
                          id="startTime"
                          label="IN"
                          variant="outlined"
                          size="small"
                          onClick={handleStartTime}
                          value={convertSecondsToTime(newMarker.startTime)}
                          margin="none"
                          disabled
                          className={`${classes.timeInput} ${classes.startInput}`}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justify="center"
                      wrap="nowrap"
                      style={{
                        padding: '0 2px',
                      }}
                    >
                      <ArrowRightAltIcon className={classes.timeArrowIcon} />
                    </Grid>
                    <Grid container direction="column" alignItems="center" wrap="nowrap">
                      <Grid item xs={12} className={classes.timeIconContainer}>
                        <TextField
                          id="endTime"
                          value={convertSecondsToTime(newMarker.endTime)}
                          onClick={handleEndTime}
                          label="OUT"
                          variant="outlined"
                          size="small"
                          className={`${classes.timeInput} ${classes.endInput}`}
                        />
                        <div className={classes.timeArrowsContainer}>
                          <ExpandLessIcon />
                          <ExpandMoreIcon />
                        </div>
                        <div className={classes.outIconContainer}>
                          <OutIcon />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '8px' }}>
                  <Grid container>
                    <Grid item xs={12} md={6} className={classes.textContainer}>
                      <TextField
                        classes={{ root: classes.text, notchedOutline: classes.outline }}
                        variant="outlined"
                        size="small"
                        id="name"
                        placeholder="Marker Name"
                        value={newMarker.name}
                        onChange={handleNewMarkerName}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className={classes.textContainer}
                      style={{ textAlign: 'right' }}
                    >
                      <TextField
                        id="category"
                        placeholder="Category (optional)"
                        variant="outlined"
                        size="small"
                        className={classes.text}
                        defaultValue={newMarker.category}
                        onBlur={handleNewMarkerCategory}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '10px' }}>
                  <Grid container className={classes.addMarkerTimeRow}>
                    <Grid item xs={8} md={5} className={classes.timeCodeColumn}>
                      <Grid container justify="space-between" wrap="nowrap">
                        <Grid container direction="column" alignItems="center" wrap="nowrap">
                          <Grid item xs={12} className={classes.timeIconContainer}>
                            <div className={classes.inIconContainer}>
                              <InIcon onClick={handleStartTime} />
                            </div>
                            <div className={classes.timeArrowsContainer}>
                              <ExpandLessIcon onClick={() => handleStartTimeInc(1)} />
                              <ExpandMoreIcon onClick={() => handleStartTimeInc(-1)} />
                            </div>
                            <TextField
                              id="startTime"
                              label="IN"
                              variant="outlined"
                              size="small"
                              value={convertSecondsToTime(newMarker.startTime)}
                              margin="none"
                              disabled
                              className={`${classes.timeInput} ${classes.startInput}`}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justify="center"
                          wrap="nowrap"
                          style={{
                            padding: '0 2px',
                          }}
                        >
                          <ArrowRightAltIcon className={classes.timeArrowIcon} />
                        </Grid>
                        <Grid container direction="column" alignItems="center" wrap="nowrap">
                          <Grid item xs={12} className={classes.timeIconContainer}>
                            <TextField
                              id="endTime"
                              value={convertSecondsToTime(newMarker.endTime)}
                              label="OUT"
                              variant="outlined"
                              size="small"
                              className={`${classes.timeInput} ${classes.endInput}`}
                              margin="none"
                              disabled
                            />
                            <div className={classes.timeArrowsContainer}>
                              <ExpandLessIcon onClick={() => handleEndTimeInc(1)} />
                              <ExpandMoreIcon onClick={() => handleEndTimeInc(-1)} />
                            </div>
                            <div className={classes.outIconContainer}>
                              <OutIcon onClick={handleEndTime} />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={2} className={classes.addDurationTotalColumn}>
                      <Grid container className={classes.durationContainer}>
                        <Typography variant="body1" className={classes.durationLabel}>
                          Duration
                        </Typography>
                        <Typography variant="body1" className={classes.durationValue}>
                          {convertSecondsToTime(newMarker.duration)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={2} className={classes.addTrackColumn}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="textPrimary"
                            name="track"
                            checked={newMarker.checked}
                            onChange={handleTrackChange}
                            className={classes.addTrackCheckbox}
                          />
                        }
                        className={classes.addTrackLabel}
                        label="Track"
                      />
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.submitButtonsContainer}>
                      <Button
                        className={classes.closeAddButton}
                        variant="contained"
                        onClick={closeAddMarker}
                      >
                        CANCEL
                      </Button>
                      <Button
                        className={classes.submitAddButton}
                        variant="contained"
                        onClick={handleSaveMarker}
                        disabled={!newMarker.name || !newMarker.startTimeAdded}
                      >
                        ADD MARKER
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {tabValue === 'share' && (
              <Grid
                container
                direction="column"
                className={`${classes.addShareContainer} ${addShareActive ? 'active' : ''}`}
              >
                <Grid container className={classes.addShareEditorContainer}>
                  <Grid item xs={8} className={classes.addShareCol1}>
                    <Grid container direction="column" className={classes.addShareInputsColumn}>
                      <TextField
                        id="share-name"
                        placeholder="Share Link Name"
                        classes={{ root: classes.shareText, notchedOutline: classes.outline }}
                        variant="outlined"
                        size="small"
                        value={newShare.name}
                        onChange={handleShareNameChange}
                      />
                      <Grid container>
                        <Grid item xs={6}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            size="small"
                          >
                            <InputLabel>Privacy</InputLabel>
                            <Select
                              value={newShare.privacy}
                              onChange={handlePrivacyChange}
                              label="Privacy"
                              className={classes.select}
                            >
                              <MenuItem value={1}>Public</MenuItem>
                              <MenuItem value={2}>Password</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid container xs={6} justify="flex-end">
                          <TextField
                            id="share-name"
                            type="password"
                            placeholder="Password"
                            classes={{ root: classes.password, notchedOutline: classes.outline }}
                            variant="outlined"
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="toggle password visibility">
                                    <EyeIcon style={{ opacity: 0.25 }} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} className={classes.addShareCol2}>
                    <Grid container direction="column" className={classes.addShareBadgesColumn}>
                      <IconButton
                        aria-label="info"
                        className={classes.addShareColumns}
                        onClick={(e) => handleInfoModel(e, 'columns')}
                      >
                        <InfoIcon />
                        <span className={classes.shareMobileOnly}>Info</span>
                        {selectedColumns.length}
                      </IconButton>
                      <IconButton
                        aria-label="markers"
                        className={classes.addShareMarkers}
                        onClick={(e) => handleInfoModel(e, 'markers')}
                      >
                        <LocationOnIcon />
                        <span className={classes.shareMobileOnly}>Markers</span>
                        {selectedMarkers.length}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} className={classes.addShareButtonsColumn}>
                    <Button
                      className={classes.shareCloseAddButton}
                      variant="contained"
                      onClick={closeAddShare}
                    >
                      CANCEL
                    </Button>
                    <Button
                      className={classes.shareSubmitAddButton}
                      variant="contained"
                      onClick={createShare}
                    >
                      ADD SHARE LINK
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={0}
            className={`${classes.tabContentContainer} ${tabValue ? '' : classes.tabContentHidden}`}
          >
            <Grid container spacing={0} className={classes.tabsContainer}>
              <Grid item xs={12}>
                <Tabs
                  value={tabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleTabChange}
                  classes={{ root: classes.tabs, fixed: classes.tabsFixed }}
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                >
                  <Tab className={classes.tab} label="Info" value="info" />
                  {(row.collectionName === 'video' || row.collectionName === 'videos') && (
                    <Tab className={classes.tab} label="Markers" value="markers" />
                  )}
                  {row.collectionName === 'playlists' && (
                    <Tab className={classes.tab} label="Items" value="plItems" />
                  )}
                  <Tab className={classes.tab} label="Share" value="share" />
                </Tabs>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {tabValue === 'info' && (
                <>
                  <Table className={classes.table}>
                    <TableBody className={classes.tableBodyNoHeader}>
                      {row.colsArray.map((col) => (
                        <>
                          <TableRow key={`${col.name}-label`}>
                            <TableCell className={`${classes.cellReset} ${classes.cellLabel}`}>
                              {col.displayName}
                            </TableCell>
                          </TableRow>
                          <TableRow key={`${col.name}-value`}>
                            <TableCell className={classes.cellReset}>
                              {Array.isArray(row.data[col.name])
                                ? row.data[col.name].join(', ')
                                : row.data[col.name] || ''}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}

              {tabValue === 'share' && (
                <ShareRow
                  rowData={row.data}
                  tableId={tableId}
                  columns={row.colsArray}
                  isVideoShare={row.collectionName === 'videos'}
                  openAddShare={openAddShare}
                  isAddShareOpen={addShareActive}
                  reloadListProp={reloadRows}
                />
              )}
              {selectColumnModel && (
                <SelectColumns
                  selectedCols={selectedColumns}
                  columns={row.colsArray}
                  handleChange={hanldeSelectedColumnChange}
                  onClose={() => {
                    setSelectColumnModel(false);
                  }}
                />
              )}

              {selectMarkerModel && (
                <SelectMarkers
                  selectedMarkers={selectedMarkers}
                  markers={allMarkers}
                  handleChange={hanldeSelectedMarkerChange}
                  onClose={() => {
                    setSelectMarkerModel(false);
                  }}
                />
              )}
              {tabValue === 'markers' && (
                <Markers
                  videoId={row.data.id}
                  videoName={row.data.name}
                  currentTime={videoCurrentTime}
                  videoTotalTimeSec={row.data.durationSeconds || 0}
                  videoData={row.data}
                  seekToStartTime={seekToTime}
                  projectId={projectId}
                  reloadListProp={reloadMarkerList}
                  openAddMarker={openAddMarker}
                  isAddMarkerOpen={addMarkerActive}
                />
              )}

              {tabValue === 'plItems' && (
                <PlaylistItems
                  items={playlistItems}
                  plId={row.data.id}
                  setPlaylistItems={setPlaylistItems}
                  updatePlayer={() => {
                    playlistPlayerElement.dispose();
                    setShowPlayer(false);
                    setShowPlayer(true);
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

FullDetail.propTypes = {
  row: PropTypes.any.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  tableId: PropTypes.string.isRequired,
};

export default FullDetail;
