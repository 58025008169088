import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ShareIcon from '@material-ui/icons/ShareOutlined';
import TableChart from '@material-ui/icons/TableChartOutlined';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import CreateTable from '../data/tables/CreateTable';
import RenameTable from '../data/tables/RenameTable';
import DeleteTable from '../data/tables/DeleteTable';
import { getAllTables } from '../../services/Tables';
import TableTypes from '../../config/TableTypes';
import logo from '../../assets/images/logo.png';
import sidebarDataStyle from '../../theme/styles/components/DataSidebar';

const useStyles = makeStyles(sidebarDataStyle);

const getIconForObject = (object) => {
  switch (object.name) {
    case 'sharedItems':
      return <ShareIcon />;
    case 'videos':
      return <OndemandVideoIcon />;
    case 'markers':
      return <LocationOnIcon />;
    case 'playlists':
      return <PlaylistPlayIcon />;
    default:
      return <TableChart />;
  }
};

// Displaying data sidebar (table listing)
const DataSidebar = ({ dataSidebarOpen, projectId, tableId, handleDataSidebar }) => {
  const classes = useStyles();
  const history = useHistory();

  const [mediaTables, setMediaTables] = useState([]);
  const [myTables, setMyTables] = useState([]);
  const [reportsTables, setReportsTables] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showRenameForm, setShowRenameForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  useEffect(() => {
    getAllTables().then((res) => {
      const tmpMediaTables = [];
      const tmpMyTables = [];
      const tmpReportsTables = [];

      res.forEach((row) => {
        if (row.section === TableTypes.MEDIA) {
          tmpMediaTables.push(row);
        } else if (row.section === TableTypes.DATA) {
          tmpMyTables.push(row);
        } else if (row.section === TableTypes.REPORTS) {
          tmpReportsTables.push(row);
        }
      });

      setMediaTables(tmpMediaTables);
      setMyTables(tmpMyTables);
      setReportsTables(tmpReportsTables);
    });
  }, [reloadData]);

  const handleClose = () => {
    handleDataSidebar();
  };

  const handleLink = (page) => {
    history.push(`/project/${projectId}/${page}`);
    handleDataSidebar();
  };

  const handleShowAddForm = () => {
    setShowAddForm(true);
  };
  const handleCloseAddForm = () => {
    setShowAddForm(false);
  };
  const handleCloseAddFormSuccess = () => {
    setReloadData(!reloadData);
    setShowAddForm(false);
  };

  const handleShowRenameForm = (val) => {
    setSelectedRecord(val);
    setShowRenameForm(true);
  };
  const handleCloseRenameForm = () => {
    setShowRenameForm(false);
    setSelectedRecord(null);
  };
  const handleCloseRenameFormSuccess = () => {
    setReloadData(!reloadData);
    setShowRenameForm(false);
    setSelectedRecord(null);
  };

  const handleShowDeleteForm = (val) => {
    setSelectedRecord(val);
    setShowDeleteForm(true);
  };
  const handleCloseDeleteForm = () => {
    setShowDeleteForm(false);
    setSelectedRecord(null);
  };
  const handleCloseDeleteFormSuccess = () => {
    setReloadData(!reloadData);
    setShowDeleteForm(false);

    if (selectedRecord.id === tableId) {
      window.localStorage.removeItem(`${projectId}_table`);
      history.push(`/project/${projectId}`);
    } else {
      setSelectedRecord(null);
    }
  };

  return (
    <>
      <Drawer
        BackdropProps={{ invisible: true }}
        open={dataSidebarOpen}
        onClose={handleClose}
        classes={{ paper: classes.tableDrawer }}
      >
        <div className={classes.logoContainer}>
          <img src={logo} alt={process.env.REACT_APP_NAME} className={classes.logo} />
        </div>

        <div className={classes.tableGroup}>
          <Box component="div" m={1} className={classes.sectionTitle}>
            <Typography variant="h6" className={classes.sidebarTitle}>
              Media
            </Typography>
          </Box>
          <List>
            {mediaTables.map((object) => (
              <ListItem
                button
                onClick={() => handleLink(object.id)}
                selected={object.id === tableId}
                className={classes.listItem}
                key={`tbl-sd-${object.id}`}
              >
                {getIconForObject(object)}
                <ListItemText
                  classes={{ primary: classes.sidebarItem }}
                  primary={object.displayName}
                />
              </ListItem>
            ))}
          </List>
        </div>
        <Divider className={classes.separator} />
        <div className={classes.tableGroup}>
          <Box component="div" m={1} className={classes.sectionTitle}>
            <Typography variant="h6" className={classes.sidebarTitle}>
              Custom Tables
            </Typography>
            <IconButton
              aria-label="add"
              className={classes.actionAddButton}
              onClick={handleShowAddForm}
            >
              <AddIcon />
            </IconButton>
          </Box>
          <List>
            {myTables.map((object) => (
              <ListItem
                button
                onClick={() => handleLink(object.id)}
                selected={object.id === tableId}
                classes={{
                  root: classes.listItem,
                  container: classes.listItemContainer,
                }}
                key={`tbl-sd-${object.id}`}
              >
                {getIconForObject(object)}

                <ListItemText
                  classes={{ primary: classes.sidebarItem }}
                  primary={object.displayName}
                />
                <ListItemSecondaryAction className={classes.itemActionContainer}>
                  <IconButton
                    aria-label="edit"
                    className={classes.actionButton}
                    onClick={() => handleShowRenameForm(object)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    className={classes.actionButton}
                    disabled={object.isSystemTable}
                    onClick={() => handleShowDeleteForm(object)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
        <Divider className={classes.separator} />
        <div className={classes.tableGroup}>
          <Box component="div" m={1} className={classes.sectionTitle}>
            <Typography variant="h6" className={classes.sidebarTitle}>
              Reports
            </Typography>
          </Box>

          <List>
            {reportsTables.map((object) => (
              <ListItem
                button
                onClick={() => handleLink(object.id)}
                selected={object.id === tableId}
                className={classes.listItem}
                key={`tbl-sd-${object.id}`}
              >
                {getIconForObject(object)}
                <ListItemText
                  classes={{ primary: classes.sidebarItem }}
                  primary={object.displayName}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>

      {showAddForm && (
        <CreateTable handleSuccess={handleCloseAddFormSuccess} handleDismiss={handleCloseAddForm} />
      )}
      {showRenameForm && (
        <RenameTable
          rowData={selectedRecord}
          handleSuccess={handleCloseRenameFormSuccess}
          handleDismiss={handleCloseRenameForm}
        />
      )}
      {showDeleteForm && (
        <DeleteTable
          rowData={selectedRecord}
          handleSuccess={handleCloseDeleteFormSuccess}
          handleDismiss={handleCloseDeleteForm}
        />
      )}
    </>
  );
};

DataSidebar.propTypes = {
  dataSidebarOpen: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  tableId: PropTypes.string.isRequired,
  handleDataSidebar: PropTypes.func.isRequired,
};

export default DataSidebar;
