import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import { ReactComponent as NumberIcon } from '../../../assets/images/number.svg';
import {
  createColumn,
  getTableReferenceColumns,
  getTableLookupColumns,
} from '../../../services/Columns';
import Validations from '../../../utils/Validations';
import ColumnTypes from '../../../config/ColumnTypes';
import { getAllTables } from '../../../services/Tables';
import formPopupStyle from '../../../theme/styles/components/FormPopup';

const useStyles = makeStyles(formPopupStyle);

const CreateColumn = ({ tableId, handleSuccess, handleDismiss }) => {
  const classes = useStyles();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [referenceTables, setReferenceTables] = useState([]);
  const [referenceTablesLoaded, setReferenceTablesLoaded] = useState(false);
  const [referenceColumns, setReferenceColumns] = useState([]);
  const [referenceColumnsLoaded, setReferenceColumnsLoaded] = useState(false);

  const { handleSubmit, control, watch } = useForm();
  const columnType = watch('columnType');
  const refColumn = watch('referenceColumn');
  const [lookupColumns, setLookupColumns] = useState([]);
  const [lookupColumnsLoaded, setLookupColumnsLoaded] = useState(false);

  useEffect(() => {
    getAllTables().then((resTables) => {
      const refTables = [];

      resTables.forEach((obj) => {
        if (obj.id !== tableId) {
          refTables.push({
            id: obj.id,
            name: obj.displayName,
          });
        }
      });

      setReferenceTables(refTables);
      setReferenceTablesLoaded(true);
    });

    getTableReferenceColumns(tableId).then((resColumns) => {
      const refColumns = [];

      resColumns.forEach((obj) => {
        refColumns.push({
          id: obj.id,
          name: obj.displayName,
        });
      });

      setReferenceColumns(refColumns);
      setReferenceColumnsLoaded(true);
    });
  }, []);

  useEffect(() => {
    setLookupColumnsLoaded(false);

    if (refColumn) {
      getTableLookupColumns(refColumn).then((resColumns) => {
        const cols = [];

        resColumns.forEach((obj) => {
          cols.push({
            id: obj.id,
            name: obj.displayName,
          });
        });

        setLookupColumns(cols);
        setLookupColumnsLoaded(true);
      });
    } else {
      setLookupColumns([]);
    }
  }, [refColumn]);

  const onSubmit = (data) => {
    setIsSubmitted(true);

    const saveData = {
      name: data.name,
      selectedColumnType: data.columnType,
      tableId,
    };
    if (data.columnType === ColumnTypes.SINGLE_SELECT.value) {
      saveData.singleSelectData = data.colOptions.split(',');
    }
    if (
      data.columnType === ColumnTypes.REFERENCE.value ||
      data.columnType === ColumnTypes.MULTI_REFERENCE.value
    ) {
      saveData.referenceTable = data.referenceTable;
    }
    if (data.columnType === ColumnTypes.LOOKUP.value) {
      saveData.referenceColumn = data.referenceColumn;
      saveData.lookupColumn = data.lookupColumn;
    }

    createColumn(saveData)
      .then(() => {
        handleSuccess();
        setIsSubmitted(false);
      })
      .catch(() => {
        // something went wrong
        setIsSubmitted(false);
      });
  };

  const handleClose = () => {
    handleDismiss();
  };

  const showForm =
    columnType === ColumnTypes.REFERENCE.value || columnType === ColumnTypes.MULTI_REFERENCE.value;

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">Add new field</DialogTitle>
      <DialogContent>
        <form id="add-new-column" onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="name"
              id="name"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoFocus
                  label="Field Name"
                  value={value}
                  variant="outlined"
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="text"
                />
              )}
            />
          </FormControl>

          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="columnType"
              id="columnType"
              defaultValue={ColumnTypes.STRING.value}
              render={({ field: { onChange, value } }) => (
                <>
                  <Select
                    className={`${classes.showLabel} ${classes.withIcon} ${classes.centeredSelect}`}
                    value={value}
                    onChange={onChange}
                    variant="outlined"
                    label="Field Type"
                  >
                    <MenuItem className={classes.withIcon} value={ColumnTypes.STRING.value}>
                      <TextFieldsIcon />
                      {ColumnTypes.STRING.text}
                    </MenuItem>
                    <MenuItem className={classes.withIcon} value={ColumnTypes.NUMBER.value}>
                      <NumberIcon />
                      {ColumnTypes.NUMBER.text}
                    </MenuItem>
                    <MenuItem className={classes.withIcon} value={ColumnTypes.DATE.value}>
                      <DateRangeIcon />
                      {ColumnTypes.DATE.text}
                    </MenuItem>
                    <MenuItem className={classes.withIcon} value={ColumnTypes.IMAGE.value}>
                      <ArrowDropDownCircleIcon />
                      {ColumnTypes.IMAGE.text}
                    </MenuItem>
                    <MenuItem className={classes.withIcon} value={ColumnTypes.SINGLE_SELECT.value}>
                      <ArrowDropDownCircleIcon />
                      {ColumnTypes.SINGLE_SELECT.text}
                    </MenuItem>
                    <MenuItem className={classes.withIcon} value={ColumnTypes.REFERENCE.value}>
                      <FileCopyIcon />
                      {ColumnTypes.REFERENCE.text}
                    </MenuItem>
                    <MenuItem
                      className={classes.withIcon}
                      value={ColumnTypes.MULTI_REFERENCE.value}
                    >
                      <FileCopyIcon />
                      {ColumnTypes.MULTI_REFERENCE.text}
                    </MenuItem>
                    <MenuItem className={classes.withIcon} value={ColumnTypes.LOOKUP.value}>
                      <FindInPageIcon />
                      {ColumnTypes.LOOKUP.text}
                    </MenuItem>
                  </Select>
                </>
              )}
            />
          </FormControl>

          {showForm && (
            <FormControl className={classes.formInput}>
              <Controller
                control={control}
                name="referenceTable"
                id="referenceTable"
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      className={`${classes.showLabel} ${classes.withIcon} ${classes.centeredSelect}`}
                      label="Reference Table"
                    >
                      <MenuItem value="">
                        {referenceTablesLoaded ? 'Select' : 'Please wait...'}
                      </MenuItem>
                      {referenceTables.map((tab) => (
                        <MenuItem value={tab.id} key={`rt-${tab.id}`}>
                          {tab.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            </FormControl>
          )}

          {columnType === ColumnTypes.LOOKUP.value && (
            <>
              <FormControl className={classes.formInput}>
                <Controller
                  control={control}
                  name="referenceColumn"
                  id="referenceColumn"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Select
                        value={value}
                        onChange={onChange}
                        variant="outlined"
                        className={`${classes.showLabel} ${classes.withIcon} ${classes.centeredSelect}`}
                        label="Reference Column"
                      >
                        <MenuItem value="">
                          {referenceColumnsLoaded ? 'Select' : 'Please wait...'}
                        </MenuItem>
                        {referenceColumns.map((col) => (
                          <MenuItem value={col.id} key={`rc-${col.id}`}>
                            {col.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </FormControl>
              {refColumn && (
                <FormControl className={classes.formInput}>
                  <Controller
                    control={control}
                    name="lookupColumn"
                    id="lookupColumn"
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Select
                          value={value}
                          onChange={onChange}
                          variant="outlined"
                          className={`${classes.showLabel} ${classes.withIcon} ${classes.centeredSelect}`}
                          label="Lookup Field"
                        >
                          <MenuItem value="">
                            {lookupColumnsLoaded ? 'Select  ' : 'Please wait...'}
                          </MenuItem>
                          {lookupColumns.map((col) => (
                            <MenuItem value={col.id} key={`lc-${col.id}`}>
                              {col.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                </FormControl>
              )}
            </>
          )}

          {columnType === ColumnTypes.SINGLE_SELECT.value && (
            <FormControl className={classes.formInput}>
              <Controller
                control={control}
                name="colOptions"
                id="colOptions"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Coma Separated Values"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="text"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          form="add-new-column"
          variant="contained"
          color="primary"
          disabled={isSubmitted}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateColumn.propTypes = {
  tableId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default CreateColumn;
