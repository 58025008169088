// Column types
const ColumnTypes = {
  STRING: {
    value: 'STRING',
    text: 'String',
  },
  NUMBER: {
    value: 'NUMBER',
    text: 'Number',
  },
  DATE: {
    value: 'DATE',
    text: 'Date',
  },
  SINGLE_SELECT: {
    value: 'SINGLE_SELECT',
    text: 'Single Select',
  },
  IMAGE: {
    value: 'IMAGE',
    text: 'Image',
  },
  REFERENCE: {
    value: 'REFERENCE',
    text: 'Reference',
  },
  MULTI_REFERENCE: {
    value: 'MULTI_REFERENCE',
    text: 'Multi Reference',
  },
  LOOKUP: {
    value: 'LOOKUP',
    text: 'Lookup',
  },
};

export default ColumnTypes;
