import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { AgGridReact } from 'ag-grid-react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Default from '../theme/Default';
import Grey from '../theme/Grey';
import Red from '../theme/Red';
import Orange from '../theme/Orange';
import ProjectColors from '../config/ProjectColors';
import { initializeClientApp } from '../config/FirebaseProject';
import { getProjectDetails } from '../services/Projects';
import { getTableDetails, getTableData } from '../services/Tables';
import { getViewDetails, updateTableView } from '../services/TableViews';
import { getTableColumns } from '../services/Columns';
import { getMarkerDetails } from '../services/Markers';
import { saveLocalData } from '../utils/LocalStore';
import DataNotFound from '../components/layout/DataNotFound';
import ProjectMenubar from '../components/layout/ProjectMenubar';
import TableViewOptions from '../components/data/TableViewOptions';
import TableOptions from '../components/data/TableOptions';
import TableViewToolbar from '../components/data/TableViewToolbar';
import FullDetail from '../components/data/rows/FullDetail';
import CellEditor from '../components/data/cell/CellEditor';
import wrapperStyle from '../theme/styles/layout/Data';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { getVideoDetails } from '../services/Video';
import { getPlaylistItems } from '../services/Playlist';

const useStyles = makeStyles(wrapperStyle);

// Layout for data tables
const Data = () => {
  const classes = useStyles();
  const urlParams = useParams();
  const location = useLocation();
  const projectId = urlParams.projectId || null;
  const tableId = urlParams.tableId || null;
  const viewId = urlParams.viewId || null;
  const statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agSelectedRowCountComponent',
        align: 'left',
      },
    ],
  };

  let gridDrag = false;
  const [projectData, setProjectData] = useState({
    loaded: false,
    projectId: '',
    projectName: '',
    projectColor: '',
    tableId: '',
    tableName: '',
    allowAddRow: true,
    collectionName: '',
    viewId: '',
    viewColDef: {},
  });
  const [showNotFound, setShowNotFound] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(0);
  const [updateState, setUpdateState] = useState(1);
  const [pageTitle, setPageTitle] = useState(process.env.REACT_APP_NAME);
  const [gridApi, setGridApi] = useState(false);
  const [colsArray, setColsArray] = useState([]);
  const [showFullView, setShowFullView] = useState(false);
  const [fullViewData, setFullViewData] = useState(null);
  const [cellEditMode, setCellEditMode] = useState(null);
  const [gridTextFilter, setGridTextFilter] = useState('');
  const [colorTheme, setColorTheme] = useState(Default);

  const loadTableData = (prjTableViewDetails) => {
    saveLocalData(`DD_${projectId}`, location.pathname, false);
    getTableColumns(prjTableViewDetails.tableId).then((colRes) => {
      const colData = [];
      const colDefArray = [];

      colData.push({
        headerName: '',
        field: 'rowCheck',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        pinned: 'left',
        width: 30,
        sortable: false,
        editable: false,
        filter: false,
        resizable: false,
        enableRowGroup: false,
        custom: {
          readOnly: true,
        },
      });
      colData.push({
        headerName: '',
        field: 'fullView',
        pinned: 'left',
        width: 30,
        sortable: false,
        editable: false,
        filter: false,
        resizable: false,
        enableRowGroup: false,
        custom: {
          readOnly: true,
        },
        cellRenderer: () =>
          `<div class="open-data">
            <img src="/images/expand.png" alt="Full View" />
          </div>`,
      });
      colData.push({
        headerName: 'ID',
        field: 'id',
        minWidth: 100,
        sortable: false,
        editable: false,
        filter: false,
        resizable: true,
        enableRowGroup: false,
        custom: {
          readOnly: true,
        },
      });

      colRes.forEach((col) => {
        const columnObj = {
          headerName: `${col.displayName}`,
          field: col.name,
          sortable: col.canSort,
          editable: false,
          filter: 'agTextColumnFilter',
          menuTabs: ['filterMenuTab'],
          resizable: true,
          enableRowGroup: true,
          minWidth: 100,
          colData: col,
          custom: {
            type: col.type,
            refType: col.refType,
            relation: col.relation || null,
            colId: col.id,
            readOnly: col.isReadOnly,
          },
        };

        if (col.type === 'IMAGE') {
          columnObj.cellRenderer = (p) => {
            if (p.value) {
              return `<img src="${p.value}" style="max-width: 100%; max-height: 100%; margin: 0px auto;" alt="image" />`;
            }
            return '';
          };
          columnObj.sortable = false;
          columnObj.filter = false;
          columnObj.enableRowGroup = false;
          columnObj.menuTabs = [];
        } else {
          columnObj.cellRenderer = (p) => {
            if (Array.isArray(p.value)) {
              return p.value.join(', ');
            }
            return p.value;
          };

          // columnObj.valueGetter = (params) => params.data[col.name] || '';
        }

        if (col.type === 'SINGLE_SELECT') {
          columnObj.cellEditorSelector = () => ({
            component: 'agRichSelectCellEditor',
            params: {
              values: col.staticOptions,
            },
          });
          columnObj.custom.staticOptions = col.staticOptions;
        }
        if (col.type === 'NUMBER') {
          columnObj.filter = 'agNumberColumnFilter';
        }
        if (col.type === 'DATE') {
          columnObj.filter = 'agDateColumnFilter';
        }

        colDefArray.push({
          name: col.name,
          displayName: col.displayName,
          disabled: col.isReadOnly,
          type: col.type,
          relation: col.relation,
          options: col?.staticOptions || [],
        });

        colData.push(columnObj);
      });

      setColsArray(colDefArray);

      getTableData(prjTableViewDetails.collectionName).then((dataRes) => {
        gridApi.columnApi.resetColumnState();
        gridApi.api.setColumnDefs(colData);
        gridApi.api.setQuickFilter('');
        gridApi.api.setRowData(dataRes);

        if (prjTableViewDetails.viewId !== 'all' && prjTableViewDetails.viewId) {
          if (prjTableViewDetails.viewColDef?.columnFilters) {
            gridApi.api.setFilterModel(prjTableViewDetails.viewColDef.columnFilters);
          }
          if (prjTableViewDetails.viewColDef?.columnState?.length > 0) {
            gridApi.columnApi.applyColumnState({
              state: prjTableViewDetails.viewColDef.columnState,
              applyOrder: true,
            });
          }
          if (prjTableViewDetails.viewColDef?.quickFilterText) {
            gridApi.api.setQuickFilter(prjTableViewDetails.viewColDef.quickFilterText);
          }
        } else {
          /*
          gridApi.api.setFilterModel(getLocalData(`${collectionName}_columnFilters`, true, '{}'));
          gridApi.columnApi.applyColumnState({
            state: getLocalData(`${collectionName}_columnState`, true, '[]'),
            applyOrder: true,
          });

          gridApi.api.setQuickFilter(getLocalData(`${collectionName}_quickFilterText`, false, ''));
          */
        }

        setProjectData({
          ...prjTableViewDetails,
          loaded: true,
        });
      });
    });
  };

  const initView = (prjTableDetails) => {
    getViewDetails(viewId)
      .then((viewRes) => {
        const prjTableViewDetails = {
          ...prjTableDetails,
          viewId: viewRes.id,
          viewColDef: {
            columnFilters: JSON.parse(viewRes.columnFilters || '{}'),
            columnState: JSON.parse(viewRes.columnState || '[]'),
            quickFilterText: viewRes.quickFilterText || '',
          },
        };
        setGridTextFilter(viewRes.quickFilterText);
        loadTableData(prjTableViewDetails);
      })
      .catch(() => {
        setShowNotFound(true);
      });
  };

  const initTable = (prjDetails) => {
    getTableDetails(tableId)
      .then((tabRes) => {
        const tmpPrjDetails = JSON.parse(JSON.stringify(prjDetails || projectData));
        const prjTableDetails = {
          ...tmpPrjDetails,
          tableId: tabRes.id,
          tableName: tabRes.displayName,
          collectionName: tabRes.name,
          allowAddRow: tabRes.allowAddRow,
          viewId,
          viewColDef: {},
        };

        setPageTitle(`${prjTableDetails.projectName} / ${prjTableDetails.tableName}`);
        window.document.title = `${prjTableDetails.projectName} / ${prjTableDetails.tableName}`;

        if (viewId !== 'all' && viewId) {
          initView(prjTableDetails);
        } else {
          loadTableData(prjTableDetails);
        }
      })
      .catch(() => {
        setShowNotFound(true);
      });
  };

  const initProject = () => {
    getProjectDetails(projectId)
      .then((prjRes) => {
        const prjDetails = {
          projectId: prjRes.id,
          projectName: prjRes.name,
          projectColor: prjRes.color,
        };

        // Load Projects
        initializeClientApp(prjRes.firebaseConf, projectId);

        if (prjRes.color === ProjectColors.COLOR1) {
          setColorTheme(Default);
        } else if (prjRes.color === ProjectColors.COLOR2) {
          setColorTheme(Grey);
        } else if (prjRes.color === ProjectColors.COLOR3) {
          setColorTheme(Orange);
        } else if (prjRes.color === ProjectColors.COLOR4) {
          setColorTheme(Red);
        }

        initTable(prjDetails);
      })
      .catch(() => {
        setShowNotFound(true);
      });
  };

  useEffect(() => {
    setGridTextFilter('');

    if (projectData.loaded) {
      initTable(null);
    } else if (reloadTableData > 0) {
      initProject();
    }
  }, [tableId, viewId, reloadTableData]);

  const saveTableView = () => {
    if (gridApi === false || projectData.collectionName === '') return;

    const columnFilters = gridApi.api.getFilterModel();
    const columnState = gridApi.columnApi.getColumnState();
    const quickFilterText = gridTextFilter;

    if (viewId === 'all' || viewId === null) {
      /*
      saveLocalData(`${collectionName}_columnFilters`, columnFilters, true);
      saveLocalData(`${collectionName}_columnState`, columnState, true);
      saveLocalData(`${collectionName}_quickFilterText`, quickFilterText, false);
      */
      return;
    }

    const saveData = {
      columnFilters: JSON.stringify(columnFilters),
      columnState: JSON.stringify(columnState),
      quickFilterText: quickFilterText || '',
    };
    updateTableView(viewId, saveData)
      .then(() => {
        // notify?
      })
      .catch(() => {
        // notify?
      });
  };

  const handleGridUpdates = () => {
    if (gridDrag) return;

    saveTableView();
    setUpdateState(updateState + 1);
  };

  const handleUpdateHideCols = (colId, value) => {
    gridApi.columnApi.applyColumnState({
      state: [
        {
          colId,
          hide: value,
        },
      ],
    });
  };
  const handleUpdateFilters = (colId) => {
    const filteredColumns = gridApi.api.getFilterModel();
    const updatedFilters = {};
    Object.keys(filteredColumns).forEach((key) => {
      if (key !== colId) {
        updatedFilters[key] = filteredColumns[key];
      }
    });
    gridApi.api.setFilterModel(updatedFilters);
    gridApi.api.onFilterChanged();
  };
  const handleUpdateGroups = (colId) => {
    gridApi.columnApi.applyColumnState({
      state: [
        {
          colId,
          rowGroup: false,
          hide: false,
        },
      ],
    });
  };
  const handleUpdateSort = (colId) => {
    gridApi.columnApi.applyColumnState({
      state: [
        {
          colId,
          sort: null,
        },
      ],
    });
  };

  const handleSearchQuery = (q) => {
    setGridTextFilter(q);
    setTimeout(() => {
      gridApi.api.setQuickFilter(q);
    }, 300);
  };
  const handleRefreshData = () => {
    setReloadTableData(reloadTableData + 1);
  };
  const handleDownloadData = () => {
    const params = {
      columnKeys: gridApi.columnApi
        .getAllColumns()
        .filter((c) => c.colDef.headerName !== '' && c.visible === true)
        .map((col) => col.colId),
    };
    gridApi.api.exportDataAsCsv(params);
  };

  const handleDragStart = () => {
    gridDrag = true;
  };
  const handleDragEnd = () => {
    gridDrag = false;
    handleGridUpdates();
  };

  const handleCellClicked = async (e) => {
    if (e?.colDef?.field === 'fullView') {
      const selectedRowData = {
        data: e.data,
        colsArray,
        collectionName: projectData.collectionName,
        tableName: projectData.tableName,
      };
      if (projectData.collectionName === 'markers') {
        const video = await getVideoDetails(e.data.references.video);
        selectedRowData.data = {
          ...selectedRowData.data,
          hlsLink: video.hlsLink,
          image: video.image,
        };
      }

      if (projectData.collectionName === 'playlistItems') {
        const marker = await getMarkerDetails(e.data.references.marker);
        if (marker) {
          const video = await getVideoDetails(marker.references.video);
          selectedRowData.data = {
            ...selectedRowData.data,
            hlsLink: video.hlsLink,
            image: video.image,
            startTimeSeconds: marker.startTimeSeconds,
          };
        } else {
          selectedRowData.data = {
            ...selectedRowData.data,
            hlsLink: '',
            image: '',
            startTimeSeconds: 0,
          };
        }
      }

      if (projectData.collectionName === 'playlists') {
        const plItems = await getPlaylistItems(e.data.id);
        const plItemsDetail = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const item of plItems) {
          // eslint-disable-next-line no-await-in-loop
          const marker = await getMarkerDetails(item.references.marker);
          if (marker) {
            // eslint-disable-next-line no-await-in-loop
            const video = await getVideoDetails(marker.references.video);
            const obj = {
              plItemId: item.id,
              plItemName: item.displayName,
              name: marker.displayName,
              sources: [{ src: video.hlsLink }],
              thumbnail: [{ src: video.image }],
              // duration: video.durationSeconds,
              startTimeSeconds: marker.startTimeSeconds || 0,
              endTimeSeconds: marker.endTimeSeconds || 0,
              totalDuration: marker.duration,
              videoDisplayName: video.displayName,
              position: item.position,
            };

            plItemsDetail.push(obj);
          }
        }
        selectedRowData.data = {
          ...selectedRowData.data,
          plItemsDetail,
        };
      }
      setFullViewData(selectedRowData);
      setShowFullView(true);
    }
  };
  const handleCloseFullView = () => {
    setShowFullView(false);
    setFullViewData(null);
  };

  const handleCellEdit = (params) => {
    if (!params.colDef.custom.readOnly) {
      setCellEditMode(params);
    }
  };
  const handleCellEditClose = () => {
    setCellEditMode(null);
  };
  const handleCellEditCloseSuccess = (val) => {
    const rowNode = gridApi.api.getRowNode(cellEditMode.data.id);
    rowNode.setDataValue(cellEditMode.colDef.field, val);
    setCellEditMode(null);
    setReloadTableData(reloadTableData + 1);
  };

  const onGridReady = (params) => {
    setGridApi(params);
    setReloadTableData(reloadTableData + 1);
  };

  const getRowNodeId = (data) => data.id;

  const handleGroupSort = (nodeA, nodeB) => {
    if (nodeA.key < nodeB.key) {
      return -1;
    }
    if (nodeA.key > nodeB.key) {
      return 1;
    }
    return 0;
  };

  return (
    <ThemeProvider theme={colorTheme}>
      <ProjectMenubar
        heading={pageTitle}
        projectId={projectData.projectId}
        projectColor={projectData.projectColor}
        tableId={projectData.tableId}
      />
      <Container className={classes.wrapper} maxWidth="xl">
        <div className={classes.innerWrapper}>
          {!showNotFound && (
            <>
              <TableViewOptions
                projectId={projectData.projectId}
                tableId={projectData.tableId}
                viewId={projectData.viewId}
              />

              <TableOptions
                tableId={projectData.tableId}
                collectionName={projectData.collectionName}
                allowAddRow={projectData.allowAddRow}
                refreshData={handleRefreshData}
                gridApi={gridApi}
                projectId={projectData.projectId}
              />

              <TableViewToolbar
                handleHideCols={handleUpdateHideCols}
                handleFilters={handleUpdateFilters}
                handleGrouping={handleUpdateGroups}
                handleSorting={handleUpdateSort}
                handleSearch={handleSearchQuery}
                handleRefresh={handleRefreshData}
                handleDownload={handleDownloadData}
                gridTextFilter={gridTextFilter}
                gridApi={gridApi}
              />

              <div
                id={tableId}
                style={{
                  height: '800px',
                  width: '100%',
                }}
                className="ag-theme-material"
              >
                <AgGridReact
                  defaultColDef={{
                    flex: 1,
                    sortable: true,
                    filter: true,
                    width: 150,
                  }}
                  suppressExcelExport
                  onGridReady={onGridReady}
                  rowSelection="multiple"
                  rowGroupPanelShow="always"
                  groupSelectsChildren
                  floatingFilter={false}
                  statusBar={statusBar}
                  applyColumnDefOrder
                  enableRangeSelection
                  groupUseEntireRow
                  animateRows
                  suppressRowClickSelection
                  groupSuppressBlankHeader
                  suppressDragLeaveHidesColumns
                  onSortChanged={handleGridUpdates}
                  onFilterChanged={handleGridUpdates}
                  onColumnRowGroupChanged={handleGridUpdates}
                  onColumnVisible={handleGridUpdates}
                  onColumnPinned={handleGridUpdates}
                  onColumnResized={handleGridUpdates}
                  onColumnMoved={handleGridUpdates}
                  onDragStarted={handleDragStart}
                  onDragStopped={handleDragEnd}
                  onCellClicked={handleCellClicked}
                  onCellDoubleClicked={handleCellEdit}
                  getRowNodeId={getRowNodeId}
                  defaultGroupSortComparator={handleGroupSort}
                />
              </div>

              {showFullView && (
                <FullDetail
                  row={fullViewData}
                  handleDismiss={handleCloseFullView}
                  projectId={projectData.projectId}
                  tableId={projectData.tableId}
                  projectColor={projectData.projectColor}
                />
              )}

              {cellEditMode !== null && (
                <CellEditor
                  params={cellEditMode}
                  tableId={projectData.tableId}
                  collectionName={projectData.collectionName}
                  handleSuccess={handleCellEditCloseSuccess}
                  handleDismiss={handleCellEditClose}
                />
              )}
            </>
          )}

          {showNotFound && <DataNotFound />}
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default Data;
