import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import accountStyle from '../../theme/styles/components/AccountSection';

const useStyles = makeStyles(accountStyle);

// Close account option
const CloseAccount = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.formContainer}>
      <Typography variant="body1" component="p" className={classes.infoText}>
        Before closing your account, you must cancel all project plans that you own. After closing
        the account, you will also be removed from any project that has been shared with you. After
        30 days of an account being closed, all data associated with your account will be deleted.
      </Typography>

      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        Close Account
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">Close Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close your account? This action can not be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CloseAccount;
