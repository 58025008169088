import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import headerStyle from '../../theme/styles/components/PageHeader';

const useStyles = makeStyles(headerStyle);

// Page header for main layout
const PageHeader = ({ pageHeading }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={0} className={classes.header}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" className={classes.headerText}>
            {pageHeading}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

PageHeader.propTypes = {
  pageHeading: PropTypes.string.isRequired,
};

export default PageHeader;
