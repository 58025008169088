import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { renameTable } from '../../../services/Tables';
import Validations from '../../../utils/Validations';
import formPopupStyle from '../../../theme/styles/components/FormPopup';

const useStyles = makeStyles(formPopupStyle);

// Rename a table
const RenameTable = ({ rowData, handleSuccess, handleDismiss }) => {
  const classes = useStyles();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    setIsSubmitted(true);

    const saveData = {
      tableId: rowData.id,
      name: data.name,
    };

    renameTable(saveData)
      .then(() => {
        handleSuccess();
        setIsSubmitted(false);
      })
      .catch(() => {
        // something went wrong
        setIsSubmitted(false);
      });
  };

  const handleClose = () => {
    handleDismiss();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">Rename table</DialogTitle>
      <DialogContent>
        <form id="rename-table" onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="name"
              id="name"
              defaultValue={rowData.displayName}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoFocus
                  label="Table Name"
                  value={value}
                  onChange={onChange}
                  variant="outlined"
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="text"
                />
              )}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          form="rename-table"
          variant="contained"
          color="primary"
          disabled={isSubmitted}
        >
          Update name
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RenameTable.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default RenameTable;
