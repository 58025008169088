import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { LicenseManager } from 'ag-grid-enterprise';
import { ThemeProvider } from '@material-ui/core/styles';
import { auth } from '../config/Firebase';
import RoutePaths from '../config/Routes';
import Projects from './projects/Projects';
import DataTables from './data/Table';
import Account from './account/Account';
import Logout from './account/Logout';
import NotFound from './errors/NotFound';
import AppLoader from '../components/AppLoader';
import Default from '../theme/Default';
import { checkAuthState } from '../services/Auth';
import '../theme/fonts/fonts.css';

// Configure ag grid enterprise features
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_LICENSE_KEY);

// Initialize app routes
const App = () => {
  const [appLoaded, setAppLoaded] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    checkAuthState()
      .then((res) => {
        if (!res.data.customToken) {
          window.location.href = process.env.REACT_APP_AUTH_BASE_URL;
        } else if (auth.currentUser.email === null) {
          setUserLoggedIn(false);
          setAppLoaded(true);
        } else {
          setUserLoggedIn(true);
          setAppLoaded(true);
        }
      })
      .catch(() => {
        setUserLoggedIn(false);
        setAppLoaded(true);
      });
  }, []);

  return (
    <ThemeProvider theme={Default}>
      <CssBaseline />

      {!appLoaded && <AppLoader />}

      {appLoaded && !userLoggedIn && <Logout />}

      {appLoaded && userLoggedIn && (
        <Router>
          <Switch>
            <Route path={RoutePaths.HOME} exact component={Projects} />
            <Route path={RoutePaths.PROJECTS} exact component={Projects} />
            <Route path={RoutePaths.PROJECT_MEDIA} exact component={DataTables} />
            <Route path={RoutePaths.PROJECT_MEDIA_TABLE} exact component={DataTables} />
            <Route path={RoutePaths.PROJECT_MEDIA_TABLE_VIEW} exact component={DataTables} />
            <Route path={RoutePaths.ACCOUNT} exact component={Account} />
            <Route path={RoutePaths.LOGOUT} exact component={Logout} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      )}
    </ThemeProvider>
  );
};

export default App;
