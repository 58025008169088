import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import wrapperStyle from '../theme/styles/layout/Empty';

const useStyles = makeStyles(wrapperStyle);

// Layout for error pages
const Empty = ({ children }) => {
  const classes = useStyles();

  useEffect(() => {
    window.document.title = `${process.env.REACT_APP_NAME} - Application`;
  }, []);

  return <Container className={classes.wrapper}>{children}</Container>;
};

Empty.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Empty;
