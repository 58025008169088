import React from 'react';
import Grid from '@material-ui/core/Grid';

// Display information when data is not found
const DataNotFound = () => (
  <Grid container direction="row" justify="center" alignItems="center">
    <Grid item xs={2}>
      The page you trying to access was not found.
    </Grid>
  </Grid>
);

export default DataNotFound;
