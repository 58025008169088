import grey from '@material-ui/core/colors/grey';

// Style for account page
const Style = () => ({
  tabs: {
    backgroundColor: grey[100],
    '& .MuiButtonBase-root': {
      padding: 0,
      minWidth: 134,
    },
  },
});

export default Style;
