import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import CreateColumn from './columns/CreateColumn';
import ManageColumns from './columns/ManageColumns';
import AddRow from './rows/AddRow';
import DeleteRows from './rows/DeleteRows';
import CreateVideo from './video/CreateVideo';
import optionsStyle from '../../theme/styles/components/TableOptions';

const useStyles = makeStyles(optionsStyle);

const TableOptions = ({
  tableId,
  collectionName,
  allowAddRow,
  refreshData,
  gridApi,
  projectId,
}) => {
  const classes = useStyles();
  const selectedRowsCount = gridApi?.api?.getSelectedRows().length || 0;

  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddColumnForm, setShowAddColumnForm] = useState(false);
  const [showManageColumnForm, setShowManageColumnForm] = useState(false);
  const [showAddRowForm, setShowAddRowForm] = useState(false);
  const [showDeleteRowsForm, setShowDeleteRowsForm] = useState(false);
  const [showAddVideoForm, setShowAddVideoForm] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowAddColumnForm = () => {
    setShowAddColumnForm(true);
    handleClose();
  };
  const handleCloseAddColumnForm = () => {
    setShowAddColumnForm(false);
  };
  const handleCloseAddColumnFormSuccess = () => {
    setShowAddColumnForm(false);
    refreshData();
  };

  const handleShowManageColumnForm = () => {
    setShowManageColumnForm(true);
    handleClose();
  };
  const handleCloseManageColumnForm = () => {
    setShowManageColumnForm(false);
  };
  const handleCloseManageColumnFormSuccess = () => {
    setShowManageColumnForm(false);
    refreshData();
  };

  const handleShowAddRowForm = () => {
    setShowAddRowForm(true);
    handleClose();
  };
  const handleCloseAddRowForm = () => {
    setShowAddRowForm(false);
  };
  const handleCloseAddRowFormSuccess = () => {
    setShowAddRowForm(false);
    refreshData();
  };

  const handleShowDeleteRowsForm = () => {
    setShowDeleteRowsForm(true);
    handleClose();
  };
  const handleCloseDeleteRowsForm = () => {
    setShowDeleteRowsForm(false);
  };
  const handleCloseDeleteRowsFormSuccess = () => {
    setShowDeleteRowsForm(false);
    refreshData();
  };

  const handleShowAddVideoForm = () => {
    setShowAddVideoForm(true);
  };
  const handleCloseAddVideoForm = () => {
    setShowAddVideoForm(false);
  };
  const handleCloseAddVideoFormSuccess = () => {
    setShowAddVideoForm(false);
    refreshData();
  };

  return (
    <>
      <div className={classes.tableOptions}>
        <Button
          aria-controls="table-options"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.optionsBtn}
        >
          <MoreVertIcon />{' '}
        </Button>
        <Button size="small" className={classes.uploadBtn} onClick={handleShowAddVideoForm}>
          <CloudUploadIcon className={classes.uploadBtnIcon} />{' '}
          <Hidden smDown implementation="css">
            Upload Video
          </Hidden>
        </Button>
        <Menu
          id="table-options"
          anchorEl={anchorEl}
          keepMounted
          classes={{ list: classes.menu }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleShowAddColumnForm}>
            <ListItemIcon className={classes.menuItemIcon}>
              <AddBoxIcon fontSize="small" />
            </ListItemIcon>
            <Typography>Add Field</Typography>
          </MenuItem>
          <MenuItem onClick={handleShowManageColumnForm}>
            <ListItemIcon className={classes.menuItemIcon}>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography>Edit Fields</Typography>
          </MenuItem>

          <Divider className={classes.divider} />

          <MenuItem onClick={handleShowAddRowForm} disabled={!allowAddRow}>
            <ListItemIcon className={classes.menuItemIcon}>
              <AddBoxIcon fontSize="small" />
            </ListItemIcon>
            <Typography>Add Row</Typography>
          </MenuItem>
          <MenuItem onClick={handleShowDeleteRowsForm} disabled={selectedRowsCount === 0}>
            <ListItemIcon className={classes.menuItemIcon}>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            {selectedRowsCount === 1 && <Typography>Delete 1 Row</Typography>}
            {selectedRowsCount > 1 && <Typography>Delete {selectedRowsCount} Rows</Typography>}
            {selectedRowsCount === 0 && <Typography>Delete Row(s)</Typography>}
          </MenuItem>
        </Menu>
      </div>

      {showAddColumnForm && (
        <CreateColumn
          tableId={tableId}
          handleSuccess={handleCloseAddColumnFormSuccess}
          handleDismiss={handleCloseAddColumnForm}
        />
      )}
      {showManageColumnForm && (
        <ManageColumns
          tableId={tableId}
          handleSuccess={handleCloseManageColumnFormSuccess}
          handleDismiss={handleCloseManageColumnForm}
        />
      )}
      {showAddRowForm && (
        <AddRow
          tableId={tableId}
          collectionName={collectionName}
          handleSuccess={handleCloseAddRowFormSuccess}
          handleDismiss={handleCloseAddRowForm}
        />
      )}
      {showDeleteRowsForm && (
        <DeleteRows
          handleSuccess={handleCloseDeleteRowsFormSuccess}
          handleDismiss={handleCloseDeleteRowsForm}
          tableId={tableId}
          collectionName={collectionName}
          selectedRows={gridApi?.api?.getSelectedRows() || []}
        />
      )}
      {showAddVideoForm && (
        <CreateVideo
          handleSuccess={handleCloseAddVideoFormSuccess}
          handleDismiss={handleCloseAddVideoForm}
          projectId={projectId}
        />
      )}
    </>
  );
};

TableOptions.propTypes = {
  tableId: PropTypes.string.isRequired,
  collectionName: PropTypes.string.isRequired,
  allowAddRow: PropTypes.bool.isRequired,
  refreshData: PropTypes.func.isRequired,
  gridApi: PropTypes.any.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default TableOptions;
