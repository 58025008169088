/* eslint-disable no-async-promise-executor */
import firebase from 'firebase';
import { db, auth } from '../config/Firebase';
import { clientPrjApp } from '../config/FirebaseProject';
import AccessRoles from '../config/Roles';
import { getUserDetails, getUserShortList } from './Users';

// Create new project
export const createNewProject = async (data) => {
  await db.collection('projects').add({
    name: data.name,
    color: data.color,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    ownedBy: auth.currentUser.uid,
    accessWith: [auth.currentUser.uid],
    accessDetails: [
      {
        id: auth.currentUser.uid,
        role: AccessRoles.OWNER.value,
      },
    ],
    status: 'Draft',
  });
};

// Update project name and color
export const editProject = async (id, data) => {
  await db.collection('projects').doc(id).set(
    {
      name: data.name,
      color: data.color,
    },
    { merge: true }
  );
};

// Get list of my projects
export const getMyProjects = async () => {
  const rows = [];

  const usersList = await getUserShortList();

  const projectsSnap = await db
    .collection('projects')
    .where('accessWith', 'array-contains', auth.currentUser.uid)
    .orderBy('createdAt', 'desc')
    .get();

  projectsSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      id: doc.id,
      ownerName: usersList[tempData.ownedBy],
    });
  });

  return rows;
};

// Get single project details
export const getProjectDetails = async (id) => {
  const project = await db.collection('projects').doc(id).get();
  return { ...project.data(), id };
};

// Get project collaborators information
export const getCollaboratorsInfo = async (id) => {
  const project = await db.collection('projects').doc(id).get();
  if (!project.exists) {
    return [];
  }

  const finalData = [...project.data().accessDetails].filter((k) => k.role !== 'OWNER');
  const promises = [];
  finalData.forEach(async (col) => {
    const promise = new Promise(async (resolve) => {
      const data = col;
      const getDetails = await getUserDetails(col.id);
      data.email = getDetails.email;
      data.name = getDetails.displayName;
      resolve(data);
    });
    promises.push(promise);
  });
  const rows = await Promise.all(promises);
  return rows;
};

// Change access
export const changeCollaboratorAccess = async (projId, userId, access) => {
  const project = await db.collection('projects').doc(projId).get();
  const projectData = project.data();

  const accessDetailsData = [...projectData.accessDetails];
  const find = accessDetailsData.find((k) => k.id === userId);
  if (find) {
    accessDetailsData[accessDetailsData.indexOf(find)].role = access;
  }
  await project.ref.update({ accessDetails: accessDetailsData });
  return true;
};

// Get project configs
export const getProjectConfig = async () => {
  const project = await clientPrjApp.dbPrj.doc('configs/projectConfigs').get();
  return { ...project.data() };
};
