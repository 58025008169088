/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField, Badge } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { sharePrivacy, shareStatus, shareTypes } from '../../config/ShareConfigs';
import SelectColumns from './SelectColumns';
import SelectMarkers from './SelectMarkers';
import { updateShare } from '../../services/ShareItem';
import formPopupStyle from '../../theme/styles/components/ShareLink';
import { ReactComponent as EyeIcon } from '../../assets/images/eye.svg';

const useStyles = makeStyles(formPopupStyle);

const EditShare = ({ shareData, handleDismiss, columns, allMarkers, rowData, handleSuccess }) => {
  const classes = useStyles();
  const [shareObj, setShareObj] = useState({
    ...shareData,
    privacy: sharePrivacy[shareData.privacy],
    status: shareStatus[shareData.status],
    type: shareTypes[shareData.type],
  });
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectColumnModel, setSelectColumnModel] = useState(false);

  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [selectMarkerModel, setSelectMarkerModel] = useState(false);

  useEffect(() => {
    setSelectedColumns(shareData.sharedColumns.map((col) => col.name));
    if (shareData.isVideoShare) {
      setSelectedMarkers(shareData.sharedMarkers.map((m) => m.name));
    }
  }, []);

  const handleShareNameChange = (e) =>
    setShareObj({
      ...shareObj,
      displayName: e.target.value,
    });

  const handleShareStatusChange = (e) =>
    setShareObj({
      ...shareObj,
      status: Number(e.target.value),
    });

  const handleInfoModel = (e, action) => {
    e.preventDefault();
    if (action === 'markers') {
      setSelectMarkerModel(true);
    } else {
      setSelectColumnModel(true);
    }
  };

  const hanldeSelectedColumnChange = (updatedCols) => {
    setSelectedColumns(updatedCols);
  };

  const hanldeSelectedMarkerChange = (updatedMarkers) => {
    setSelectedMarkers(updatedMarkers);
  };

  const getSharedCols = () => {
    const retData = [];
    columns.forEach((col) => {
      if (selectedColumns.includes(col.name)) {
        retData.push({ name: col.name, displayName: col.displayName, type: col.type });
      }
    });
    return retData;
  };

  const getData = () => {
    const retData = {};

    if (shareObj.isVideoShare) {
      retData.displayName = rowData.displayName;
      retData.name = rowData.name;
      retData.hlsLink = rowData.hlsLink;
      retData.id = rowData.id;
      retData.image = rowData.image;
    }
    columns.forEach((col) => {
      if (selectedColumns.includes(col.name)) {
        retData[col.name] = rowData[col.name] || '';
      }
    });

    return retData;
  };

  const getSelectedMarkersInfo = () => {
    const retData = [];
    allMarkers.forEach((m) => {
      if (selectedMarkers.includes(m.name)) {
        retData.push({
          displayName: m.displayName,
          durationSeconds: m.durationSeconds,
          endTime: m.endTime,
          endTimeSeconds: m.endTimeSeconds,
          startTime: m.startTime,
          startTimeSeconds: m.startTimeSeconds,
          id: m.id,
          name: m.name,
        });
      }
    });
    return retData;
  };

  const updateShareData = async () => {
    const { rand } = shareObj;
    const sharedColumns = getSharedCols();

    const payload = {
      displayName: shareObj.displayName,
      name: `${shareObj.displayName}_${rand}`,
      data: getData(),
      sharedColumns,
      sharedColumnsCount: sharedColumns.length,
      privacy: sharePrivacy[shareObj.privacy],
      type: shareTypes[shareObj.type],
      status: shareStatus[shareObj.status],
    };

    if (shareObj.isVideoShare) {
      const sharedMarkers = getSelectedMarkersInfo();
      payload.sharedMarkers = getSelectedMarkersInfo();
      payload.sharedMarkersCount = sharedMarkers.length;
    }

    await updateShare(shareObj.id, payload);

    handleSuccess();
  };

  return (
    <>
      <Grid container direction="column" className={classes.addShareContainer}>
        <Grid container justify="space-between" className={classes.addShareTitleContainer}>
          <Typography className={classes.addShareTitle}>Edit Share Link</Typography>
          <CloseIcon className={classes.addShareClose} onClick={handleDismiss} />
        </Grid>
        <Grid container className={classes.editorContainer}>
          <Grid item xs={12} sm={8}>
            <Grid container direction="column" className={classes.addShareInputsColumn}>
              <TextField
                id="share-name"
                label="Name"
                classes={{ root: classes.text, notchedOutline: classes.outline }}
                variant="outlined"
                size="small"
                value={shareObj.displayName}
                onChange={handleShareNameChange}
              />
              <Grid container>
                <Grid item xs={6}>
                  <FormControl variant="outlined" className={classes.formControl} size="small">
                    <InputLabel>Privacy</InputLabel>
                    <Select
                      value={shareObj.privacy}
                      disabled
                      label="Privacy"
                      className={classes.select}
                    >
                      <MenuItem value={1}>Public</MenuItem>
                      <MenuItem value={2}>Password</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container xs={6} justify="flex-end">
                  <TextField
                    id="share-name"
                    type="password"
                    label="Password"
                    disabled
                    classes={{ root: classes.password, notchedOutline: classes.outline }}
                    variant="outlined"
                    size="small"
                    defaultValue={shareObj.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility">
                            <EyeIcon style={{ opacity: 0.25 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container direction="column" className={classes.addShareBadgesColumn}>
              <Badge
                badgeContent={selectedColumns.length}
                color="secondary"
                classes={{ badge: classes.shareBadge }}
              >
                <Chip
                  className={classes.chip}
                  label="INFO"
                  onClick={(e) => handleInfoModel(e, 'columns')}
                />
              </Badge>
              <Badge
                badgeContent={selectedMarkers.length}
                color="secondary"
                classes={{ badge: classes.shareBadge }}
              >
                <Chip
                  className={classes.chip}
                  label="MARKERS"
                  onClick={(e) => handleInfoModel(e, 'markers')}
                />
              </Badge>
            </Grid>
          </Grid>
          <Hidden smUp>
            <Grid item xs={12}>
              <Button
                className={classes.submitAddButton}
                variant="contained"
                onClick={updateShareData}
              >
                UPDATE LINK
              </Button>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      {selectColumnModel && (
        <SelectColumns
          selectedCols={selectedColumns}
          columns={columns}
          handleChange={hanldeSelectedColumnChange}
          onClose={() => {
            setSelectColumnModel(false);
          }}
        />
      )}

      {selectMarkerModel && (
        <SelectMarkers
          selectedMarkers={selectedMarkers}
          markers={allMarkers}
          handleChange={hanldeSelectedMarkerChange}
          onClose={() => {
            setSelectMarkerModel(false);
          }}
        />
      )}
    </>
  );
};

EditShare.propTypes = {
  shareData: PropTypes.objectOf(PropTypes.any).isRequired,
  rowData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  allMarkers: PropTypes.arrayOf(PropTypes.any).isRequired,
};
export default EditShare;
