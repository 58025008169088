import { clientPrjApp } from '../config/FirebaseProject';

// Sync data operations
const dataSyncLogs = async (logs) => {
  for (let index = 0; index < logs.length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    await clientPrjApp.dbPrj.collection('updateLogs').add(logs[index]);
  }
};

// Sync data operations
export const sharedDataSyncLogs = async (payload) =>
  // eslint-disable-next-line no-return-await
  await clientPrjApp.dbPrj.collection('updateSharedDataLogs').add(payload);

export default dataSyncLogs;
