// Style for share project
const Style = () => ({
  shareFormContent: {
    marginBottom: 20,
  },
  formInput: {
    marginBottom: 15,
    width: '100%',
  },
  inviteButton: {
    paddingTop: 15,
    paddingBottom: 15,
    marginBottom: 15,
  },
  modalCloseButton: {
    float: 'right',
  },
});

export default Style;
