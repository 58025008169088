import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import UserMenu from './UserMenu';
import menubarStyle from '../../theme/styles/components/Menubar';
import logo from '../../assets/images/dadabase.png';

const useStyles = makeStyles(menubarStyle);
const homeURL = '/';

// Menubar for main layout
const Menubar = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.header} container>
      <Grid className={classes.headerContainer} container>
        <AppBar position="static" className={`${classes.appbar} ${classes.appbarMain}`}>
          <Toolbar className={classes.toolbar}>
            <Grid item className={classes.title}>
              <a className={classes.brand} href={homeURL}>
                <img src={logo} alt="Dadabase icon" className={classes.logo} />
              </a>
            </Grid>

            <UserMenu showUsername />
          </Toolbar>
        </AppBar>
      </Grid>
    </Grid>
  );
};

export default Menubar;
