import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-quality-levels';
import 'videojs-seek-buttons';
import 'videojs-seek-buttons/dist/videojs-seek-buttons.css';
import 'videojs-hls-quality-selector';
import '@videojs/http-streaming';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import videoPlayerStyle from '../../../theme/styles/components/VideoPlayer';

const useStyles = makeStyles(videoPlayerStyle);

const Player = ({ src, poster, updateCurrentTime, playerElement }) => {
  const playerRef = useRef();
  const playerId = 'video-player';
  const classes = useStyles();
  const videoPlayerOptions = {
    controls: true,
    autoplay: false,
    preload: false,
    poster,
    aspectRatio: '16:9',
  };

  useEffect(() => {
    const player = videojs(playerRef.current, videoPlayerOptions, () => {
      player.src(src);
      player.hlsQualitySelector({
        displayCurrentQuality: true,
      });

      player.seekButtons({
        back: 10,
      });

      player.on('timeupdate', () => {
        const time = Math.floor(player.currentTime());
        updateCurrentTime(time);
      });

      player.on('pause', () => {
        const time = Math.floor(player.currentTime());
        updateCurrentTime(time);
      });
    });
    playerElement(player);
  }, []);

  return (
    <Container maxWidth={false} className={classes.videoContainer}>
      <video
        ref={playerRef}
        id={playerId}
        className="video-js vjs-default-skin vjs-big-play-centered"
        width="auto"
        height="auto"
        style={{ 'object-fit': 'cover' }}
      />
    </Container>
  );
};

Player.propTypes = {
  src: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  updateCurrentTime: PropTypes.func.isRequired,
  playerElement: PropTypes.func,
};
Player.defaultProps = {
  playerElement: () => true,
};

export default Player;
