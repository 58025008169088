// Application route path information
const RoutePaths = {
  HOME: '/',
  PROJECTS: '/projects',
  PROJECT_MEDIA: '/project/:projectId',
  PROJECT_MEDIA_TABLE: '/project/:projectId/:tableId',
  PROJECT_MEDIA_TABLE_VIEW: '/project/:projectId/:tableId/:viewId',
  ACCOUNT: '/account',
  LOGOUT: '/logout',
};

export default RoutePaths;
