import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'videojs-playlist';
import 'videojs-playlist/dist/videojs-playlist';
import 'videojs-playlist-ui';
import 'videojs-playlist-ui/dist/videojs-playlist-ui.css';
import 'videojs-contrib-quality-levels';
import 'videojs-seek-buttons';
import 'videojs-seek-buttons/dist/videojs-seek-buttons.css';
import 'videojs-hls-quality-selector';
import '@videojs/http-streaming';
import { Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import playlistStyle from '../../../theme/styles/components/Playlist';

const useStyles = makeStyles(playlistStyle);

const PlaylistPlayer = ({ playlistItems, setPlaylistPlayerElement }) => {
  const classes = useStyles();
  const playerRef = useRef();
  const playerId = 'playlist-player';

  const videoPlayerOptions = {
    controls: true,
    autoplay: false,
    preload: false,
    aspectRatio: '16:9',
  };

  useEffect(() => {
    const player = videojs(playerRef.current, videoPlayerOptions, () => {
      // Set playlist UI
      player.playlistUi();

      // Set playlist items
      player.playlist(playlistItems);

      // Set index of video to play from playlist items
      player.playlist.autoadvance(0);

      player.hlsQualitySelector({
        displayCurrentQuality: true,
      });

      player.seekButtons({
        back: 10,
      });

      player.on('timeupdate', () => {
        const time = Math.floor(player.currentTime());
        // Get current playlist item
        const endDuration = playlistItems[player.playlist.currentItem()];

        // Check if current time is not crossed marker end time
        if (endDuration.endTimeSeconds <= time) {
          // Check playlist if reached at end
          if (player.playlist.currentItem() === playlistItems.length - 1) {
            // Reset player
            player.playlist.currentItem(0);
          } else {
            // Play next item
            player.playlist.next();
          }
        }
      });

      player.on('playlistitem', () => {
        // Get current playlist item
        const currentItem = playlistItems[player.playlist.currentItem()];
        // Set start time of related marker
        player.currentTime(currentItem.startTimeSeconds);
      });
    });
    setPlaylistPlayerElement(player);
  }, []);

  return (
    <Container>
      <section className={classes.mainPreviewPlayer}>
        <video
          ref={playerRef}
          id={playerId}
          className="video-js vjs-default-skin vjs-big-play-centered"
          width="auto"
          height="auto"
          style={{ 'object-fit': 'cover' }}
        />

        <div
          className="playlist-container  preview-player-dimensions vjs-fluid"
          style={{ display: 'none' }}
        >
          <ol className="vjs-playlist" />
        </div>
      </section>
    </Container>
  );
};

PlaylistPlayer.propTypes = {
  playlistItems: PropTypes.arrayOf().isRequired,
  setPlaylistPlayerElement: PropTypes.func.isRequired,
};

export default PlaylistPlayer;
