// Style for page heading
const Style = () => ({
  header: {
    paddingTop: 5,
    paddingBottom: 5,
    flex: 1,
    marginBottom: 10,
  },
  headerText: {
    color: 'inherit',
    fontWeight: '600',
  },
});

export default Style;
