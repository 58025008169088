import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  arrangPlaylistItemWithDeletedPosition,
  getPlaylistData,
  getPlaylistItems,
} from '../../services/Playlist';
import {
  deleteTableData,
  getTableDetailsFromTableName,
  saveTableData,
} from '../../services/Tables';
import { getTableColumns } from '../../services/Columns';
import formPopupStyle from '../../theme/styles/components/FormPopup';

const useStyles = makeStyles(formPopupStyle);

const PlaylistModel = ({ onClose, marker }) => {
  const classes = useStyles();
  const [processing, setProcessing] = useState(false);
  const [playLists, setPlayLists] = useState([]);
  const [plListItems, setPlListItems] = useState(null);
  const [playlistItemsTable, setPlaylistItemsTable] = useState(null);
  const [allColumnsList, setAllColumnsList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const setTableColumns = (table) => {
    getTableColumns(table.id).then((resColumns) => {
      const allColumns = [];

      resColumns.forEach((obj) => {
        allColumns.push({
          id: obj.id,
          type: obj.type,
          name: obj.name,
          refType: obj.refType,
          relation: obj.relation,
        });
      });
      setAllColumnsList(allColumns);
    });
  };

  useEffect(() => {
    getTableDetailsFromTableName('playlistItems').then((res) => {
      setPlaylistItemsTable(res[0]);
      setTableColumns(res[0]);
    });
    setProcessing(true);
    getPlaylistData()
      .then(async (res) => {
        setPlayLists([]);
        const tmpObj = {};
        if (res.length === 0) {
          setPlayLists(res);
          setProcessing(false);
        }
        const promises = [];

        res.forEach(async (pl) => {
          tmpObj[pl.id] = [];

          const promise = new Promise((resolve) => {
            getPlaylistItems(pl.id, marker.id).then((playListItem) => {
              if (playListItem.length > 0) {
                playListItem.forEach((foundItem) => {
                  tmpObj[pl.id].push({
                    id: foundItem.id,
                    markerId: foundItem.references.marker,
                    ...foundItem,
                  });
                });
              }
              resolve();
            });
          });

          promises.push(promise);
        });

        await Promise.all(promises);
        setPlListItems(tmpObj);
        setPlayLists(res);
        setProcessing(false);
      })
      .catch(() => {
        setProcessing(false);
      });
  }, []);

  const saveChanges = (e) => {
    setIsSubmitted(true);
    e.preventDefault();
    const promises = [];
    playLists.forEach((pl) => {
      // eslint-disable-next-line no-async-promise-executor
      const promise = new Promise(async (resolve) => {
        const check = plListItems[pl.id].filter((v) => v.markerId === marker.id);
        if (e.target[pl.id].checked) {
          if (check.length > 0) {
            resolve();
          }
          if (check.length === 0) {
            const data = {
              marker: { ...marker, value: marker.id, label: marker.name },
              playlist: { ...pl, value: pl.id, label: pl.name },
              displayName: marker.displayName,
            };
            await saveTableData(data, 'playlistItems', allColumnsList, {
              position: plListItems[pl.id].length + 1,
            });
            resolve();
          }
        } else if (check.length > 0) {
          await deleteTableData([check[0].id], 'playlistItems', playlistItemsTable.id);
          await arrangPlaylistItemWithDeletedPosition(pl.id, check[0].position);
          resolve();
        } else {
          resolve();
        }
      });

      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      setIsSubmitted(false);
      onClose(false);
    });
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{`Add to Playlist `}</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          {processing && <CircularProgress size={25} />}
          {!processing && playLists.length === 0 && (
            <Typography variant="body1">No playlist found, Please create new playlist.</Typography>
          )}
        </Box>

        {!processing && playLists.length > 0 && (
          <form onSubmit={saveChanges} id="playlist-form">
            <List className="align-flex-start">
              {playLists.map((pl) => (
                <ListItem key={pl.name} dense className={classes.rowItem}>
                  <Checkbox
                    size="small"
                    type="checkbox"
                    id={pl.id}
                    name={pl.id}
                    defaultChecked={
                      plListItems &&
                      plListItems[pl.id].filter((v) => v.markerId === marker.id).length > 0
                    }
                  />
                  <Typography>{pl.displayName}</Typography>
                </ListItem>
              ))}
            </List>
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          form="playlist-form"
          disabled={playLists.length === 0 || isSubmitted}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PlaylistModel.propTypes = {
  onClose: PropTypes.func.isRequired,
  marker: PropTypes.objectOf().isRequired,
};

export default PlaylistModel;
