import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import formPopupStyle from '../../../../theme/styles/components/FormPopup';

const useStyles = makeStyles(formPopupStyle);

const Sorting = ({ handleSuccess, handleDismiss, gridApi }) => {
  const classes = useStyles();

  const [columnsList, setColumnsList] = useState([]);

  useEffect(() => {
    const columns = [];
    const sortedColumns = gridApi.columnApi.getColumnState();

    sortedColumns.forEach((obj) => {
      if (obj.sort) {
        columns.push({
          id: obj.colId,
          name: gridApi.columnApi.getColumn(obj.colId).colDef.headerName,
          order: obj.sort === 'asc' ? 'Ascending' : 'Descending',
        });
      }
    });

    setColumnsList(columns);
  }, []);

  const handleClose = () => {
    handleDismiss();
  };

  const handleRemoveSort = (colId) => {
    const updatedColumnsList = [];
    columnsList.forEach((obj) => {
      if (obj.id !== colId) {
        updatedColumnsList.push({
          id: obj.id,
          name: obj.name,
          order: obj.sort,
        });
      }
    });

    setColumnsList(updatedColumnsList);
    handleSuccess(colId);
    if (updatedColumnsList.length === 0) {
      handleClose();
    }
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">
        Sorted Fields
        <IconButton
          aria-label="close"
          size="small"
          className={classes.viewToolCloseButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.DialogContent}>
        {columnsList.length > 0 && (
          <List>
            {columnsList.map((object) => (
              <ListItem button className={classes.rowItem} key={`colm-${object.id}`}>
                <ListItemText primary={`${object.name} (${object.order})`} />
                <ListItemSecondaryAction className={classes.rowActionContainer}>
                  <IconButton
                    aria-label="delete"
                    className={classes.rowActionButton}
                    onClick={() => handleRemoveSort(object.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
        {columnsList.length === 0 && <div>No sorting is applied on the data.</div>}
      </DialogContent>
    </Dialog>
  );
};

Sorting.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  gridApi: PropTypes.any.isRequired,
};

export default Sorting;
