import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LaunchIcon from '@material-ui/icons/Launch';
import accountStyle from '../../theme/styles/components/AccountSection';

const useStyles = makeStyles(accountStyle);

// Billing section
const Billing = () => {
  const classes = useStyles();

  return (
    <div className={classes.formContainer}>
      <Typography variant="body1" component="p" className={classes.infoText}>
        You will be redirected to the payment portal for managing subscriptions. You can manage
        cards, cancel/re-activate project subscriptions, manage invoices from there.
      </Typography>

      <Button variant="contained" color="primary" startIcon={<LaunchIcon />}>
        Manage Subscriptions
      </Button>
    </div>
  );
};

export default Billing;
