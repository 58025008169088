// Style for project item
const Style = () => ({
  projectItem: {
    display: 'flex',
    height: '200px',
    width: '271px',
    marginRight: '20px',
    marginLeft: '20px',
    WebkitBoxOrient: 'vertical',
    WebkitBoxDirection: 'normal',
    WebkitFlexDirection: 'column',
    MsFlexDirection: 'column',
    flexDirection: 'column',
    WebkitBoxPack: 'justify',
    WebkitJustifyContent: 'space-between',
    MsFlexPack: 'justify',
    justifyContent: 'space-between',
    borderRadius: '12px',
    backgroundColor: '#fff',
    boxShadow: '4px 4px 8px 0 rgb(0 0 0 / 34%)',
    cursor: 'pointer',
  },
  chip: {
    width: '32px',
    height: '32px',
    marginTop: '13px',
    marginRight: '24px',
    borderRadius: '20px',
    backgroundColor: '#f66',
  },
  projectName: {
    marginTop: '9px',
    marginLeft: '24px',
    color: '#000',
    fontSize: '24px',
    fontWeight: '500',
  },
  cardNameReset: {
    padding: '0',
    height: '100%',
  },
  projectItemBottom: {
    marginRight: '18px',
    alignSelf: 'flex-end',
  },
  groupIcon: {
    width: '36px',
    height: '36px',
    marginBottom: '6px',
    marginLeft: '3px',
  },
  gearIcon: {
    width: '32px',
    height: '32px',
    marginBottom: '6px',
    marginLeft: '22px',
  },
  access: {
    display: 'block',
    marginLeft: '24px',
    color: '#000',
    fontSize: '18px',
    fontWeight: '500',
  },
  addButton: {
    height: '100%',
    WebkitBoxOrient: 'vertical',
    WebkitBoxDirection: 'normal',
    WebkitFlexDirection: 'column',
    MsFlexDirection: 'column',
    flexDirection: 'column',
    WebkitBoxPack: 'center',
    WebkitJustifyContent: 'center',
    MsFlexPack: 'center',
    justifyContent: 'center',
    WebkitBoxAlign: 'center',
    WebkitAlignItems: 'center',
    MsFlexAlign: 'center',
    alignItems: 'center',
  },
  addIcon: {
    width: '48px',
    height: '48px',
  },
  iconButton: {
    padding: '0',
    minWidth: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  placeholderContent: {
    paddingTop: '8px',
    paddingBottom: '0',
  },
  placeholderActions: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px',
  },
});

export default Style;
