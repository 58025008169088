import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ColumnTypes from '../../../config/ColumnTypes';
import Validations from '../../../utils/Validations';
import { createDateString, getCurrentTimestamp } from '../../../utils/DateTime';
import { getTableDataOnlyName, updateTableData } from '../../../services/Tables';
import { getRefLookupColumns, getNameColumnDetails } from '../../../services/Columns';
import dataSyncLogs from '../../../services/SyncData';
import formPopupStyle from '../../../theme/styles/components/FormPopup';

const useStyles = makeStyles(formPopupStyle);

// Cell editor
const CellEditor = ({ params, tableId, collectionName, handleSuccess, handleDismiss }) => {
  const classes = useStyles();
  const colType = params.colDef.custom.type;
  const colRefType = params.colDef.custom.refType;
  const colHeader = params.colDef.headerName;
  const colField = params.colDef.field;
  let currentValue = null;
  if (params.data.references) {
    currentValue =
      colField in params.data.references ? params.data.references[colField] : params.value;
  } else {
    currentValue = params.value;
  }

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [refOptions, setRefOptions] = useState([]);
  const [isRefLoaded, setIsRefLoaded] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    colRefType === ColumnTypes.MULTI_REFERENCE.value ? [] : null
  );
  const [lookupCols, setLookupCols] = useState({});
  const [nameColId, setNameColId] = useState(null);

  const { handleSubmit, control } = useForm();

  useEffect(() => {
    if ([ColumnTypes.REFERENCE.value, ColumnTypes.MULTI_REFERENCE.value].includes(colRefType)) {
      const refTable = params.colDef.custom.relation.tableName;
      getTableDataOnlyName(refTable).then((rows) => {
        let selectedData = null;
        if (colRefType === ColumnTypes.REFERENCE.value) {
          selectedData = rows.filter((r) => r.value === currentValue);
          setSelectedOptions(selectedData[0]);
        } else {
          selectedData = rows.filter((r) => (currentValue || []).includes(r.value));
          setSelectedOptions(selectedData);
        }

        setRefOptions(rows);
        setIsRefLoaded(true);
      });

      getRefLookupColumns(params.colDef.custom.colId, tableId).then((cols) => {
        setLookupCols(cols);
      });
    } else {
      setIsRefLoaded(true);
      if (colField === 'displayName') {
        getNameColumnDetails(tableId).then((res) => {
          setNameColId(res);
        });
      }
    }
  }, []);

  const onSubmit = (data) => {
    setIsSubmitted(true);

    const saveData = {
      modified: getCurrentTimestamp(),
      references: params.data.references || {},
    };
    const syncColsArray = [];

    if (colRefType === ColumnTypes.REFERENCE.value) {
      saveData[colField] = data[colField] ? data[colField].label || '' : '';
      saveData.references[colField] = data[colField] ? data[colField].value || null : null;

      syncColsArray.push({
        tableId,
        columnId: params.colDef.custom.colId,
        docId: params.data.id,
        oldValue: params.value || '',
        newValue: saveData[colField],
      });

      const lookupColsData = Object.entries(lookupCols);
      lookupColsData.forEach((e) => {
        const c = e[1];
        saveData[c.name] = data[colField] ? data[colField][c.referencedCol] || '' : '';

        syncColsArray.push({
          tableId,
          columnId: c.id,
          docId: params.data.id,
          oldValue: params.data[c.name] || '',
          newValue: saveData[c.name],
        });
      });
    } else if (colRefType === ColumnTypes.MULTI_REFERENCE.value) {
      const actualValue = [];
      const ids = [];
      if (data[colField]) {
        data[colField].forEach((d) => {
          actualValue.push(d.label);
          ids.push(d.value);
        });
      }

      saveData[colField] = actualValue;
      saveData.references[colField] = ids;

      syncColsArray.push({
        tableId,
        columnId: params.colDef.custom.colId,
        docId: params.data.id,
        oldValue: params.value || '',
        newValue: saveData[colField],
      });

      const lookupColsData = Object.entries(lookupCols);
      lookupColsData.forEach((e) => {
        const c = e[1];
        const fieldValues = [];
        if (data[colField]) {
          data[colField].forEach((d) => {
            fieldValues.push(d[c.referencedCol] || '');
          });
        }
        saveData[c.name] = fieldValues;

        syncColsArray.push({
          tableId,
          columnId: c.id,
          docId: params.data.id,
          oldValue: params.data[c.name] || '',
          newValue: saveData[c.name],
        });
      });
    } else if (colType === ColumnTypes.DATE.value) {
      saveData[colField] = createDateString(data[colField]);

      syncColsArray.push({
        tableId,
        columnId: params.colDef.custom.colId,
        docId: params.data.id,
        oldValue: params.value || '',
        newValue: saveData[colField],
      });
    } else {
      saveData[colField] = data[colField];

      syncColsArray.push({
        tableId,
        columnId: params.colDef.custom.colId,
        docId: params.data.id,
        oldValue: params.value || '',
        newValue: saveData[colField],
      });

      if (colField === 'displayName') {
        // do some more changes
        saveData.name = `${data[colField]}_${params.data.rand}`;

        syncColsArray.push({
          tableId,
          columnId: nameColId,
          docId: params.data.id,
          oldValue: params.data.name || '',
          newValue: saveData.name,
        });
      }
    }

    updateTableData(params.data.id, saveData, collectionName, colField)
      .then(() => {
        dataSyncLogs(syncColsArray);
        handleSuccess(saveData[colField]);
      })
      .catch(() => {
        setIsSubmitted(false);
      });
  };

  const handleClose = () => {
    handleDismiss();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">Update Value - {params.colDef.headerName}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <form id="edit-data" onSubmit={handleSubmit(onSubmit)}>
            {colField === 'displayName' && (
              <FormControl className={classes.formInput}>
                <Controller
                  control={control}
                  rules={Validations.REQUIRED}
                  name={colField}
                  id={colField}
                  defaultValue={currentValue}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      autoFocus
                      label={colHeader}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      type="text"
                    />
                  )}
                />
              </FormControl>
            )}
            {colType === ColumnTypes.STRING.value &&
              colRefType === 'NONE' &&
              colField !== 'name' &&
              colField !== 'displayName' && (
                <FormControl className={classes.formInput}>
                  <Controller
                    control={control}
                    name={colField}
                    id={colField}
                    defaultValue={currentValue}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        autoFocus
                        label={colHeader}
                        value={value}
                        onChange={onChange}
                        type="text"
                      />
                    )}
                  />
                </FormControl>
              )}
            {colType === ColumnTypes.IMAGE.value && colRefType === 'NONE' && (
              <FormControl className={classes.formInput}>
                <Controller
                  control={control}
                  rules={Validations.OPTIONAL_URL}
                  name={colField}
                  id={colField}
                  defaultValue={currentValue}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      autoFocus
                      label={colHeader}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      type="text"
                    />
                  )}
                />
              </FormControl>
            )}
            {colType === ColumnTypes.NUMBER.value && colRefType === 'NONE' && (
              <FormControl className={classes.formInput}>
                <Controller
                  control={control}
                  name={colField}
                  id={colField}
                  defaultValue={currentValue}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      autoFocus
                      label={colHeader}
                      value={value}
                      onChange={onChange}
                      type="number"
                    />
                  )}
                />
              </FormControl>
            )}
            {colType === ColumnTypes.DATE.value && colRefType === 'NONE' && (
              <>
                <InputLabel htmlFor={colField}>{colHeader}</InputLabel>
                <FormControl className={classes.formInput}>
                  <Controller
                    control={control}
                    name={colField}
                    id={colField}
                    defaultValue={currentValue ? new Date(currentValue) : new Date()}
                    render={({ field: { onChange, value } }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          clearable
                          autoOk
                          format="MM/dd/yyyy"
                          value={value}
                          onChange={onChange}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  />
                </FormControl>
              </>
            )}
            {colType === ColumnTypes.SINGLE_SELECT.value && colRefType === 'NONE' && (
              <FormControl className={classes.formInput}>
                <Controller
                  control={control}
                  name={colField}
                  id={colField}
                  defaultValue={currentValue}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <InputLabel htmlFor={colField}>{colHeader}</InputLabel>
                      <Select autoFocus value={value} onChange={onChange}>
                        <MenuItem value="">Select</MenuItem>
                        {params.colDef.custom.staticOptions.map((v) => (
                          <MenuItem value={v} key={`op-${Math.random()}`}>
                            {v}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </FormControl>
            )}
            {colRefType === ColumnTypes.REFERENCE.value && isRefLoaded && (
              <FormControl className={classes.formInput}>
                <Controller
                  control={control}
                  name={colField}
                  id={colField}
                  defaultValue={selectedOptions}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Autocomplete
                        options={refOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(opts) => <TextField {...opts} label={colHeader} />}
                        onChange={(_, data) => onChange(data)}
                        value={value}
                      />
                    </>
                  )}
                />
              </FormControl>
            )}
            {colRefType === ColumnTypes.MULTI_REFERENCE.value && isRefLoaded && (
              <FormControl className={classes.formInput}>
                <Controller
                  control={control}
                  name={colField}
                  id={colField}
                  defaultValue={selectedOptions}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Autocomplete
                        multiple
                        options={refOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(opts) => <TextField {...opts} label={colHeader} />}
                        onChange={(_, data) => onChange(data)}
                        value={value}
                      />
                    </>
                  )}
                />
              </FormControl>
            )}
          </form>
        </DialogContentText>
      </DialogContent>
      {isRefLoaded && (
        <DialogActions>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            form="edit-data"
            variant="contained"
            color="primary"
            disabled={isSubmitted}
          >
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

CellEditor.propTypes = {
  params: PropTypes.any.isRequired,
  tableId: PropTypes.string.isRequired,
  collectionName: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default CellEditor;
