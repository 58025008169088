import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DataSidebar from './DataSidebar';
import UserMenu from './UserMenu';
import menubarStyle from '../../theme/styles/components/Menubar';
import DadabaseButton from '../../assets/images/dadabase-button.png';

const useStyles = makeStyles(menubarStyle);

// Project menu for data layout
const ProjectMenubar = ({ heading, projectId, tableId }) => {
  const classes = useStyles();

  const [dataSidebarOpen, setDataSidebarOpen] = useState(false);

  const handleDataSidebar = () => {
    setDataSidebarOpen(!dataSidebarOpen);
  };

  return (
    <>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.tableMenuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDataSidebar}
          >
            <MenuIcon fontSize="medium" />
          </IconButton>
          <img src={DadabaseButton} alt="Dadabase logo" className={classes.logoSmall} />
          <Typography variant="h6" className={classes.title}>
            {heading}
          </Typography>

          <UserMenu />
        </Toolbar>
      </AppBar>

      {tableId !== '' && tableId !== null && (
        <DataSidebar
          dataSidebarOpen={dataSidebarOpen}
          projectId={projectId}
          tableId={tableId}
          handleDataSidebar={handleDataSidebar}
        />
      )}
    </>
  );
};

ProjectMenubar.propTypes = {
  heading: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  tableId: PropTypes.string.isRequired,
};

export default ProjectMenubar;
