import { clientPrjApp } from '../config/FirebaseProject';
import { getCurrentTimestamp } from '../utils/DateTime';

// Create new marker
export const createNewMarker = async (data) => {
  await clientPrjApp.dbPrj.collection('markers').add({
    ...data,
    type: 'Track',
    modified: getCurrentTimestamp(),
  });
};

// Get list of markers
export const getVideoMarkers = async (videoId) => {
  const rows = [];
  const tableName = 'markers';

  const dataSnap = await clientPrjApp.dbPrj
    .collection(tableName)
    .where('references.video', '==', videoId)
    .orderBy('startTimeSeconds')
    .get();

  dataSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      id: doc.id,
    });
  });

  return rows;
};

// Update marker
export const updateMarker = async (id, data) => {
  await clientPrjApp.dbPrj
    .collection('markers')
    .doc(id)
    .update({
      ...data,
      modified: getCurrentTimestamp(),
    });
};

// Get marker details
export const getMarkerDetails = async (id) => {
  const marker = await clientPrjApp.dbPrj.collection('markers').doc(id).get();
  if (!marker.exists) {
    return null;
  }

  return { id: marker.id, ...marker.data() };
};
