import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteColumn from './DeleteColumn';
import RenameColumn from './RenameColumn';
import { getTableColumns } from '../../../services/Columns';
import formPopupStyle from '../../../theme/styles/components/FormPopup';

const useStyles = makeStyles(formPopupStyle);

const ManageColumns = ({ tableId, handleSuccess, handleDismiss }) => {
  const classes = useStyles();

  const [hasChanged, setHasChanged] = useState(false);
  const [columnsList, setColumnsList] = useState([]);
  const [showRenameForm, setShowRenameForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  useEffect(() => {
    getTableColumns(tableId).then((resColumns) => {
      const columns = [];

      resColumns.forEach((obj) => {
        columns.push({
          id: obj.id,
          displayName: obj.displayName,
          isSystemColumn: obj.isSystemColumn,
          type: obj.type,
        });
      });

      setColumnsList(columns);
    });
  }, [reloadData]);

  const handleClose = () => {
    if (hasChanged) {
      handleSuccess();
    } else {
      handleDismiss();
    }
  };

  const handleShowRenameForm = (val) => {
    setSelectedRecord(val);
    setShowRenameForm(true);
  };
  const handleCloseRenameForm = () => {
    setShowRenameForm(false);
    setSelectedRecord(null);
  };
  const handleCloseRenameFormSuccess = () => {
    setReloadData(!reloadData);
    setShowRenameForm(false);
    setSelectedRecord(null);
    setHasChanged(true);
  };

  const handleShowDeleteForm = (val) => {
    setSelectedRecord(val);
    setShowDeleteForm(true);
  };
  const handleCloseDeleteForm = () => {
    setShowDeleteForm(false);
    setSelectedRecord(null);
  };
  const handleCloseDeleteFormSuccess = () => {
    setReloadData(!reloadData);
    setShowDeleteForm(false);
    setSelectedRecord(null);
    setHasChanged(true);
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">
        Edit Fields
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List>
          {columnsList.map((object) => (
            <>
              {(selectedRecord === null || selectedRecord.id !== object.id) && (
                <ListItem button className={classes.rowItem} key={`colm-${object.id}`}>
                  <ListItemText primary={object.displayName} secondary={object.type} />
                  {!object.isSystemColumn && (
                    <ListItemSecondaryAction className={classes.rowActionContainer}>
                      <IconButton
                        aria-label="edit"
                        className={classes.rowActionButton}
                        onClick={() => handleShowRenameForm(object)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        className={classes.rowActionButton}
                        onClick={() => handleShowDeleteForm(object)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              )}

              {showRenameForm && selectedRecord.id === object.id && (
                <RenameColumn
                  rowData={selectedRecord}
                  handleSuccess={handleCloseRenameFormSuccess}
                  handleDismiss={handleCloseRenameForm}
                />
              )}
              {showDeleteForm && selectedRecord.id === object.id && (
                <DeleteColumn
                  rowData={selectedRecord}
                  handleSuccess={handleCloseDeleteFormSuccess}
                  handleDismiss={handleCloseDeleteForm}
                />
              )}
            </>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

ManageColumns.propTypes = {
  tableId: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default ManageColumns;
