// Style for table toolbar
const Style = (theme) => ({
  root: {
    padding: '14px 24px 8px',
    background: 'white',
    '& .MuiButton-root': {
      fontSize: '.8rem',
    },
  },
  buttonBadgeContainer: {
    marginRight: '14px',
  },
  buttonBadge: {
    background: theme.palette.primary.light,
  },
  hideFieldsBtn: {
    marginBottom: 5,
    padding: '4px 10px',
    background: 'transparent',
    color: theme.palette.grey[600],
    fontWeight: 'medium',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      border: 0,
      margin: 0,
      padding: 0,
      minWidth: 0,
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
  },
  filtersBtn: {
    marginBottom: 5,
    padding: '4px 10px',
    background: 'transparent',
    color: theme.palette.grey[600],
    fontWeight: 'medium',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      border: 0,
      margin: 0,
      padding: 0,
      minWidth: 0,
      '& .MuiButton-startIcon': {
        margin: 0,
      },
      '&.Mui-disabled': {
        background: 'transparent',
      },
    },
  },
  searchSection: {
    textAlign: 'right',
  },
  searchInput: {
    width: 210,
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: '0 12px',
    borderRadius: '8px',
    color: theme.palette.grey[600],
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      border: 0,
      '& input': {
        display: 'none',
      },
    },
  },
  searchInputInput: {
    fontSize: '15px',
  },
  searchIcon: {
    marginRight: '8px',
    color: theme.palette.grey[600],
  },
  refreshBtn: {
    marginLeft: 15,
    minWidth: 0,
    padding: '4px 10px',
  },
  downloadBtn: {
    marginLeft: 5,
    minWidth: 0,
    padding: '4px 10px',
    background: 'transparent',
    '& >span': {
      fontSize: '0.8rem',
      color: theme.palette.grey[600],
    },
    '& svg': {
      fontSize: '1.2rem',
      marginRight: '4px',
      marginBottom: '-3px',
      color: theme.palette.grey[600],
    },
  },
});

export default Style;
