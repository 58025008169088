/* eslint-disable import/no-cycle */
import firebase from 'firebase/app';
// import { signinToTenantApp } from '../services/Auth';
// import { getUserDetails } from '../services/Users';
import 'firebase/auth';
import 'firebase/firestore';
// import { clientPrjApp } from './FirebaseProject';

// Firebase configurations
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize firebase app
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Initialize firebase services
const auth = firebaseApp.auth();
const db = firebaseApp.firestore();

// Connect services with emulator
if (window.location.hostname === 'localhost') {
  auth.useEmulator('http://localhost:9098');
  db.useEmulator('localhost', 8081);
}

auth.onAuthStateChanged(async (user) => {
  if (user) {
    /*
    const getUser = await getUserDetails(user.uid);
    if (getUser) {
      try {
        const getToken = await signinToTenantApp({ id: user.uid, role: getUser.role });
        clientPrjApp.dbPrjAuth.signInWithCustomToken(getToken.customToken);
      } catch {
        // console.log('error on teneant login');
      }
    }
    */
  }
});

export { auth, db };
