import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getMyProjects } from '../../services/Projects';
import Project from '../../components/projects/Project';
import EmptyProject from '../../components/projects/EmptyProject';
import ProjectPlaceholder from '../../components/projects/ProjectPlaceholder';
import NoProject from '../../components/projects/NoProject';
import CreateProject from '../../components/projects/CreateProject';
import ProjectsLayout from '../../layout/Projects';
import pageStyle from '../../theme/styles/pages/Projects';

const useStyles = makeStyles(pageStyle);

const Projects = () => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const pageHeading = 'My Projects';

  const [openAddNewForm, setOpenAddNewForm] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [refershList, setRefreshList] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getMyProjects()
      .then((data) => {
        setDataLoaded(true);
        setRows(data);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [refershList]);

  const handleNewProject = () => {
    setOpenAddNewForm(!openAddNewForm);
  };

  const handleRefreshList = () => {
    setDataLoaded(false);
    setOpenAddNewForm(false);
    setRefreshList(!refershList);
  };

  return (
    <ProjectsLayout pageHeading={pageHeading}>
      <>
        {dataLoaded && rows.length === 0 && <NoProject handleNewProject={handleNewProject} />}

        {dataLoaded && rows.length > 0 && (
          <>
            {rows.map((value) => (
              <Project data={value} handleRefreshList={handleRefreshList} />
            ))}
            <EmptyProject handleNewProject={handleNewProject} />
          </>
        )}

        {!dataLoaded && (
          <>
            {[1, 2, 3, 4].map((value) => (
              <ProjectPlaceholder key={`prj-${value.id}`} />
            ))}
          </>
        )}

        {openAddNewForm && (
          <CreateProject
            handleNewProject={handleNewProject}
            handleRefreshList={handleRefreshList}
          />
        )}
      </>
    </ProjectsLayout>
  );
};

export default Projects;
