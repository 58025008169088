import axios from 'axios';
import ColumnTypes from '../config/ColumnTypes';
import { clientPrjApp } from '../config/FirebaseProject';
import getDataSource from '../utils/DataSource';
import { createDateString, getCurrentTimestamp } from '../utils/DateTime';
import getRandString from '../utils/RandString';
import dataSyncLogs, { sharedDataSyncLogs } from './SyncData';

// Create new table
export const createTable = async (data) => {
  const snapConfig = await clientPrjApp.dbPrj.collection('configs').doc('cfUrl').get();
  const snapData = snapConfig.data();

  const apiEndpoint = snapData.url;
  const result = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/create-table`,
    withCredentials: true,
    data,
  });

  return result.data;
};

// Rename table
export const renameTable = async (data) => {
  const snapConfig = await clientPrjApp.dbPrj.collection('configs').doc('cfUrl').get();
  const snapData = snapConfig.data();

  const apiEndpoint = snapData.url;
  const result = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/rename-table`,
    withCredentials: true,
    data,
  });

  return result.data;
};

// Delete table
export const deleteTable = async (data) => {
  const snapConfig = await clientPrjApp.dbPrj.collection('configs').doc('cfUrl').get();
  const snapData = snapConfig.data();

  const apiEndpoint = snapData.url;
  const result = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/delete-table`,
    withCredentials: true,
    data,
  });

  await sharedDataSyncLogs({
    tableId: data.tableId,
    type: 'TABLE_DELETE',
  });

  return result.data;
};

// Get list of tables
export const getAllTables = async () => {
  const rows = [];

  const tablesSnap = await clientPrjApp.dbPrj
    .collection('tables')
    .where('isDeleted', '==', false)
    .orderBy('created')
    .get();

  tablesSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      id: doc.id,
    });
  });

  return rows;
};

// Get default table from project
export const getDefaultTable = async () => {
  let defaultTableId = null;

  const defaultTable = await clientPrjApp.dbPrj
    .collection('tables')
    .where('name', '==', 'videos')
    .limit(1)
    .get();

  defaultTable.forEach((doc) => {
    defaultTableId = doc.id;
  });

  return defaultTableId;
};

// Get table details
export const getTableDetails = async (id) => {
  if (!id) {
    const tableId = await getDefaultTable();
    const view = await clientPrjApp.dbPrj.collection('tables').doc(tableId).get();
    return { ...view.data(), id: tableId };
  }

  const view = await clientPrjApp.dbPrj.collection('tables').doc(id).get();
  const viewData = view.data();
  if (viewData.isDeleted) {
    return null;
  }
  return { ...viewData, id };
};

// Get list of table data
export const getTableData = async (tableName) => {
  const rows = [];

  const source = await getDataSource(tableName);

  const dataSnap = await clientPrjApp.dbPrjCache.collection(tableName).get({ source });

  dataSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      id: doc.id,
    });
  });

  return rows;
};

// Get list of table data (only name)
export const getTableDataOnlyName = async (tableName) => {
  const rows = [];

  const source = await getDataSource(tableName);

  const dataSnap = await clientPrjApp.dbPrjCache
    .collection(tableName)
    .orderBy('name')
    .get({ source });

  dataSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      value: doc.id,
      label: tempData.name,
    });
  });

  return rows;
};

// Save row data
export const saveTableData = async (data, tableName, allColumnsList, hiddenFields = null) => {
  const saveData = {
    rand: getRandString(4),
    references: {},
    modified: getCurrentTimestamp(),
  };
  const refColsData = {};
  allColumnsList.forEach((col) => {
    const { id, name, refType, relation, type } = col;

    if (refType === ColumnTypes.REFERENCE.value) {
      if (data[name]) {
        saveData[name] = data[name].label;
        saveData.references[name] = data[name].value;

        refColsData[id] = data[name];
      } else {
        saveData[name] = '';
      }
    } else if (refType === ColumnTypes.MULTI_REFERENCE.value) {
      const actualValue = [];
      const ids = [];

      if (data[name]) {
        data[name].forEach((d) => {
          actualValue.push(d.label);
          ids.push(d.value);
        });

        saveData[name] = actualValue;
        saveData.references[name] = ids;

        refColsData[id] = data[name];
      } else {
        saveData[name] = actualValue;
      }
    } else if (refType === ColumnTypes.LOOKUP.value) {
      if (refColsData[relation.referenceColumnId]) {
        if (Array.isArray(refColsData[relation.referenceColumnId])) {
          const actualValue = [];
          refColsData[relation.referenceColumnId].forEach((d) => {
            actualValue.push(d[relation.columnName]);
          });
          saveData[name] = actualValue;
        } else {
          saveData[name] = refColsData[relation.referenceColumnId][relation.columnName] || '';
        }
      } else {
        saveData[name] = '';
      }
    } else if (type === ColumnTypes.DATE.value) {
      saveData[name] = data[name] ? createDateString(data[name]) : '';
    } else {
      saveData[name] = data[name] || '';
    }
  });
  saveData.name = `${saveData.displayName}_${saveData.rand}`;
  if (tableName === 'playlistItems') {
    saveData.name = `${saveData.playlist}_${saveData.marker}_${saveData.rand}`;
  }

  if (hiddenFields) {
    Object.entries(hiddenFields).forEach((e) => {
      const key = e[0];
      const value = e[1];
      saveData[key] = value;
    });
  }

  await clientPrjApp.dbPrj.collection(tableName).add(saveData);
};

// Update row data
export const updateTableData = async (id, data, tableName, updatedColName) => {
  await clientPrjApp.dbPrj.collection(tableName).doc(id).set(data, { merge: true });
  await sharedDataSyncLogs({
    documentId: id,
    collectionName: tableName,
    updatedColName,
    type: 'ROW_UPDATE',
  });
};

export const deleteTableData = async (ids, tableName, tableId) => {
  const syncColsArray = [];

  for (let i = 0; i < ids.length; i += 1) {
    const payload = {
      documentId: ids[i],
      collectionName: tableName,
      type: 'ROW_DELETE',
    };
    if (tableName === 'markers') {
      // eslint-disable-next-line no-await-in-loop
      const getMarkerVideoRef = await clientPrjApp.dbPrj
        .doc(`${tableName}/${payload.documentId}`)
        .get();
      payload.videoRef = getMarkerVideoRef.data().references;
    }
    // eslint-disable-next-line no-await-in-loop
    await clientPrjApp.dbPrj.collection(tableName).doc(ids[i]).delete();

    // eslint-disable-next-line no-await-in-loop
    await sharedDataSyncLogs(payload);

    syncColsArray.push({
      tableId,
      columnId: null,
      docId: ids[i],
      oldValue: null,
      newValue: null,
      delete: true,
    });
  }

  // Update last record of collection to revalidate cache
  const dataSnap = await clientPrjApp.dbPrj
    .collection(tableName)
    .orderBy('modified', 'desc')
    .limit(1)
    .get();
  let lastDocId = 0;
  dataSnap.forEach((doc) => {
    lastDocId = doc.id;
  });
  if (lastDocId) {
    await clientPrjApp.dbPrj
      .collection(tableName)
      .doc(lastDocId)
      .set({ modified: getCurrentTimestamp() }, { merge: true });
  }

  await dataSyncLogs(syncColsArray);
};

// Get table details from table name
export const getTableDetailsFromTableName = async (tableName) => {
  const table = await clientPrjApp.dbPrj.collection('tables').where('name', '==', tableName).get();
  if (table.size === 0) {
    return [];
  }
  const returnVal = table.docs.map((t) => ({ id: t.id, ...t.data() }));
  return returnVal;
};
