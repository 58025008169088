import axios from 'axios';
import { clientPrjApp } from '../config/FirebaseProject';
import ColumnTypes from '../config/ColumnTypes';
import { sharedDataSyncLogs } from './SyncData';

// Create new column
export const createColumn = async (data) => {
  const snapConfig = await clientPrjApp.dbPrj.collection('configs').doc('cfUrl').get();
  const snapData = snapConfig.data();

  const apiEndpoint = snapData.url;
  const result = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/create-column`,
    withCredentials: true,
    data,
  });

  return result.data;
};

// Rename column
export const renameColumn = async (data) => {
  const snapConfig = await clientPrjApp.dbPrj.collection('configs').doc('cfUrl').get();
  const snapData = snapConfig.data();

  const apiEndpoint = snapData.url;
  const result = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/rename-column`,
    withCredentials: true,
    data,
  });
  await sharedDataSyncLogs({
    columnId: data.columnId,
    type: 'COL_UPDATE',
  });
  return result.data;
};

// Delete column
export const deleteColumn = async (data) => {
  const snapConfig = await clientPrjApp.dbPrj.collection('configs').doc('cfUrl').get();
  const snapData = snapConfig.data();

  const apiEndpoint = snapData.url;
  const result = await axios({
    method: 'POST',
    url: `${apiEndpoint}/api/delete-column`,
    withCredentials: true,
    data,
  });
  await sharedDataSyncLogs({
    columnId: data.colId,
    type: 'COL_DELETE',
  });
  return result.data;
};

// Get list of columns from table
export const getTableColumns = async (tableId) => {
  const rows = [];

  const columnsSnap = await clientPrjApp.dbPrj
    .collection('columns')
    .where('tableId', '==', tableId)
    .where('isDeleted', '==', false)
    .orderBy('created')
    .get();

  columnsSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      id: doc.id,
    });
  });

  return rows;
};

// Get list of reference column from table
export const getTableReferenceColumns = async (tableId) => {
  const rows = [];

  const columnsSnap = await clientPrjApp.dbPrj
    .collection('columns')
    .where('tableId', '==', tableId)
    .where('isDeleted', '==', false)
    .where('refType', 'in', [ColumnTypes.REFERENCE.value, ColumnTypes.MULTI_REFERENCE.value])
    .orderBy('created')
    .get();

  columnsSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      id: doc.id,
    });
  });

  return rows;
};

// Get list of lookup column from reference column (from referenced table)
export const getTableLookupColumns = async (columnId) => {
  const rows = [];

  const refColSnap = await clientPrjApp.dbPrj.collection('columns').doc(columnId).get();
  const refColData = refColSnap.data();

  const columnsSnap = await clientPrjApp.dbPrj
    .collection('columns')
    .where('tableId', '==', refColData.relation.tableId)
    .where('isDeleted', '==', false)
    .orderBy('created')
    .get();

  columnsSnap.forEach((doc) => {
    const tempData = doc.data();

    if (tempData.name !== 'name') {
      rows.push({
        ...tempData,
        id: doc.id,
      });
    }
  });

  return rows;
};

// Get list of lookup columns of reference or multi-reference column (from existing table)
export const getRefLookupColumns = async (columnId, tableId) => {
  const rows = {};

  const columnsSnap = await clientPrjApp.dbPrj
    .collection('columns')
    .where('tableId', '==', tableId)
    .where('relation.referenceColumnId', '==', columnId)
    .where('isDeleted', '==', false)
    .orderBy('created')
    .get();

  columnsSnap.forEach((doc) => {
    const tempData = doc.data();

    rows[tempData.name] = {
      name: tempData.name,
      id: doc.id,
      referencedCol: tempData.relation.columnName,
    };
  });

  return rows;
};

// Get column details
export const getColumnDetails = async (id) => {
  const view = await clientPrjApp.dbPrj.collection('columns').doc(id).get();
  return { ...view.data(), id };
};

// Get name column details
export const getNameColumnDetails = async (tableId) => {
  const query = await clientPrjApp.dbPrj
    .collection('columns')
    .where('tableId', '==', tableId)
    .where('name', '==', 'name')
    .limit(1)
    .get();
  const snapshot = query.docs[0];
  return snapshot.id || null;
};
