import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { auth } from '../../config/Firebase';
import Validations from '../../utils/Validations';
import accountStyle from '../../theme/styles/components/AccountSection';
import { updateProfile } from '../../services/Auth';
import SnackbarMessage from '../SnackbarMessage';
import { getUserDetails } from '../../services/Users';

const useStyles = makeStyles(accountStyle);

// Update profile information
const Profile = () => {
  const classes = useStyles();

  const { handleSubmit, control, setValue } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const displayName = auth.currentUser.displayName || '';
  const email = auth.currentUser.email || '';

  useEffect(() => {
    getUserDetails(auth.currentUser.uid).then((res) => {
      setValue('firstName', res.firstName);
      setValue('lastName', res.lastName);
    });
  }, []);

  const onSubmit = (data) => {
    setSuccessMessage(null);
    setErrorMessage(null);

    updateProfile(data)
      .then(() => {
        setSuccessMessage('Your profile has been successfully updated.');
      })
      .catch(() => {
        setErrorMessage('Some error occurred while updating the profile, please try again.');
      });
  };

  return (
    <div className={classes.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl className={classes.formInput}>
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="firstName"
            id="firstName"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="First Name"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type="text"
              />
            )}
          />
        </FormControl>
        <FormControl className={classes.formInput}>
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="lastName"
            id="lastName"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Last Name"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type="text"
              />
            )}
          />
        </FormControl>
        <FormControl className={classes.formInput}>
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="displayName"
            id="displayName"
            defaultValue={displayName}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Display Name"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type="text"
              />
            )}
          />
        </FormControl>
        <FormControl className={classes.formInput}>
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="email"
            id="email"
            render={({ field: { value } }) => (
              <TextField label="Email" value={value} type="email" />
            )}
            defaultValue={email}
            disabled
          />
        </FormControl>

        <Button type="submit" variant="contained" color="primary" className={classes.formSubmitBtn}>
          Update Profile
        </Button>
      </form>
      {errorMessage && <SnackbarMessage type="error" message={errorMessage} />}
      {successMessage && <SnackbarMessage type="success" message={successMessage} />}
    </div>
  );
};

export default Profile;
