import { auth } from '../config/Firebase';
import { clientPrjApp } from '../config/FirebaseProject';
import { getCurrentTimestamp } from '../utils/DateTime';

// Create new view
export const createView = async (data) => {
  await clientPrjApp.dbPrj.collection('custom_views').add({
    name: data.name,
    displayName: data.name,
    tableId: data.tableId,
    createdBy: auth.currentUser.uid,
    created: getCurrentTimestamp(),
    modified: getCurrentTimestamp(),
    isDeleted: false,
  });
};

// Rename view
export const renameView = async (id, data) => {
  await clientPrjApp.dbPrj.collection('custom_views').doc(id).set(
    {
      displayName: data.name,
      modified: getCurrentTimestamp(),
    },
    { merge: true }
  );
};

// Delete view
export const deleteView = async (id) => {
  await clientPrjApp.dbPrj.collection('custom_views').doc(id).set(
    {
      isDeleted: true,
      modified: getCurrentTimestamp(),
    },
    { merge: true }
  );
};

// Get list of table views
export const getTableViews = async (tableId) => {
  const rows = [];

  const viewsSnap = await clientPrjApp.dbPrj
    .collection('custom_views')
    .where('tableId', '==', tableId)
    .where('isDeleted', '==', false)
    .orderBy('created')
    .get({ source: 'server' });

  viewsSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      id: doc.id,
    });
  });

  return rows;
};

// Get single table view
export const getViewDetails = async (id) => {
  const view = await clientPrjApp.dbPrj.collection('custom_views').doc(id).get();
  const viewData = view.data();
  if (viewData.isDeleted) {
    return null;
  }
  return { ...viewData, id };
};

// Update view information
export const updateTableView = async (id, data) => {
  await clientPrjApp.dbPrj
    .collection('custom_views')
    .doc(id)
    .set(
      {
        ...data,
        modified: getCurrentTimestamp(),
      },
      { merge: true }
    );
};
