// Style for table options
const Style = (theme) => ({
  tableOptions: {
    position: 'absolute',
    top: 4,
    right: 24,
  },
  uploadBtn: {
    background: theme.palette.primary[100],
    color: theme.palette.grey[800],
    padding: '4px 8px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    justifyContent: 'space-around',
    '&:hover': {
      background: theme.palette.primary.lighter,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 30,
    },
  },
  optionsBtn: {
    minWidth: 30,
    minHeight: 40,
    color: theme.palette.textOnM.main,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      minWidth: 30,
    },
  },
  uploadBtnIcon: {
    marginRight: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 8,
    },
  },
  menu: {
    padding: '12px 8px',
  },
  menuItemIcon: {
    minWidth: 30,
    color: theme.palette.grey[800],
  },
  divider: {
    backgroundColor: theme.palette.grey[800],
    marginTop: '8px',
    marginBottom: '8px',
  },
});

export default Style;
