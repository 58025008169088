import { clientPrjApp } from '../config/FirebaseProject';

// Get table fetch source
const getDataSource = async (table) => {
  let lastUpdatedAtCache = 0;
  let lastUpdatedAtServer = 0;

  try {
    await clientPrjApp.dbPrjCache
      .collection(table)
      .orderBy('modified', 'desc')
      .limit(1)
      .get({ source: 'cache' })
      .then((rows) => {
        rows.forEach((item) => {
          lastUpdatedAtCache = item.data().modified;
        });
      });

    await clientPrjApp.dbPrjCache
      .collection(table)
      .orderBy('modified', 'desc')
      .limit(1)
      .get({ source: 'server' })
      .then((rows) => {
        rows.forEach((item) => {
          lastUpdatedAtServer = item.data().modified;
        });
      });

    if (lastUpdatedAtServer === 0 || lastUpdatedAtCache === 0) return 'server';
    return lastUpdatedAtServer > lastUpdatedAtCache ? 'server' : 'cache';
  } catch (e) {
    // ignore error
  }

  return 'server';
};

export default getDataSource;
