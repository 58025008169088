const styles = (theme) => ({
  chipSmall: {
    backgroundColor: 'white',
    border: '1px solid black',
    color: 'black',
    height: '24px',
    padding: '0 4px',
    fontSize: '11px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:focus': {
      backgroundColor: 'white',
    },
    [theme.breakpoints.down('md')]: {
      background: 'transparent',
      padding: '0',
      fontSize: '9px',
      height: '20px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  chipSmallLabel: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  chipSmallColumn: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  badgeSmall: {
    backgroundColor: '#00D1FF',
    color: 'black',
    fontSize: '10px',
    [theme.breakpoints.down('md')]: {
      fontSize: '8px',
      height: '16px',
    },
  },
  linkTool: {
    cursor: 'pointer',
    width: '16px',
    height: '16px',
    fill: theme.palette.grey[600],
    color: theme.palette.grey[600],
    '&:hover': {
      fill: theme.palette.grey[700],
      color: theme.palette.grey[700],
    },
  },
  linkToolButton: {
    '&:hover': {
      background: 'none',
    },
  },
  addShareTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '12px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      marginBottom: '32px',
    },
  },
  addShareClose: {
    color: 'rgba(0,0,0,0.5)',
    cursor: 'pointer',
  },

  addShareButton: {
    backgroundColor: '#18657D',
    borderRadius: '16px',
    overflow: 'hidden',
    padding: '34px',
    position: 'absolute',
    right: '0',
    top: '40px',
    transition: 'all 0.5s',
    '&:hover': {
      backgroundColor: '#18657D',
    },
    '&.active': {
      transform: 'rotate(360deg)',
    },
    [theme.breakpoints.down('md')]: {
      padding: '17px',
      borderRadius: '8px',
      backgroundColor: '#00BABA',
      top: '-85px',
      right: '20px',
      '&:hover': {
        backgroundColor: '#00BABA',
      },
    },
  },
  addIcon: {
    width: '50px',
    height: '50px',
    color: 'white',
  },
  shareContainer: {
    padding: '16px 20px 16px 16px',
    borderRadius: '0px',
    maxWidth: '800px',
    margin: 'auto',
    justifyContent: 'space-around',
    backgroundColor: '#FAFAFA',
    borderBottom: '1px solid rgba(239, 239, 239, 0.8)',
    alignItems: 'stretch',
  },
  lockIcon: {
    marginRight: 3,
    color: theme.palette.grey[600],
    height: '20px',
  },
  publicIcon: {
    color: theme.palette.grey[600],
    height: '20px',
  },
  sharedInfoLabel: {
    fontWeight: '400',
    fontSize: '10px',
    color: 'rgba(0,0,0,0.7)',
    marginRight: '8px',
    [theme.breakpoints.down('md')]: {
      fontSize: '9px',
    },
  },
  sharedInfoDot: {
    fontSize: '30px',
    fontWeight: '600',
    lineHeight: '0',
    margin: '0 2px',
  },
  sharedInfoValue: {
    fontWeight: '400',
    fontSize: '0.75rem',
    color: theme.palette.grey[600],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '9px',
    },
  },
  shareTitle: {
    fontWeight: '400',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    marginBottom: '8px',
  },
  shareToolsColumn: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  noLinksContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noLinksIcon: {
    color: '#CECECE',
    height: '87px',
    width: '54px',
  },
  noLinksTitle: {
    color: 'rgba(0, 0, 0, 0.2)',
    fontWeight: 'bold',
    fontSize: '24px',
  },
  noLinksDescription: {
    fontSize: '18px',
    color: 'rgba(0, 0, 0, 0.2)',
    maxWidth: '180px',
    textAlign: 'center',
  },
  shareColumns: {
    background: theme.palette.grey[500],
    borderRadius: '16px',
    minWidth: '44px',
    marginRight: '8px',
    padding: '0',
    fontSize: '16px',
    height: '24px',
    width: '46px',
    '& span': {
      color: 'white',
      display: 'flex',
      justifyContent: 'space-around',
      margin: '0 6px',
      fontWeight: 400,
      fontSize: '10px',
    },
    '& svg': {
      width: '18px',
      height: '18px',
      color: theme.palette.grey[50],
      opacity: '0.7',
    },
    '&.active': {
      background: '#e0e0e0',
    },
    '&:hover': {
      background: '#e0e0e0',
    },
  },
  shareMarkers: {
    background: theme.palette.grey[500],
    borderRadius: '16px',
    minWidth: '44px',
    padding: '0',
    fontSize: '16px',
    height: '24px',
    width: '46px',
    '& svg': {
      width: '18px',
      height: '18px',
      color: theme.palette.grey[50],
      marginRight: '2px',
      marginLeft: '-4px',
      opacity: '0.7',
    },
    '& span': {
      color: 'white',
      display: 'flex',
      justifyContent: 'space-around',
      margin: '0 10px',
      fontWeight: 400,
      fontSize: '10px',
    },
    '&.active': {
      background: '#e0e0e0',
    },
    '&:hover': {
      background: '#e0e0e0',
    },
  },
  mobileAddShare: {
    display: 'none',
    background: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
    maxWidth: '390px',
    margin: 'auto',
    position: 'fixed',
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '15px 30px',
    zIndex: '3',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      display: 'block',
    },
  },
  mobileAddShareTitle: {
    fontSize: '0.9rem',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '500',
    fontFamily: 'Roboto, sans-serif',
  },
});

export default styles;
