// Generate random string
const getRandString = (len) => {
  let returnValue = '';
  const base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  try {
    for (let i = 0; i < len; i += 1) {
      returnValue += base.charAt(Math.floor(Math.random() * base.length));
    }
  } catch (e) {
    // Something went wrong while getting values
  }

  return returnValue;
};

export default getRandString;
