import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getTableColumns } from '../../../services/Columns';
import { getTableDataOnlyName, saveTableData } from '../../../services/Tables';
import ColumnTypes from '../../../config/ColumnTypes';
import Validations from '../../../utils/Validations';
import formPopupStyle from '../../../theme/styles/components/FormPopup';

const useStyles = makeStyles(formPopupStyle);

// Create a new row
const AddRow = ({ tableId, collectionName, handleSuccess, handleDismiss }) => {
  const classes = useStyles();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [columnsList, setColumnsList] = useState([]);
  const [allColumnsList, setAllColumnsList] = useState([]);
  const [referenceData, setReferenceData] = useState({});

  const { handleSubmit, control } = useForm();

  const loadRefData = async (tableList) => {
    const tables = Object.keys(tableList);
    const refTableData = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const item of tables) {
      // eslint-disable-next-line no-await-in-loop
      const rows = await getTableDataOnlyName(item);
      refTableData[item] = rows;
    }
    setReferenceData(refTableData);
  };

  useEffect(() => {
    getTableColumns(tableId).then((resColumns) => {
      const columns = [];
      const allColumns = [];
      const refTableData = {};

      resColumns.forEach((obj) => {
        if (
          obj.name !== 'name' &&
          obj.name !== 'displayName' &&
          obj.refType !== ColumnTypes.LOOKUP.value
        ) {
          columns.push({
            id: obj.id,
            name: obj.name,
            label: obj.displayName,
            type: obj.type,
            refType: obj.refType,
            options: obj.staticOptions || [],
            refTable: obj.relation?.tableName || '',
          });

          if (
            obj.refType === ColumnTypes.REFERENCE.value ||
            obj.refType === ColumnTypes.MULTI_REFERENCE.value
          ) {
            refTableData[obj.relation.tableName] = [];
          }
        }

        allColumns.push({
          id: obj.id,
          type: obj.type,
          name: obj.name,
          refType: obj.refType,
          relation: obj.relation,
        });
      });

      loadRefData(refTableData);
      setColumnsList(columns);
      setAllColumnsList(allColumns);
    });
  }, []);

  const onSubmit = (data) => {
    setIsSubmitted(true);
    saveTableData(data, collectionName, allColumnsList)
      .then(() => {
        setIsSubmitted(false);
        handleSuccess();
      })
      .catch(() => {
        setIsSubmitted(false);
      });
  };

  const handleClose = () => {
    handleDismiss();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">Add New Row</DialogTitle>
      <DialogContent>
        <form id="add-new-row" onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="displayName"
              id="displayName"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoFocus
                  label="Display Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="text"
                />
              )}
            />
          </FormControl>

          {columnsList.map((col) => (
            <>
              {col.type === ColumnTypes.STRING.value && col.refType === 'NONE' && (
                <FormControl className={classes.formInput} key={`col-${col.name}`}>
                  <Controller
                    control={control}
                    name={col.name}
                    id={col.name}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField label={col.label} value={value} onChange={onChange} type="text" />
                    )}
                  />
                </FormControl>
              )}
              {col.type === ColumnTypes.IMAGE.value && col.refType === 'NONE' && (
                <FormControl className={classes.formInput} key={`col-${col.name}`}>
                  <Controller
                    control={control}
                    rules={Validations.OPTIONAL_URL}
                    name={col.name}
                    id={col.name}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField label={col.label} value={value} onChange={onChange} type="text" />
                    )}
                  />
                </FormControl>
              )}
              {col.type === ColumnTypes.NUMBER.value && col.refType === 'NONE' && (
                <FormControl className={classes.formInput} key={`col-${col.name}`}>
                  <Controller
                    control={control}
                    name={col.name}
                    id={col.name}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={col.label}
                        value={value}
                        onChange={onChange}
                        type="number"
                      />
                    )}
                  />
                </FormControl>
              )}
              {col.type === ColumnTypes.DATE.value && col.refType === 'NONE' && (
                <>
                  <InputLabel htmlFor={col.name}>{col.label}</InputLabel>
                  <FormControl className={classes.formInput} key={`col-${col.name}`}>
                    <Controller
                      control={control}
                      name={col.name}
                      id={col.name}
                      defaultValue={new Date()}
                      render={({ field: { onChange, value } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            clearable
                            autoOk
                            format="MM/dd/yyyy"
                            value={value}
                            onChange={onChange}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </FormControl>
                </>
              )}
              {col.type === ColumnTypes.SINGLE_SELECT.value && col.refType === 'NONE' && (
                <FormControl className={classes.formInput} key={`col-${col.name}`}>
                  <Controller
                    control={control}
                    name={col.name}
                    id={col.name}
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <>
                        <InputLabel htmlFor={col.name}>{col.label}</InputLabel>
                        <Select value={value} onChange={onChange}>
                          <MenuItem value="">Select</MenuItem>
                          {col.options.map((v) => (
                            <MenuItem value={v} key={`op-${Math.random()}`}>
                              {v}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                </FormControl>
              )}
              {col.refType === ColumnTypes.REFERENCE.value && (
                <FormControl className={classes.formInput} key={`col-${col.name}`}>
                  <Controller
                    control={control}
                    name={col.name}
                    id={col.name}
                    render={({ field: { onChange } }) => (
                      <>
                        <Autocomplete
                          options={referenceData[col.refTable] || []}
                          getOptionLabel={(option) => option.label}
                          renderInput={(opts) => <TextField {...opts} label={col.label} />}
                          onChange={(_, data) => onChange(data)}
                        />
                      </>
                    )}
                  />
                </FormControl>
              )}
              {col.refType === ColumnTypes.MULTI_REFERENCE.value && (
                <FormControl className={classes.formInput} key={`col-${col.name}`}>
                  <Controller
                    control={control}
                    name={col.name}
                    id={col.name}
                    render={({ field: { onChange } }) => (
                      <>
                        <Autocomplete
                          multiple
                          options={referenceData[col.refTable] || []}
                          getOptionLabel={(option) => option.label}
                          renderInput={(opts) => <TextField {...opts} label={col.label} />}
                          onChange={(_, data) => onChange(data)}
                        />
                      </>
                    )}
                  />
                </FormControl>
              )}
            </>
          ))}
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          form="add-new-row"
          variant="contained"
          color="primary"
          disabled={isSubmitted}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddRow.propTypes = {
  tableId: PropTypes.string.isRequired,
  collectionName: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default AddRow;
