import grey from '@material-ui/core/colors/grey';

// Style for collaborator section
const Style = () => ({
  root: {
    marginTop: 25,
    marginBottom: 10,
  },
  row: {
    backgroundColor: grey[100],
  },
  colAvtar: {
    width: '10%',
    borderBottom: 0,
    padding: 10,
  },
  colUser: {
    width: '50%',
    borderBottom: 0,
    padding: 10,
  },
  colRole: {
    width: '30%',
    borderBottom: 0,
    padding: 10,
  },
  colAction: {
    width: '10%',
    borderBottom: 0,
    padding: 10,
  },
  actionSel: {
    width: '100%',
  },
});

export default Style;
