// Style for table view options
const Style = (theme) => ({
  root: {
    marginTop: 0,
    background: theme.palette.primary.light,
    minHeight: '48px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  tabArea: {
    padding: '0px 24px 0 !important',
    '& .MuiTabs-root': {
      minHeight: 40,
    },
  },
  tabsIndicator: {
    display: 'none',
  },
  tabsFlexContainer: {
    alignItems: 'center',
  },
  tabsScroller: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  moreTabOption: {
    minWidth: '16px',
    minHeight: '16px',
    marginRight: '2px',
    paddingLeft: '5px',
    paddingRight: '5px',
    background: theme.palette.paper.white,
    borderRadius: '4px',
    width: '16px',
    height: '16px',
  },
  moreTabIcon: {
    fontSize: '16px',
    color: theme.palette.primary.light,
  },
  tabView: {
    marginRight: 16,
    paddingLeft: 16,
    paddingRight: 8,
    minWidth: 100,
    minHeight: 32,
    maxHeight: 32,
    borderRadius: '4px 4px 0 0',
    background: theme.palette.primary.dark,
    color: theme.palette.textOnM.main,
    fontWeight: 'medium',
    fontSize: '.8rem',
    '& span': {
      flexDirection: 'row',
    },
    '&.Mui-selected': {
      background: 'white',
      color: theme.palette.textOnL.main,
    },
  },

  menuTabIcon: {
    marginTop: '4px',
  },

  tabViewAll: {
    marginRight: 16,
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 100,
    minHeight: 32,
    maxHeight: 32,
    borderRadius: '4px 4px 0 0',
    background: theme.palette.primary.dark,
    color: theme.palette.textOnM.main,
    fontWeight: 'medium',
    fontSize: '.8rem',
    '& .MuiTab-wrapper': {
      flexDirection: 'row-reverse',
      '& span': {
        flexGrow: 2,
      },
    },
    '&.Mui-selected': {
      background: 'white',
      color: theme.palette.textOnL.main,
    },
  },

  menuItemIcon: {
    minWidth: 30,
  },
});

export default Style;
