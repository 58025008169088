/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import {
  Button,
  FormControl,
  TextField,
  LinearProgress,
  Box,
  Typography,
  Grid,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import formPopupStyle from '../../../theme/styles/components/FormPopup';
import Validations from '../../../utils/Validations';
import getRandString from '../../../utils/RandString';
import videoUploadUtil, { convertBytesToGBs } from '../../../utils/Video';
import { getTableColumns } from '../../../services/Columns';
import { getTableDetailsFromTableName, saveTableData } from '../../../services/Tables';
import { convertSecondsToTime, createDateString } from '../../../utils/DateTime';
import { getProjectConfig } from '../../../services/Projects';
import { createAWSOrgVidJobs } from '../../../services/Video';

const useStyles = makeStyles(formPopupStyle);

// Create a new video
const CreateVideo = ({ handleSuccess, handleDismiss, projectId }) => {
  const classes = useStyles();
  const defaultFileState = {
    videoBytes: 0,
    videoDuration: 0,
    videoHeight: 0,
    videoWidth: 0,
  };

  const [isUploadingVideo, setIsUploadingVideo] = useState(false);
  const [processingFile, setProcessingFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileInformation, setFileInformation] = useState(defaultFileState);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState('');

  const { handleSubmit, control } = useForm();

  const uploadProgressCallback = (uploadedBytes) => {
    const progress = (uploadedBytes * 100) / fileInformation.videoBytes;
    setUploadProgress(parseFloat(progress).toFixed(2));
  };

  const onSubmit = async ({ name }) => {
    const vidFolderName = `${name.split(' ').join('_')}_${getRandString(5)}`;
    const vidName = `${vidFolderName}.${selectedFile.name.split('.').reverse()[0]}`;

    const params = {
      fileName: vidName,
      fileType: selectedFile.type,
      projectId,
    };

    setIsUploadingVideo(true);

    // Uploading video
    const uploadStatus = await videoUploadUtil(selectedFile, params, uploadProgressCallback);

    if (uploadStatus) {
      const getTableId = await getTableDetailsFromTableName('videos');
      const resColumns = await getTableColumns(getTableId[0].id);
      const allColumns = [];

      resColumns.forEach((obj) => {
        allColumns.push({
          id: obj.id,
          type: obj.type,
          name: obj.name,
          refType: obj.refType,
          relation: obj.relation,
        });
      });
      const getProject = await getProjectConfig();

      const uploadRes = uploadStatus.completeUploadCall;
      const vidRandStr = getRandString(6);
      const videoPath = `${vidName.split('.').slice(0, -1).join('.')}/${vidRandStr}`;
      const saveData = {
        displayName: name,
        hlsLink: `${getProject.AWSDistributionDomain}/${videoPath}.m3u8`,
        image: `${getProject.AWSDistributionDomain}/${videoPath}_video_image.0000000_video_image.jpg`,
        mp4Link: `${getProject.AWSDistributionDomain}/${videoPath}.mp4`,
        convertedFileSize: fileInformation.videoBytes * 2,
        convertedFolderName: vidFolderName,
        creationDate: createDateString(new Date()),
        creationType: 'UPLOADED',
        sizeInGb: convertBytesToGBs(fileInformation.videoBytes),
        duration: convertSecondsToTime(fileInformation.videoDuration),
      };

      const hiddenFields = {
        videoStartTime: 0,
        videoEndTime: fileInformation.videoDuration,
        durationSeconds: fileInformation.videoDuration,
        inBucketBytes: fileInformation.videoBytes,
        originalFileName: uploadRes.Key.split('/').reverse()[0],
        activeContentType: 'HLS',
      };

      await saveTableData(saveData, 'videos', allColumns, hiddenFields);

      const jobPayload = {
        videoName: vidName,
        folder: vidName.split('.').slice(0, -1).join('.'),
        subFile: vidRandStr,
        videoHeight: fileInformation.videoHeight,
        videoWidth: fileInformation.videoWidth,
        projectId,
      };

      // Create M3U8 and MP4 Jobs
      await createAWSOrgVidJobs(jobPayload);

      handleSuccess();
    } else {
      // notify?
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setProcessingFile(true);
      setSelectedFile(file);

      const vid = document.createElement('video');
      const fileURL = window.URL.createObjectURL(file);
      vid.src = fileURL;

      // wait for duration to change from NaN to the actual duration
      vid.ondurationchange = () => {
        setFileInformation({
          videoDuration: Math.round(vid.duration),
          videoHeight: vid.videoHeight,
          videoWidth: vid.videoWidth,
          videoBytes: file.size,
        });
        setProcessingFile(false);
      };
    } else {
      setFileName('');
    }
  };

  const handleClose = () => {
    handleDismiss();
  };

  const handleUploadFile = () => {
    onSubmit({ name: fileName });
  };

  return (
    <Dialog
      open
      fullScreen
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        <Grid container className={classes.titleContainer}>
          <IconButton
            aria-label="close"
            size="small"
            className={classes.dialogCloseButton}
            onClick={handleClose}
          >
            <ChevronLeftRoundedIcon />
          </IconButton>
          <Typography variant="h1" color="textPrimary" className={classes.title}>
            Upload Video
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <form id="add-new-video" onSubmit={handleSubmit(onSubmit)} style={{ display: 'none' }}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="name"
              id="name"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoFocus
                  label="Video Title"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="text"
                />
              )}
            />
          </FormControl>
        </form>
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={classes.outlinedBox}
            >
              <CloudUploadOutlinedIcon className={classes.newVideoIcon} />
              <Typography className={classes.newVideoTitle}>Upload your video here!</Typography>
              <input
                accept="video/*"
                id="upload-button"
                multiple
                type="file"
                hidden
                onChange={handleFileChange}
              />
              {fileName === '' && (
                <label htmlFor="upload-button">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    size="large"
                    disabled={processingFile}
                  >
                    Select File
                  </Button>
                </label>
              )}
              {fileName !== '' && (
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  size="large"
                  disabled={isUploadingVideo}
                  onClick={handleUploadFile}
                >
                  Upload
                </Button>
              )}
              <Typography style={{ marginTop: 15 }}>{fileName}</Typography>
            </Box>
            <Typography style={{ maxWidth: 240, textAlign: 'center', marginTop: 20 }}>
              BEWARE!
              <br /> If you navigate away from this page before the video finishes uploading, the
              upload will be lost!
            </Typography>
          </Box>
        </Box>
        {isUploadingVideo && (
          <Box display="flex" alignItems="center" mt={3}>
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="h5" color="textSecondary">{`${uploadProgress}%`}</Typography>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

CreateVideo.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default CreateVideo;
