import { clientPrjApp } from '../config/FirebaseProject';

export const getPlaylistData = async () => {
  const rows = [];
  const tableName = 'playlists';

  const dataSnap = await clientPrjApp.dbPrj.collection(tableName).get();

  dataSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      id: doc.id,
    });
  });

  return rows;
};

export const getPlaylistItems = async (plId, markerId) => {
  const rows = [];
  const tableName = 'playlistItems';

  const query = clientPrjApp.dbPrj.collection(tableName).where('references.playlist', '==', plId);
  if (markerId) {
    query.where('references.marker', '==', markerId);
  }

  const dataSnap = await query.orderBy('position').get();

  dataSnap.forEach((doc) => {
    const tempData = doc.data();

    rows.push({
      ...tempData,
      id: doc.id,
    });
  });

  return rows;
};

export const arrangPlaylistItemWithDeletedPosition = async (plId, position) => {
  const tableName = 'playlistItems';

  const query = clientPrjApp.dbPrj.collection(tableName).where('references.playlist', '==', plId);

  const dataSnap = await query.orderBy('position').get();

  dataSnap.forEach(async (doc) => {
    const tempData = doc.data();
    if (tempData.position > position) {
      await doc.ref.update({ position: tempData.position - 1 });
    }
  });
  return true;
};

export const updatePositionWithNewPosition = async (updatedArr) => {
  const tableName = 'playlistItems';

  updatedArr.forEach(async (plItem, index) => {
    await clientPrjApp.dbPrj
      .collection(tableName)
      .doc(plItem.plItemId)
      .update({ position: index + 1 });
  });
  return true;
};
