import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Roles from '../../config/Roles';
import collaboratorStyle from '../../theme/styles/components/Collaborators';
import { changeCollaboratorAccess, getCollaboratorsInfo } from '../../services/Projects';
import getInitials from '../../utils/NameInitials';

const useStyles = makeStyles(collaboratorStyle);

// List project collaborators
const Collaborators = ({ projId }) => {
  const classes = useStyles();

  const [hasCollaborators, setHasCollaborators] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [collaborators, setCollaborators] = useState([]);

  useEffect(() => {
    setProcessing(true);
    setHasCollaborators(false);
    getCollaboratorsInfo(projId)
      .then(async (res) => {
        setCollaborators(res);
        setHasCollaborators(res.length !== 0);
        setProcessing(false);
      })
      .catch(() => {
        setProcessing(false);
        setHasCollaborators(false);
      });
  }, []);

  const changeAccess = (doc, value) => {
    changeCollaboratorAccess(projId, doc.id, value);
  };

  if (processing)
    return (
      <Box justifyContent="center" display="flex">
        <CircularProgress size={25} />
      </Box>
    );

  if (!hasCollaborators) return <></>;

  return (
    <Table aria-label="caption table" className={classes.root}>
      <TableBody>
        {collaborators.length > 0 &&
          collaborators.map((doc) => (
            <TableRow className={classes.row}>
              <TableCell className={classes.colAvtar}>
                <Avatar>{getInitials(doc.name)}</Avatar>
              </TableCell>
              <TableCell className={classes.colUser}>
                {doc.name}
                <br />
                {doc.email}
              </TableCell>
              <TableCell className={classes.colRole}>
                <FormControl className={classes.actionSel} size="small">
                  <Select
                    defaultValue={doc.role}
                    onChange={(e) => changeAccess(doc, e.target.value)}
                  >
                    <MenuItem value={Roles.OWNER.value}>{Roles.OWNER.text}</MenuItem>
                    <MenuItem value={Roles.EDITOR.value}>{Roles.EDITOR.text}</MenuItem>
                    <MenuItem value={Roles.READ_ONLY.value}>{Roles.READ_ONLY.text}</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell align="right" className={classes.colAction}>
                <DeleteIcon color="secondary" />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

Collaborators.propTypes = {
  projId: PropTypes.string.isRequired,
};

export default Collaborators;
