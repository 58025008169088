import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FilterListIcon from '@material-ui/icons/FilterList';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import SortIcon from '@material-ui/icons/Sort';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import HideFields from './views/toolbar/HideFields';
import Grouping from './views/toolbar/Grouping';
import Filters from './views/toolbar/Filters';
import Sorting from './views/toolbar/Sorting';
import toolbarStyle from '../../theme/styles/components/TableViewToolbar';

const useStyles = makeStyles(toolbarStyle);

const TableViewToolbar = ({
  handleHideCols,
  handleFilters,
  handleGrouping,
  handleSorting,
  handleSearch,
  handleRefresh,
  handleDownload,
  gridTextFilter,
  gridApi,
}) => {
  const classes = useStyles();
  const gridCols = gridApi?.columnApi?.getColumnState() || [];
  const numOfHiddenCols = gridCols.filter(
    (col) => col.hide === true && col.rowGroup === false
  ).length;
  const numOfFilters = Object.keys(gridApi?.api?.getFilterModel() || {}).length;
  const numOfGrouping = gridCols.filter((col) => col.rowGroup === true).length;
  const numOfSorts = gridCols.filter((col) => col.sort !== null).length;

  const [showHideColumns, setShowHideColumns] = useState(false);
  const [showGrouping, setShowGrouping] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showSorting, setShowSorting] = useState(false);

  const handleShowHideColumns = () => {
    setShowHideColumns(true);
  };
  const handleCloseHideColumns = () => {
    setShowHideColumns(false);
  };
  const handleCloseHideColumnsSuccess = (colId, value) => {
    handleHideCols(colId, value);
  };

  const handleShowGrouping = () => {
    setShowGrouping(true);
  };
  const handleCloseGrouping = () => {
    setShowGrouping(false);
  };
  const handleCloseGroupingSuccess = (colId) => {
    handleGrouping(colId);
  };

  const handleShowFilters = () => {
    setShowFilters(true);
  };
  const handleCloseFilters = () => {
    setShowFilters(false);
  };
  const handleCloseFiltersSuccess = (colId) => {
    handleFilters(colId);
  };

  const handleShowSorting = () => {
    setShowSorting(true);
  };
  const handleCloseSorting = () => {
    setShowSorting(false);
  };
  const handleCloseSortingSuccess = (colId) => {
    handleSorting(colId);
  };

  const handleSearchInputChange = (event) => {
    handleSearch(event.target.value);
  };
  const handleRefreshClick = () => {
    handleRefresh();
  };
  const handleDownloadClick = () => {
    handleDownload();
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={6} sm={6}>
          <Badge
            classes={{
              root: classes.buttonBadgeContainer,
              badge: classes.buttonBadge,
            }}
            badgeContent={numOfHiddenCols}
            color="primary"
          >
            <Button
              variant="contained"
              startIcon={<VisibilityOffIcon />}
              className={classes.hideFieldsBtn}
              onClick={handleShowHideColumns}
            >
              <Hidden smDown implementation="css">
                Hide
              </Hidden>
            </Button>
          </Badge>
          <Badge
            classes={{
              root: classes.buttonBadgeContainer,
              badge: classes.buttonBadge,
            }}
            badgeContent={numOfGrouping}
            color="primary"
          >
            <Button
              variant="contained"
              startIcon={<GroupWorkIcon />}
              className={classes.filtersBtn}
              onClick={handleShowGrouping}
              disabled={numOfGrouping === 0}
            >
              <Hidden smDown implementation="css">
                Group
              </Hidden>
            </Button>
          </Badge>
          <Badge
            classes={{
              root: classes.buttonBadgeContainer,
              badge: classes.buttonBadge,
            }}
            badgeContent={numOfFilters}
            color="primary"
          >
            <Button
              variant="contained"
              startIcon={<FilterListIcon />}
              className={classes.filtersBtn}
              onClick={handleShowFilters}
              disabled={numOfFilters === 0}
            >
              <Hidden smDown implementation="css">
                Filter
              </Hidden>
            </Button>
          </Badge>
          <Badge
            classes={{
              root: classes.buttonBadgeContainer,
              badge: classes.buttonBadge,
            }}
            badgeContent={numOfSorts}
            color="primary"
          >
            <Button
              variant="contained"
              startIcon={<SortIcon />}
              className={classes.filtersBtn}
              onClick={handleShowSorting}
              disabled={numOfSorts === 0}
            >
              <Hidden smDown implementation="css">
                Sort
              </Hidden>
            </Button>
          </Badge>
        </Grid>
        <Grid item xs={6} sm={6} className={classes.searchSection}>
          <TextField
            id="search-data"
            placeholder="Search"
            className={classes.searchInput}
            onChange={handleSearchInputChange}
            value={gridTextFilter}
            InputProps={{
              startAdornment: <SearchIcon className={classes.searchIcon} />,
              disableUnderline: true,
              className: classes.searchInputInput,
            }}
          />

          <Button
            variant="contained"
            className={classes.refreshBtn}
            onClick={handleRefreshClick}
            style={{ display: 'none' }}
          >
            <RefreshIcon />
          </Button>
          <Button variant="contained" className={classes.downloadBtn} onClick={handleDownloadClick}>
            <GetAppIcon />
            <Hidden smDown implementation="css">
              CSV
            </Hidden>
          </Button>
        </Grid>
      </Grid>

      {showHideColumns && (
        <HideFields
          handleSuccess={handleCloseHideColumnsSuccess}
          handleDismiss={handleCloseHideColumns}
          gridApi={gridApi}
        />
      )}
      {showGrouping && (
        <Grouping
          handleSuccess={handleCloseGroupingSuccess}
          handleDismiss={handleCloseGrouping}
          gridApi={gridApi}
        />
      )}
      {showFilters && (
        <Filters
          handleSuccess={handleCloseFiltersSuccess}
          handleDismiss={handleCloseFilters}
          gridApi={gridApi}
        />
      )}
      {showSorting && (
        <Sorting
          handleSuccess={handleCloseSortingSuccess}
          handleDismiss={handleCloseSorting}
          gridApi={gridApi}
        />
      )}
    </>
  );
};

TableViewToolbar.propTypes = {
  handleHideCols: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleGrouping: PropTypes.func.isRequired,
  handleSorting: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  gridTextFilter: PropTypes.string.isRequired,
  gridApi: PropTypes.any.isRequired,
};

export default TableViewToolbar;
