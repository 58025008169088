import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import getProjectAccess from '../../utils/ProjectAccess';
import { getLocalData } from '../../utils/LocalStore';
import AccessRoles from '../../config/Roles';
import UpdateProject from './UpdateProject';
import ShareProject from './ShareProject';
import projectStyle from '../../theme/styles/components/Project';
import { ReactComponent as GroupsIcon } from '../../assets/images/groups.svg';
import { ReactComponent as GearIcon } from '../../assets/images/gear.svg';

const useStyles = makeStyles(projectStyle);

// Project item section
const Project = ({ data, handleRefreshList }) => {
  const classes = useStyles();
  const history = useHistory();

  const [openSettingForm, setOpenSettingForm] = useState(false);
  const [openShareForm, setOpenShareForm] = useState(false);

  const currentUserAccess = getProjectAccess(data.accessDetails);

  const handleProjectSettings = () => {
    setOpenSettingForm(!openSettingForm);
  };

  const handleShareForm = () => {
    setOpenShareForm(!openShareForm);
  };

  const handleProjectOpen = () => {
    const lastLocation = getLocalData(`DD_${data.id}`, false, null);
    if (lastLocation) {
      history.push(lastLocation);
    } else {
      history.push(`/project/${data.id}`);
    }
  };

  return (
    <>
      <Card className={classes.projectItem}>
        <CardContent className={classes.cardNameReset}>
          <Grid container justify="space-between" onClick={handleProjectOpen}>
            <Grid item>
              <Typography className={classes.projectName}>{data.name}</Typography>
              <Typography className={classes.access}>Access: {currentUserAccess}</Typography>
            </Grid>
            <Grid item>
              <Chip className={classes.chip} style={{ backgroundColor: data.color }} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container className={classes.projectItemBottom} justify="flex-end">
            <Button
              onClick={handleShareForm}
              disabled={currentUserAccess !== AccessRoles.OWNER.value}
              className={classes.iconButton}
            >
              <GroupsIcon className={classes.groupIcon} alt="group icon" />
            </Button>
            <Button
              onClick={handleProjectSettings}
              disabled={currentUserAccess !== AccessRoles.OWNER.value}
              className={classes.iconButton}
            >
              <GearIcon className={classes.gearIcon} alt="gear icon" />
            </Button>
          </Grid>
        </CardActions>
      </Card>
      {openSettingForm && (
        <UpdateProject
          handleProjectSettings={handleProjectSettings}
          handleRefreshList={handleRefreshList}
          id={data.id}
          name={data.name}
          color={data.color}
          status={data.status}
        />
      )}

      {openShareForm && (
        <ShareProject handleShareForm={handleShareForm} projId={data.id} projName={data.name} />
      )}
    </>
  );
};

Project.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    status: PropTypes.string,
    accessDetails: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        role: PropTypes.string,
      })
    ),
    ownerName: PropTypes.string,
  }).isRequired,
  handleRefreshList: PropTypes.func.isRequired,
};

export default Project;
