import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import TimerIcon from '@material-ui/icons/Timer';
import { ReactComponent as InIcon } from '../../assets/images/in.svg';
import { ReactComponent as OutIcon } from '../../assets/images/out.svg';
import { ReactComponent as ListIcon } from '../../assets/images/list.svg';
import { ReactComponent as CutIcon } from '../../assets/images/cut.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/trash.svg';
import formPopupStyle from '../../theme/styles/components/FormPopup';
import { convertSecondsToTime } from '../../utils/DateTime';

const useStyles = makeStyles(formPopupStyle);

const PlaylistItems = ({ items }) => {
  const classes = useStyles();
  return (
    <>
      {items.length === 0 && (
        <Grid className={classes.noMarkersContainer}>
          <LocationOnIcon className={classes.noMarkersIcon} />
          <Typography className={classes.noMarkersTitle}>No Items Added</Typography>
          <Typography className={classes.noMarkersDescription}>
            New Items will be shown here
          </Typography>
        </Grid>
      )}
      {items.length > 0 && (
        <Grid className={classes.markersContainerTitle}>
          <Typography variant="body1">Items</Typography>
        </Grid>
      )}
      {items.map((row, i) => (
        <Grid key={row.id} container className={classes.markerContainer}>
          <Grid item className={classes.markerLeft}>
            <Chip className={classes.playlistChip} label={i + 1} />
            <Chip className={classes.playlistChipHover} label={<DragIndicatorIcon />} />
          </Grid>
          <Grid item className={classes.markerRight}>
            <Grid container>
              <Grid item xs={12} className={classes.markerInfoContainer}>
                <Typography className={classes.markerTitle}>{row.plItemName}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.markerTimeRow}>
                <Grid
                  container
                  justify="space-between"
                  align="center"
                  className={classes.markerDurationContainer}
                >
                  <InIcon className={classes.markerInIcon} />
                  <Typography className={classes.markerDurationValue}>
                    {convertSecondsToTime(row.startTimeSeconds)}
                  </Typography>
                  <ArrowRightAltIcon className={classes.timeArrowIcon} />
                  <Typography className={classes.markerDurationValue}>
                    {convertSecondsToTime(row.endTimeSeconds)}
                  </Typography>
                  <OutIcon className={classes.markerOutIcon} />
                </Grid>
                <Grid container className={classes.markerFullDurationContainer}>
                  <TimerIcon className={classes.clockIcon} />
                  <Typography variant="body1" className={classes.markerFullDurationValue}>
                    {row.totalDuration}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.markerToolsRow}>
                <Grid item className={classes.markerCategoryColumn}>
                  <Typography className={classes.markerCategory}>{row.videoDisplayName}</Typography>
                </Grid>
                <Grid item className={classes.markerToolsColumn}>
                  <Grid align="right">
                    {row.isVideoCreated && (
                      <IconButton size="small" className={classes.markerToolButton}>
                        <CloudDownload className={classes.markerTool} />
                      </IconButton>
                    )}
                    <IconButton size="small" className={classes.markerToolButton}>
                      <ListIcon className={classes.markerTool} />
                    </IconButton>
                    {!row.isVideoCreated && (
                      <IconButton size="small" className={classes.markerToolButton}>
                        <CutIcon className={classes.markerTool} />
                      </IconButton>
                    )}
                    <IconButton
                      size="small"
                      className={classes.markerToolButton}
                      style={{ marginRight: -8 }}
                    >
                      <TrashIcon className={classes.markerTool} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

PlaylistItems.propTypes = {
  items: PropTypes.arrayOf().isRequired,
};

export default PlaylistItems;
