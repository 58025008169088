import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

/* google font css @imports are in /fonts/fonts.css */
const customFonts = `"Zen Kaku Gothic New",Arial,sans-serif`;

// Extend default MuiTheme
const Default = createMuiTheme({
  typography: {
    fontFamily: customFonts,
  },
  breakpoints: {
    values: {
      xs: 0,
      verySmall: 394,
      noLogin: 510,
      sm: 600,
      lowMd: 650,
      video: 730,
      md: 960,
      addTool: 1120,
      col: 1000,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    background: {
      default: grey[100],
    },
    primary: {
      main: '#C62828',
      light: '#FF5F52',
      dark: '#8E0000',
      lighter: '#FEEBEE',
      100: '#FFCDD2',
    },
    textOnM: {
      main: '#fff',
    },
    textOnL: {
      main: '#263238',
    },
    grey: {
      50: '#fafafa',
      200: '#eee',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      800: '#424242',
      900: '#212121',
    },
    grey800: {
      main: '#424242',
      light: '#6D6D6D',
    },
    paper: {
      white: '#FFF',
      grey: {
        50: '#fafafa',
        100: '#F5F5F5',
        200: '#EEE',
      },
    },
    lightBlue: {
      main: '#0277BD',
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        color: 'black',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
    },
    MuiTypography: {
      body1: {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        color: '#263238',
      },
    },
  },
});

export default Default;
