import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Menubar from '../components/layout/Menubar';
import PageHeader from '../components/layout/PageHeader';
import wrapperStyle from '../theme/styles/layout/Main';

const useStyles = makeStyles(wrapperStyle);

// Layout for main pages
const Main = ({ pageHeading, children }) => {
  const classes = useStyles();

  useEffect(() => {
    window.document.title = `${process.env.REACT_APP_NAME} - Application`;
  }, []);

  return (
    <>
      <Menubar />
      <Container className={classes.wrapper}>
        <div className={classes.innerWrapper}>
          <PageHeader pageHeading={pageHeading} />

          {children}
        </div>
      </Container>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.element.isRequired,
  pageHeading: PropTypes.string.isRequired,
};

export default Main;
