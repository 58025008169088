import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { deleteColumn } from '../../../services/Columns';
import formPopupStyle from '../../../theme/styles/components/FormPopup';

const useStyles = makeStyles(formPopupStyle);

// Delete a column
const DeleteColumn = ({ rowData, handleSuccess, handleDismiss }) => {
  const classes = useStyles();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = () => {
    setIsSubmitted(true);

    const saveData = {
      colId: rowData.id,
    };

    deleteColumn(saveData)
      .then(() => {
        handleSuccess();
        setIsSubmitted(false);
      })
      .catch(() => {
        // something went wrong
        setIsSubmitted(false);
      });
  };

  const handleClose = () => {
    handleDismiss();
  };

  return (
    <ListItem button className={classes.rowItem}>
      <ListItemText primary={`Are you sure you want to delete ${rowData.displayName} field?`} />

      <Button variant="text" onClick={handleClose} className={classes.rowActionButton}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={isSubmitted}
        onClick={handleSubmit}
        className={classes.rowActionButton}
      >
        Delete
      </Button>
    </ListItem>
  );
};

DeleteColumn.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default DeleteColumn;
