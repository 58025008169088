import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

/* google font css @imports are in /fonts/fonts.css */
const customFonts = `Roboto,Arial,sans-serif`;

// Extend default MuiTheme
const Default = createMuiTheme({
  typography: {
    fontFamily: customFonts,
  },
  breakpoints: {
    values: {
      xs: 0,
      verySmall: 394,
      noLogin: 510,
      sm: 600,
      lowMd: 650,
      video: 730,
      md: 960,
      addTool: 1120,
      col: 1000,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    background: {
      default: grey[100],
    },
    primary: {
      main: '#0277BD',
      light: '#58A5F0',
      dark: '#004C8C',
      lighter: '#E1F5FE',
      100: '#B3E5FC',
    },
    textOnM: {
      main: '#fff',
    },
    textOnL: {
      main: '#263238',
    },
    grey: {
      50: '#fafafa',
      200: '#eee',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      800: '#424242',
      900: '#212121',
    },
    grey800: {
      main: '#424242',
      light: '#6D6D6D',
    },
    paper: {
      white: '#FFF',
      grey: {
        50: '#fafafa',
        100: '#F5F5F5',
        200: '#EEE',
      },
    },
    lightBlue: {
      main: '#0277BD',
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        color: 'black',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
      text: {
        minWidth: '100px',
      },
    },
    MuiTypography: {
      body1: {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 400,
        color: '#263238',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '24px 28px 16px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '8px 24px 16px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '12px 24px 28px',
      },
    },
  },
});

export default Default;
