import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { auth } from '../../config/Firebase';
import RoutePaths from '../../config/Routes';
import usermenuStyle from '../../theme/styles/components/UserMenu';

const useStyles = makeStyles(usermenuStyle);

// User menu options
const UserMenu = (props) => {
  const { showUsername, extraClasses } = props;
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [username, setUsername] = useState('Guest');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePageChange = (page) => {
    history.push(page);
    handleClose();
  };

  useEffect(() => {
    setUsername(auth.currentUser.displayName);
  }, []);

  return (
    <>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        className={`${classes.userMenu} ${extraClasses.userMenu}`}
      >
        <AccountCircle className={extraClasses.userIcon} /> {showUsername && username}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.userOptions}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handlePageChange(RoutePaths.PROJECTS)}>Projects</MenuItem>
        <MenuItem onClick={() => handlePageChange(RoutePaths.ACCOUNT)}>My Account</MenuItem>
        <MenuItem onClick={() => handlePageChange(RoutePaths.LOGOUT)}>Logout</MenuItem>
      </Menu>
    </>
  );
};

UserMenu.propTypes = {
  showUsername: PropTypes.bool,
  extraClasses: PropTypes.object,
};

UserMenu.defaultProps = {
  showUsername: false,
  extraClasses: {},
};

export default UserMenu;
