// Style for popup
const Style = (theme) => ({
  formText: {
    marginBottom: 20,
  },
  formInput: {
    marginBottom: 15,
    width: '100%',
  },
  formInputSmall: {
    marginBottom: 8,
    marginTop: 8,
    width: '100%',
  },
  rowItem: {
    padding: '3px 28px',
    height: '48px',
  },
  rowActionContainer: {
    right: 36,
    '& svg': {
      color: theme.palette.grey[400],
    },
    '&:hover svg': {
      color: theme.palette.grey[600],
    },
  },
  rowActionButton: {
    padding: 0,
    marginLeft: 0,
  },
  tabsContainer: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.noLogin)]: {
      display: 'flex',
    },
  },
  tabs: {
    overflow: 'visible',
    maxWidth: '800px',
    margin: 'auto',
    display: 'block',
  },
  tabsFixed: {
    overflow: 'visible !important',
    width: 'auto',
  },
  tab: {
    fontFamily: 'Roboto, sans-serif',
    textTransform: 'none',
    color: theme.palette.grey[900],
    minWidth: '0',
    flexGrow: '0',
    flexBasis: 'auto',
    minHeight: '20px',
    paddingBottom: '4px',
    '&.Mui-selected': {
      color: theme.palette.grey[900],
      borderBottom: `1px solid ${theme.palette.grey[900]}`,
    },
  },
  closeButton: {
    float: 'right',
    cursor: 'pointer',
    margin: '-5px 0 0 0',
    color: theme.palette.grey[700],
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  viewToolCloseButton: {
    float: 'right',
    color: theme.palette.grey[700],
  },

  dialogCloseButton: {
    color: theme.palette.textOnM.main,
  },

  headerButtonLabel: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  infoButton: {
    background: theme.palette.primary.lighter,
    borderRadius: '4px',
    fontFamily: "'Roboto', sans-serif",
    minWidth: '56px',
    height: '27px',
    marginRight: '24px',
    justifyContent: 'space-around',
    padding: '0 8px',
    fontSize: '1rem',
    '& svg': {
      width: '22px',
      height: '22px',
      color: theme.palette.primary.main,
    },
    '&.active': {
      background: '#FFF',
    },
    '&:hover': {
      background: '#FFF',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      minWidth: '30px',
      marginRight: '12px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.noLogin)]: {
      display: 'none',
    },
  },
  markersButton: {
    background: theme.palette.primary.lighter,
    fontFamily: "'Roboto', sans-serif",
    borderRadius: '4px',
    minWidth: '56px',
    height: '27px',
    marginRight: '24px',
    justifyContent: 'space-around',
    padding: '0 8px',
    fontSize: '1rem',
    '& svg': {
      width: '22px',
      height: '22px',
      marginRight: '2px',
      marginLeft: '-4px',
      color: theme.palette.primary.main,
    },
    '& span': {},
    '&.active': {
      background: '#FFF',
    },
    '&:hover': {
      background: '#FFF',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      minWidth: '30px',
      marginRight: '12px',
      '& svg': {
        marginRight: '2px',
        marginLeft: '-4px',
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.noLogin)]: {
      display: 'none',
    },
  },
  shareButton: {
    background: theme.palette.primary.lighter,
    borderRadius: '4px',
    fontFamily: "'Roboto', sans-serif",
    minWidth: '56px',
    height: '27px',
    marginRight: '24px',
    justifyContent: 'space-around',
    padding: '0 8px',
    fontSize: '1rem',
    '& svg': {
      height: '16px',
      width: '16px',
      marginRight: '4px',
      marginLeft: '0px',
      color: theme.palette.primary.main,
    },
    '& span': {},
    '&.active': {
      background: '#FFF',
    },
    '&:hover': {
      background: '#FFF',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      minWidth: '30px',
      marginRight: '12px',
      '& svg': {
        marginRight: '4px',
        marginLeft: '0px',
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.noLogin)]: {
      display: 'none',
    },
  },
  mainSection: {
    flexWrap: 'nowrap',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  leftColumn: {
    minWidth: '680px',
    position: 'relative',
    height: '100%',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      minWidth: 0,
    },
  },
  tabContentContainer: {
    maxWidth: '400px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    marginRight: '8px',

    height: 'calc(100vh - 47px)',
    [theme.breakpoints.down('md')]: {
      width: '90vw',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '100%',
      height: 'auto',
      minHeight: 'calc(100vh - 53px - (100vw * 0.5625))',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
  },
  tabContentHidden: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  videoPlayer: {
    position: 'relative',
    width: 'min(100%, (100vh - 47px) * 1.7777778)',
    paddingTop: '12px',
    paddingBottom: '12px',
    margin: 'auto',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '&.with-create': {
      width: 'min(100%, (100vh - 177px) * 1.7777778)',
      height: 'calc(100% - 152px)',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0',
      width: 'calc(100% - 40px )',
      marginTop: '10px',
      marginBottom: '10px',
      '&.with-create': {
        width: 'calc(100% - 40px )',
        height: '100%',
        marginBottom: '10px',
      },
    },
  },
  markerBtn: {
    marginLeft: 10,
    marginRight: 10,
  },
  container: {
    width: '95vw',
    maxWidth: '1200px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  titleContainer: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    height: '100%',
  },
  title: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: '1.1rem',
    fontWeight: '500',
    marginBottom: '0',
    marginTop: '0',
    marginLeft: '24px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.textOnM.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
      maxWidth: '80%',
      marginLeft: '12px',
    },
  },
  cellReset: {
    border: 0,
    minWidth: '200px',
    maxWidth: '600px',
    wordBreak: 'break-word',
    padding: '0',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    [theme.breakpoints.down('md')]: {
      minWidth: '100px',
      wordBreak: 'break-word',
    },
  },
  cellLabel: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.paper.grey[50],
    padding: '14px 12px 0',
  },
  table: {
    width: '100%',
    maxWidth: '800px',
    borderCollapse: 'separate',
    margin: '28px 30px',
    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
  },
  tableBodyNoHeader: {
    '& tr:nth-child(even) td': {
      padding: '2px 40px 8px 14px',
      backgroundColor: theme.palette.paper.grey[50],
      borderBottom: `2px solid ${theme.palette.paper.grey[100]}`,
    },
    '& tr:nth-child(odd)': {},
  },

  DialogContent: {
    padding: '0',
  },
  videoDialogContent: {
    padding: '0px',
  },
  DialogActions: {
    padding: '12px 24px',
  },

  videoDialogTitle: {
    paddingBottom: '8px',
    paddingTop: '5px',
    backgroundColor: theme.palette.primary.main,
  },
  dialogTitle: {
    paddingBottom: '5px',
    paddingTop: '5px',
    backgroundColor: theme.palette.primary.main,
  },
  textContainer: {
    maxWidth: '50%',
    flexBasis: '50%',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  text: {
    width: 'calc(100% - 10px)',
    height: '42px',
    background: theme.palette.paper.grey[200],
    border: `1px solid ${theme.palette.grey['400']}`,
    borderRadius: '4px',
    justifyContent: 'center',
    '& fieldset': {
      border: 'none',
    },
    '& input': {
      fontSize: '0.75rem',
      fontFamily: 'Roboto, sans-serif',
      color: theme.palette.grey['600'],
    },
    '& label': {
      transform: 'translate(14px, 14px) scale(1) !important',
      fontSize: '0.75rem',
      fontWeight: '500',
      fontFamily: 'Roboto, sans-serif',
      color: theme.palette.grey['400'],
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -5px) scale(0.65) !important',
        color: theme.palette.grey['400'],
        background: theme.palette.paper.grey[50],
      },
    },
    '&>div': {
      height: '30px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      width: '100%',
      marginBottom: '14px',
    },
  },
  addMarkerTimeRow: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      flexWrap: 'wrap',
    },
  },
  addMarkerContainer: {
    maxHeight: '190px',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexWrap: 'nowrap',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 15%)',
    background: theme.palette.paper.grey[50],
    borderRadius: '6px',
    padding: '8px 20px 10px 20px',
    maxWidth: 'min( 700px, calc(100% - 20px))',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      display: 'none',
      background: theme.palette.paper.grey[300],
      padding: '10px 20px 25px 20px',
      maxHeight: 'unset',
      border: '0',
      boxShadow: 'none',
      maxWidth: '390px',
      '&.active': {
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 100%)',
        boxShadow: '0px 1px 8px rgb(0 0 0 / 25%)',
        zIndex: '2',
      },
    },
  },
  addMarkerTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    marginBottom: '12px',
  },
  addMarkerClose: {
    color: 'rgba(0,0,0,0.5)',
    cursor: 'pointer',
  },
  editorContainer: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '92px',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      height: 'auto',
    },
  },
  addMarkerTitleContainer: {},
  durationContainer: {
    width: 'auto',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
      justifyContent: 'center',
    },
  },
  durationLabel: {
    fontWeight: '500',
    fontSize: '0.6rem',
    fontFamily: 'Roboto, sans-serif',
    textAlign: 'center',
  },
  durationValue: {
    lineHeight: '12px',
    textTransform: 'uppercase',
    color: theme.palette.grey[800],
    fontWeight: '600',
    fontSize: '0.9rem',
    marginLeft: '0',
    fontFamily: 'Roboto, sans-serif',
    marginTop: '3px',
  },
  addDurationTotalColumn: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingLeft: '20px',
    alignItems: 'flex-end',
    paddingBottom: '4px',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      padding: '0 0 0 10px',
      justifyContent: 'flex-start',
    },
  },
  addTrackColumn: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingLeft: '20px',
    alignItems: 'center',
    paddingBottom: '4px',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '0',
      paddingRight: '10px',
    },
  },
  addTrackLabel: {
    marginRight: '0',
    '& .MuiFormControlLabel-label': {
      fontSize: '1rem',
      color: theme.palette.grey[800],
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
      marginLeft: '0',
      marginRight: '0',
      justifyContent: 'center',
    },
  },
  addTrackCheckbox: {
    color: theme.palette.primary.main,
    padding: '0',
    paddingRight: '4px',
    '& svg': {
      fontSize: '1.5rem',
    },
  },
  addTextColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center',
  },
  addArrowColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    alignItems: 'center',
    paddingBottom: '12px',
  },
  timeIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inIconContainer: {
    background: theme.palette.grey[300],
    borderRadius: '4px 0px 0px 4px',
    minWidth: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '6px',
    cursor: 'pointer',
    height: '44px',
    marginRight: '6px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      background: theme.palette.grey[400],
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      height: '42px',
      minWidth: '29px',
      background: theme.palette.grey[300],
      justifyContent: 'center',
      paddingRight: '0',
    },
  },
  mobileTimeCodeColumn: {
    display: 'none',
    marginBottom: '16px',
    '& $timeArrowIcon': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      display: 'flex',
    },
  },
  timeCodeColumn: {
    maxWidth: '350px',
    '& $timeArrowIcon': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      display: 'none',
    },
  },
  timeArrowsContainer: {
    width: '17px',
    height: '44px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& svg': {
      background: theme.palette.grey[300],
      width: '20px',
      height: '20px',
      cursor: 'pointer',
      boxShadow:
        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
      '&:hover': {
        background: theme.palette.grey[400],
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      width: '18px',
      height: '100%',
      '& svg': {
        width: '18px',
        height: '20px',
      },
    },
  },
  timeArrowIcon: {
    color: theme.palette.grey[500],
    marginBottom: '0',
    marginLeft: '-2px',
    marginRight: '-2px',
    fontSize: '1rem',
  },
  outIconContainer: {
    background: theme.palette.grey[300],
    borderRadius: '0px 4px 4px 0px',
    minWidth: '24px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '6px',
    cursor: 'pointer',
    height: '44px',
    marginLeft: '8px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      background: theme.palette.grey[400],
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      height: '42px',
      minWidth: '29px',
      justifyContent: 'center',
      paddingLeft: '0',
    },
  },
  timeInput: {
    background: theme.palette.paper.grey[200],
    borderRadius: '40px',
    width: '100px',
    border: `1px solid ${theme.palette.grey[600]}`,
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& input': {
      color: theme.palette.grey[900],
      padding: '6px',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '500',
      fontSize: '0.8rem',
    },
    '& fieldset': {
      border: '0',
    },
    '& label': {
      background: theme.palette.grey[50],
      fontSize: '0.85rem',
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '500',
      paddingLeft: '2px',
      paddingRight: '2px',
      transform: 'translate(6px, -5px) scale(0.75)',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      background: 'transparent',
      '& label': {
        background: theme.palette.grey[50],
      },
    },
  },
  startInput: {
    borderRadius: '0 4px 4px 0',
    marginLeft: '8px',
    '& input': {
      textAlign: 'center',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      height: '42px',
      width: '80px',
      '& input': {
        textAlign: 'center',
      },
    },
  },
  endInput: {
    borderRadius: '4px 0px 0px 4px',
    marginRight: '6px',
    '& input': {
      textAlign: 'center',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      height: '42px',
      width: '80px',
      '& input': {
        textAlign: 'center',
      },
    },
  },
  durationEditContainer: {
    width: 'auto',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '380px',
  },
  outline: {
    borderColor: 'black',
  },
  markerTool: {
    fill: theme.palette.grey[300],
    cursor: 'pointer',
    width: '16px',
    height: '16px',
    transition: 'fill 0.5s',
  },
  markerToolButton: {
    '&:not(:last-child)': {
      marginRight: '4px',
    },
    '&:hover': {
      background: 'none',
    },
  },
  markerContainer: {
    padding: '9px 20px',
    maxWidth: '800px',
    margin: 'auto',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.grey[50],
    borderBottom: '1px solid rgba(239, 239, 239, 0.8)',
    transition: 'background 0.5s',
    cursor: 'pointer',
    flexWrap: 'nowrap',
    marginBottom: '2px',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      '& $markerTool': {
        fill: theme.palette.grey800.main,
      },
      '& $playlistChip': {
        display: 'none',
      },
      '& $playlistChipHover': {
        display: 'block',
      },
    },
  },
  markerTimeRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    maxHeight: '30px',
  },
  markerToolsRow: {
    flexWrap: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '6px',
  },
  markerCategory: {
    fontSize: '0.75rem',
  },
  markerFullDurationContainer: {
    maxWidth: '78px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'rgba(9, 0, 255, 0.64)',
    '& svg': {
      fontSize: '0.9rem',
      color: theme.palette.grey800.light,
    },
  },
  markerFullDurationValue: {
    fontSize: '0.75rem',
    marginLeft: '4px',
    fontFamily: 'Roboto, sans-serif',
  },
  markerRight: {
    paddingLeft: '20px',
    width: '100%',
  },
  markerInfoContainer: {
    paddingBottom: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  markerTitle: {
    fontSize: '0.9rem',
    fontFamily: 'Roboto, sans-serif',
  },
  markerDurationContainer: {
    maxWidth: '140px',
    alignItems: 'center',
  },
  markerDurationLabel: {
    color: 'rgba(0,0,0,0.4)',
    fontWeight: 'bold',
  },
  markerDurationValue: {
    fontSize: '0.75rem',
    fontFamily: 'Roboto, sans-serif',
    color: theme.palette.textOnL.main,
    '&:first-of-type': {},
    '&:last-of-type': {},
    '& span': {
      paddingLeft: '4px',
      paddingRight: '4px',
    },
  },
  markerInIcon: {
    height: '13px',
    '& path': {
      stroke: theme.palette.grey[500],
      '&:first-child': {
        fill: theme.palette.grey[500],
      },
    },
  },
  markerOutIcon: {
    height: '13px',
    '& path': {
      stroke: theme.palette.grey[500],
      '&:first-child': {
        fill: theme.palette.grey[500],
      },
    },
  },
  markerDurationValueLabel: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  markerDurationColumn: {
    height: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
  markerToolsColumn: {
    display: 'block',
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'opacity 0.5s',
    minWidth: '150px',
  },
  categoryChip: {
    background: 'white',
    border: '1px solid black',
    height: '26px',
    [theme.breakpoints.down('md')]: {
      background: 'transparent',
      padding: '0',
      fontSize: '0.55rem',
      height: '18px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  categoryChipLabel: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  trackCheckbox: {
    '&.Mui-disabled': {
      color: 'rgba(0,0,0,0.7)',
    },
    [theme.breakpoints.down('md')]: {
      '&.Mui-disabled': {
        padding: '0 2px 0 0',
      },
    },
  },
  trackCheckboxRoot: {
    [theme.breakpoints.down('md')]: {
      marginRight: '8px',
    },
  },
  trackCheckboxLabel: {
    '&.Mui-disabled': {
      color: 'black',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.55rem',
    },
  },
  trackColumn: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  trackContainer: {
    paddingLeft: '20px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '10px',
    },
  },
  markerChip: {
    borderRadius: '100%',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: 'white',
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.grey[500],
    fontFamily: 'Roboto, sans-serif',
    flexShrink: '0',
    paddingTop: '3px',
    '& span': {
      padding: '0',
      color: theme.palette.textOnL,
    },
  },
  playlistChip: {
    borderRadius: '100%',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: 'white',
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.grey[500],
    fontFamily: 'Roboto, sans-serif',
    flexShrink: '0',
    paddingTop: '3px',
    '& span': {
      padding: '0',
      color: theme.palette.textOnL,
    },
  },
  playlistChipHover: {
    display: 'none',
    borderRadius: '100%',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: 'white',
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.grey[500],
    fontFamily: 'Roboto, sans-serif',
    flexShrink: '0',
    '& span': {
      padding: '0',
      color: theme.palette.textOnL,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  },
  addIcon: {
    width: '50px',
    height: '50px',
    color: 'white',
  },
  submitButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      alignItems: 'center',
      marginTop: '20px',
    },
  },
  submitAddButton: {
    color: 'white',
    background: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: '4px',
    float: 'right',
    minWidth: '140px',
    paddingTop: '8px',
    paddingBottom: '8px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.9rem',
    '&:hover': {
      background: theme.palette.primary.light,
    },
    '&:focus': {
      background: theme.palette.primary.light,
    },
    '& span': {
      textTransform: 'none',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      minWidth: '140px',
      marginLeft: '20px',
    },
  },
  closeAddButton: {
    display: 'none',
    paddingTop: '3px',
    borderRadius: '4px',
    paddingBottom: '3px',
    background: 'transparent',
    fontFamily: 'Roboto, sans-serif',
    '& span': {
      textTransform: 'none',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      display: 'inline-block',
    },
  },
  userMenu: {
    padding: '0',
  },
  userIcon: {
    color: 'white',
    height: '28px',
    width: '28px',
  },
  noMarkersContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noMarkersIcon: {
    color: theme.palette.grey[500],
    height: '87px',
    width: '54px',
  },
  noMarkersTitle: {
    color: theme.palette.grey[500],
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  noMarkersDescription: {
    fontSize: '1.15rem',
    color: theme.palette.grey[500],
    maxWidth: '180px',
    textAlign: 'center',
  },
  markersContainerTitle: {
    marginTop: '16px',
    marginBottom: '8px',
    [theme.breakpoints.down(theme.breakpoints.values.noLogin)]: {
      display: 'none',
    },
  },
  mobileAddMarker: {
    display: 'none',
    background: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
    maxWidth: '390px',
    margin: 'auto',
    position: 'fixed',
    bottom: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '15px 30px',
    zIndex: '3',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      display: 'block',
    },
  },
  mobileAddMarkerTitle: {
    fontSize: '0.9rem',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: '500',
    fontFamily: 'Roboto, sans-serif',
  },
  mobileOnly: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  desktopOnly: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  addShareContainer: {
    padding: '10px 20px 15px 30px',
    maxWidth: 'min( 800px, calc(100% - 20px)  )',
    background: 'white',
    boxShadow: '0px 3px 10px rgb(0 0 0 / 25%)',
    borderRadius: '10px',
    maxHeight: '190px',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      display: 'none',
      padding: '25px 20px 25px 20px',
      maxHeight: 'unset',
      '&.active': {
        display: 'block',
        position: 'absolute',
        bottom: '0',
        transform: 'translate(-50%, calc(100% + 10px))',
        left: '50%',
        zIndex: '2',
      },
    },
  },
  addShareInputsColumn: {
    justifyContent: 'space-between',
    height: '100%',
  },
  formControl: {
    maxWidth: 'max(180px, calc(100% - 5px))',
    width: '100%',
    background: theme.palette.paper.grey[200],
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '6px',
    height: '44px',
    justifyContent: 'center',
    '& label': {
      display: 'none',
    },
    '& fieldset': {
      display: 'none',
    },
    '&>div>div': {
      paddingTop: '5px',
      paddingBottom: '5px',
      fontSize: '0.75rem',
      fontWeight: 'bold',
      color: theme.palette.grey['600'],
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      maxWidth: 'calc(100% - 10px)',
    },
  },
  password: {
    maxWidth: 'max(180px, calc(100% - 5px))',
    width: '100%',
    background: theme.palette.paper.grey[200],
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '6px',
    height: '44px',
    '& fieldset': {
      border: 'none',
    },
    '& input': {
      fontSize: '0.75rem',
      color: theme.palette.grey['600'],
    },
    '& label': {
      transform: 'translate(14px, 14px) scale(1) !important',
      fontSize: '0.75rem',
      fontWeight: 'bold',
      color: theme.palette.grey['600'],
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.65) !important',
        color: theme.palette.grey['600'],
        background: theme.palette.paper.grey[50],
      },
    },
    '&>div': {
      height: '44px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      maxWidth: 'calc(100% - 10px)',
      marginBottom: '14px',
    },
  },
  addShareBadgesColumn: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    height: '100%',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      flexDirection: 'row',
    },
  },
  chip: {
    backgroundColor: 'rgba(104, 104, 104, 0.3)',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.2)',
    color: 'black',
    height: '40px',
    padding: '0 8px',
    borderRadius: '80px',
    position: 'relative',
    width: '150px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'rgba(104, 104, 104, 0.3)',
    },
    '&:focus': {
      backgroundColor: 'rgba(104, 104, 104, 0.3)',
    },
  },
  shareText: {
    width: '100%',
    background: theme.palette.paper.grey[200],
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '6px',
    height: '44px',
    '& fieldset': {
      border: 'none',
    },
    '& input': {
      fontSize: '0.75rem',
    },
    '& label': {
      transform: 'translate(14px, 14px) scale(1) !important',
      fontSize: '0.75rem',
      fontWeight: 'bold',
      color: theme.palette.grey['600'],
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.65) !important',
        color: theme.palette.grey['600'],
        background: theme.palette.paper.grey[50],
      },
    },
    '&>div': {
      height: '44px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      width: '100%',
      marginBottom: '14px',
    },
  },
  addShareColumns: {
    background: theme.palette.grey[300],
    borderRadius: '4px',
    minWidth: '44px',
    marginBottom: '10px',
    padding: '0',
    fontSize: '1rem',
    height: '44px',
    width: '70px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    '& span': {
      color: theme.palette.primary.light,
      display: 'flex',
      justifyContent: 'space-around',
      margin: '0 10px',
      fontWeight: 'bold',
    },
    '& svg': {
      width: '22px',
      height: '22px',
      color: theme.palette.primary.light,
    },
    '&.active': {
      background: theme.palette.grey[400],
    },
    '&:hover': {
      background: theme.palette.grey[400],
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      width: 'calc(50% - 10px)',
      marginBottom: '20px',
    },
  },
  addShareMarkers: {
    background: theme.palette.grey[300],
    borderRadius: '4px',
    minWidth: '44px',
    padding: '0',
    fontSize: '1rem',
    height: '44px',
    width: '70px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    '& svg': {
      width: '22px',
      height: '22px',
      color: theme.palette.primary.light,
      marginRight: '2px',
      marginLeft: '-4px',
    },
    '& span': {
      color: theme.palette.primary.light,
      display: 'flex',
      justifyContent: 'space-around',
      margin: '0 10px',
      fontWeight: 'bold',
    },
    '&.active': {
      background: theme.palette.grey[400],
    },
    '&:hover': {
      background: theme.palette.grey[400],
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      width: 'calc(50% - 10px)',
      marginBottom: '20px',
    },
  },
  shareMobileOnly: {
    display: 'none !important',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      display: 'inline !important',
    },
  },
  addShareEditorContainer: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '95px',
    padding: '0',
    '& >div': {
      height: '100%',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      flexWrap: 'wrap',
      height: 'auto',
    },
  },
  addShareButtonsColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  addShareCol1: {
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  addShareCol2: {
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  shareCloseAddButton: {
    paddingTop: '3px',
    borderRadius: '4px',
    paddingBottom: '3px',
    display: 'none',
    background: 'transparent',
    height: '44px',
    '& span': {
      textTransform: 'none',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      display: 'block',
    },
  },
  shareSubmitAddButton: {
    color: 'white',
    background: theme.palette.primary.main,
    filter: 'drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.25))',
    fontWeight: 'bold',
    borderRadius: '4px',
    float: 'right',
    minWidth: '80px',
    paddingTop: '3px',
    paddingBottom: '3px',
    marginLeft: '20px',
    height: '44px',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '&:focus': {
      background: theme.palette.primary.dark,
    },
    '& span': {
      textTransform: 'none',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down(theme.breakpoints.values.video)]: {
      minWidth: '60px',
    },
  },
  showLabel: {
    '& legend': {
      visibility: 'visible',
    },
  },
  withIcon: {
    '& svg': {
      color: theme.palette.grey[600],
      marginRight: '24px',
    },
  },
  centeredSelect: {
    display: 'flex',
    alignItems: 'center',
  },
  newVideoIcon: {
    color: theme.palette.grey[700],
    fontSize: 100,
  },
  newVideoTitle: {
    color: theme.palette.textOnL.main,
    fontSize: 24,
    marginTop: 10,
    marginBottom: 32,
    fontWeight: 500,
  },
  outlinedBox: {
    border: `4px solid ${theme.palette.primary.light}`,
    padding: '32px 64px',
  },
});

export default Style;
