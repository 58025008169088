import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import Profile from '../../components/account/Profile';
import ChangePassword from '../../components/account/ChangePassword';
import Billing from '../../components/account/Billing';
import CloseAccount from '../../components/account/CloseAccount';
import Main from '../../layout/Main';
import pageStyle from '../../theme/styles/pages/Account';

const useStyles = makeStyles(pageStyle);

const Account = () => {
  const classes = useStyles();
  const pageHeading = 'My Account';
  const tabProfile = 'profile';
  const tabBilling = 'billing';
  const tabAccount = 'account';

  const [value, setValue] = useState(tabProfile);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Main pageHeading={pageHeading}>
      <>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              className={classes.tabs}
            >
              <Tab label="Profile" value={tabProfile} />
              <Tab label="Billing" value={tabBilling} />
              <Tab label="Account" value={tabAccount} />
            </Tabs>
          </Grid>
        </Grid>

        {value === tabProfile && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Profile />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ChangePassword />
            </Grid>
          </Grid>
        )}

        {value === tabBilling && (
          <Grid container>
            <Grid item xs={12}>
              <Billing />
            </Grid>
          </Grid>
        )}

        {value === tabAccount && (
          <Grid container>
            <Grid item xs={12}>
              <CloseAccount />
            </Grid>
          </Grid>
        )}
      </>
    </Main>
  );
};

export default Account;
