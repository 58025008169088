import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import TimerIcon from '@material-ui/icons/Timer';
import { ReactComponent as InIcon } from '../../../assets/images/in.svg';
import { ReactComponent as OutIcon } from '../../../assets/images/out.svg';
import { getVideoMarkers } from '../../../services/Markers';
import { convertSecondsToTime, createDateString } from '../../../utils/DateTime';
import formPopupStyle from '../../../theme/styles/components/FormPopup';
import ConfirmDialog from '../../ConfirmDialog';
import { createAWSTrimVidJobs } from '../../../services/Video';
import RandString from '../../../utils/RandString';
import PlaylistModel from '../../playliist/PlaylistModel';
import {
  deleteTableData,
  getTableDetailsFromTableName,
  saveTableData,
  updateTableData,
} from '../../../services/Tables';
import { getTableColumns } from '../../../services/Columns';
import { getProjectConfig } from '../../../services/Projects';
import { convertBytesToGBs } from '../../../utils/Video';
import { ReactComponent as ListIcon } from '../../../assets/images/list.svg';
import { ReactComponent as CutIcon } from '../../../assets/images/cut.svg';
import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';

const useStyles = makeStyles(formPopupStyle);

const Markers = ({
  videoId,
  videoData,
  seekToStartTime,
  projectId,
  reloadListProp,
  openAddMarker,
  isAddMarkerOpen,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [markerForTrim, setMarkerForTrim] = useState(null);
  const [createTrimPopup, setCreatTrimPopup] = useState(false);
  const [reloadList, setReloadList] = useState(1);
  const [trimMarkerProcessing, setTrimMarkerProcessing] = useState(false);
  const [playlistModel, setPlaylistModel] = useState(false);
  const [actionId, setActionId] = useState(0);
  const [markerDeletePopup, setMarkerDeletePopup] = useState(false);
  const [markerDeleteProcessing, setMarkerDeleteProcessing] = useState(false);
  const [markerTable, setMarkerTable] = useState(null);

  useEffect(() => {
    getTableDetailsFromTableName('playlistItems').then((res) => {
      setMarkerTable(res[0]);
    });
    getVideoMarkers(videoId).then((data) => {
      setRows(data);
    });
  }, [reloadList, reloadListProp]);

  const trimTheMarker = async () => {
    setTrimMarkerProcessing(true);

    const getTableId = await getTableDetailsFromTableName('videos');
    const resColumns = await getTableColumns(getTableId[0].id);
    const allColumns = [];

    resColumns.forEach((obj) => {
      allColumns.push({
        id: obj.id,
        type: obj.type,
        name: obj.name,
        refType: obj.refType,
        relation: obj.relation,
      });
    });

    const getProject = await getProjectConfig();

    const vidRandStr = RandString(6);
    const videoPath = `${markerForTrim.displayName.split(' ').join('_')}_${RandString(5)}`;

    const vidDuration = videoData.durationSeconds;
    const markerStart = Math.round(markerForTrim.startTimeSeconds + videoData.videoStartTime);
    const markerEnd = Math.round(markerForTrim.endTimeSeconds + videoData.videoStartTime);
    const markerDuration = markerEnd - markerStart;
    const markerSize = parseInt(
      (videoData.inBucketBytes * markerForTrim.durationSeconds) / vidDuration,
      10
    );

    const saveData = {
      displayName: markerForTrim.displayName,
      hlsLink: `${getProject.AWSDistributionDomain}/${videoPath}/${vidRandStr}.m3u8`,
      image: `${getProject.AWSDistributionDomain}/${videoPath}/${vidRandStr}_video_image.0000000_video_image.jpg`,
      mp4Link: `${getProject.AWSDistributionDomain}/${videoPath}/${vidRandStr}.mp4`,
      convertedFileSize: markerSize * 2,
      convertedFolderName: videoPath,
      creationDate: createDateString(new Date()),
      creationType: 'TRIMMED',
      sizeInGb: convertBytesToGBs(markerSize),
      duration: convertSecondsToTime(markerDuration),
      trimMarker: { value: markerForTrim.id, label: markerForTrim.name },
      trimVideoSource: { value: videoData.id, label: videoData.name },
    };

    const hiddenFields = {
      videoStartTime: markerStart,
      videoEndTime: markerEnd,
      durationSeconds: markerDuration,
      inBucketBytes: videoData.inBucketBytes,
      originalFileName: videoData.originalFileName,
      activeContentType: 'HLS',
    };

    await saveTableData(saveData, 'videos', allColumns, hiddenFields);
    await updateTableData(markerForTrim.id, { isVideoCreated: true }, 'markers', 'isVideoCreated');

    const mStart = `${convertSecondsToTime(markerStart)}:00`;
    const mEnd = `${convertSecondsToTime(markerEnd)}:00`;

    const jobPayload = {
      videoName: videoPath,
      subFile: vidRandStr,
      videoHeight: videoData.videoHeight,
      videoWidth: videoData.videoWidth,
      trimStartPoint: mStart,
      trimEndPoint: mEnd,
      orgVideoKey: `In/${videoData.originalFileName}`,
      projectId,
    };

    // Create M3U8 and MP4 Trim Jobs
    await createAWSTrimVidJobs(jobPayload);

    setTrimMarkerProcessing(false);
    setMarkerForTrim(null);
    setCreatTrimPopup(false);
    setReloadList(reloadList + 1);
  };

  const deleteMarker = () => {
    setMarkerDeleteProcessing(true);
    deleteTableData([actionId], 'markers', markerTable.id)
      .then(() => {
        setMarkerDeleteProcessing(false);
        setActionId(0);
        setMarkerDeletePopup(false);
        setReloadList(reloadList + 1);
      })
      .catch(() => {
        setMarkerDeleteProcessing(false);
      });
  };

  return (
    <>
      {rows.length === 0 && (
        <Grid className={classes.noMarkersContainer}>
          <LocationOnIcon className={classes.noMarkersIcon} />
          <Typography className={classes.noMarkersTitle}>No Markers Added</Typography>
          <Typography className={classes.noMarkersDescription}>
            New Markers will be shown here
          </Typography>
        </Grid>
      )}
      {rows.length > 0 && (
        <Grid className={classes.markersContainerTitle}>
          <Typography variant="body1"> </Typography>
        </Grid>
      )}
      {rows.map((row, i) => (
        <Grid
          key={row.id}
          container
          className={classes.markerContainer}
          onClick={() => seekToStartTime(row.startTimeSeconds)}
        >
          <Grid item className={classes.markerLeft}>
            <Chip className={classes.markerChip} label={i + 1} />
          </Grid>
          <Grid item className={classes.markerRight}>
            <Grid container>
              <Grid item xs={12} className={classes.markerInfoContainer}>
                <Typography className={classes.markerTitle}>{row.displayName}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.markerTimeRow}>
                <Grid
                  container
                  justify="space-between"
                  align="center"
                  className={classes.markerDurationContainer}
                >
                  <InIcon className={classes.markerInIcon} />
                  <Typography className={classes.markerDurationValue}>{row.startTime}</Typography>
                  <ArrowRightAltIcon className={classes.timeArrowIcon} />
                  <Typography className={classes.markerDurationValue}>{row.endTime}</Typography>
                  <OutIcon className={classes.markerOutIcon} />
                </Grid>
                <Grid container className={classes.markerFullDurationContainer}>
                  <TimerIcon className={classes.clockIcon} />
                  <Typography variant="body1" className={classes.markerFullDurationValue}>
                    {row.duration}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.markerToolsRow}>
                <Grid item className={classes.markerCategoryColumn}>
                  <Typography className={classes.markerCategory}>{row.category}</Typography>
                </Grid>
                <Grid item className={classes.markerToolsColumn}>
                  <Grid align="right">
                    {row.isVideoCreated && (
                      <IconButton size="small" className={classes.markerToolButton}>
                        <CloudDownload className={classes.markerTool} />
                      </IconButton>
                    )}
                    <IconButton
                      size="small"
                      onClick={() => {
                        setMarkerForTrim(row);
                        setPlaylistModel(true);
                      }}
                      className={classes.markerToolButton}
                    >
                      <ListIcon className={classes.markerTool} />
                    </IconButton>
                    {!row.isVideoCreated && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          setMarkerForTrim(row);
                          setCreatTrimPopup(true);
                        }}
                        className={classes.markerToolButton}
                      >
                        <CutIcon className={classes.markerTool} />
                      </IconButton>
                    )}
                    <IconButton
                      size="small"
                      className={classes.markerToolButton}
                      style={{ marginRight: -8 }}
                      onClick={() => {
                        setActionId(row.id);
                        setMarkerDeletePopup(true);
                      }}
                    >
                      <TrashIcon className={classes.markerTool} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      {!isAddMarkerOpen && (
        <Grid container className={classes.mobileAddMarker}>
          <Typography className={classes.mobileAddMarkerTitle} onClick={openAddMarker}>
            Add Marker
          </Typography>
        </Grid>
      )}
      {playlistModel && (
        <PlaylistModel
          onClose={() => {
            setPlaylistModel(false);
            setMarkerForTrim(null);
          }}
          marker={markerForTrim}
        />
      )}

      {createTrimPopup && (
        <ConfirmDialog
          title="Trim Marker"
          message="A new video will be created from the IN and OUT points of the marker. Standard rates will apply."
          onClose={() => {
            setMarkerForTrim(null);
            setCreatTrimPopup(false);
          }}
          approveButtonText="Trim"
          onApprove={trimTheMarker}
          approveProcessing={trimMarkerProcessing}
        />
      )}

      {markerDeletePopup && (
        <ConfirmDialog
          title="Delete Marker"
          message="Are you sure you want to delete this marker? This action can not be undone."
          onClose={() => {
            setActionId(0);
            setMarkerDeletePopup(false);
          }}
          onApprove={deleteMarker}
          approveProcessing={markerDeleteProcessing}
        />
      )}
    </>
  );
};

Markers.propTypes = {
  videoId: PropTypes.string.isRequired,
  videoData: PropTypes.objectOf().isRequired,
  seekToStartTime: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  reloadListProp: PropTypes.string.isRequired,
  openAddMarker: PropTypes.func.isRequired,
  isAddMarkerOpen: PropTypes.bool.isRequired,
};
Markers.defaultProps = {};

export default Markers;
