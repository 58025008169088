// Style for popup
const Style = (theme) => ({
  videoContainer: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
});

export default Style;
