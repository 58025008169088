import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import ListItem from '@material-ui/core/ListItem';
import { renameColumn } from '../../../services/Columns';
import Validations from '../../../utils/Validations';
import formPopupStyle from '../../../theme/styles/components/FormPopup';

const useStyles = makeStyles(formPopupStyle);

// Rename a column
const RenameColumn = ({ rowData, handleSuccess, handleDismiss }) => {
  const classes = useStyles();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    setIsSubmitted(true);

    const saveData = {
      columnId: rowData.id,
      name: data.name,
    };

    renameColumn(saveData)
      .then(() => {
        handleSuccess();
        setIsSubmitted(false);
      })
      .catch(() => {
        // something went wrong
        setIsSubmitted(false);
      });
  };

  const handleClose = () => {
    handleDismiss();
  };

  return (
    <ListItem button className={classes.rowItem}>
      <form id="rename-column" onSubmit={handleSubmit(onSubmit)}>
        <FormControl className={classes.formInputSmall}>
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="name"
            id="name"
            defaultValue={rowData.displayName}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                autoFocus
                label="Field Name"
                value={value}
                onChange={onChange}
                size="small"
                error={!!error}
                helperText={error ? error.message : null}
                type="text"
                variant="outlined"
              />
            )}
          />
        </FormControl>
      </form>

      <Button variant="text" onClick={handleClose} className={classes.rowActionButton}>
        Cancel
      </Button>
      <Button
        type="submit"
        form="rename-column"
        variant="contained"
        color="primary"
        disabled={isSubmitted}
        onClick={handleSubmit}
        className={classes.rowActionButton}
      >
        Rename
      </Button>
    </ListItem>
  );
};

RenameColumn.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  handleSuccess: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default RenameColumn;
