import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Collaborators from './Collaborators';
import Roles from '../../config/Roles';
import Validations from '../../utils/Validations';
import projectShareStyle from '../../theme/styles/components/ShareForm';
import { inviteCollaborators } from '../../services/Auth';
import SnackBarMessage from '../SnackbarMessage';

const useStyles = makeStyles(projectShareStyle);

// Share project section
const ShareProject = ({ handleShareForm, projId, projName }) => {
  const classes = useStyles();

  const [processing, setProcessing] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState({
    show: false,
    message: '',
    type: '',
  });
  const { handleSubmit, control } = useForm();

  const handleClose = () => {
    handleShareForm();
  };

  const onSubmit = (data) => {
    setProcessing(true);
    setSnackbarMessage({
      show: false,
      message: '',
      type: '',
    });

    inviteCollaborators({ ...data, projName, projId })
      .then((res) => {
        setSnackbarMessage({
          show: true,
          message: res.message,
          type: 'success',
        });
        setProcessing(false);
        handleClose();
      })
      .catch(() => {
        setSnackbarMessage({
          show: true,
          message: 'Some error occurred, Please try again.',
          type: 'error',
        });
        setProcessing(false);
      });
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">
        {`${projName} - `}
        Collaborators
        <IconButton
          aria-label="close"
          size="small"
          className={classes.modalCloseButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContentText className={classes.shareFormContent}>
            Invite your team members to collaborate for this project.
          </DialogContentText>

          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="email"
              id="email"
              size="small"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Email Address"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="email"
                />
              )}
            />
          </FormControl>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              name="access"
              id="access"
              size="small"
              defaultValue={Roles.EDITOR.value}
              render={({ field: { onChange, value } }) => (
                <>
                  <TextField
                    select
                    label="Access"
                    id="access"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                  >
                    <MenuItem value={Roles.OWNER.value}>{Roles.OWNER.text}</MenuItem>
                    <MenuItem value={Roles.EDITOR.value}>{Roles.EDITOR.text}</MenuItem>
                    <MenuItem value={Roles.READ_ONLY.value}>{Roles.READ_ONLY.text}</MenuItem>
                  </TextField>
                </>
              )}
            />
          </FormControl>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="message"
              id="message"
              size="small"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Message"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="text"
                />
              )}
            />
          </FormControl>

          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            className={classes.inviteButton}
            disabled={processing}
          >
            Invite
          </Button>
        </form>
        <Collaborators projId={projId} />
      </DialogContent>
      {snackBarMessage.show && <SnackBarMessage {...snackBarMessage} />}
    </Dialog>
  );
};

ShareProject.propTypes = {
  handleShareForm: PropTypes.func.isRequired,
  projId: PropTypes.string.isRequired,
  projName: PropTypes.string.isRequired,
};

export default ShareProject;
