// Style for projects page
const Style = () => ({
  addNewProjectBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    '& button': {
      fontWeight: '600',
    },
  },
});

export default Style;
