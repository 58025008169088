import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import LaunchIcon from '@material-ui/icons/Launch';
import { editProject } from '../../services/Projects';
import Validations from '../../utils/Validations';
import ProjectColors from '../../config/ProjectColors';
import projectFormStyle from '../../theme/styles/components/ProjectForm';

const useStyles = makeStyles(projectFormStyle);

// Update project information
const UpdateProject = ({ handleProjectSettings, handleRefreshList, id, name, color, status }) => {
  const classes = useStyles();
  const hasSubscription = status !== 'Inactive';

  const [selectedColor, setSelectedColor] = useState(color);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    const saveData = {
      name: data.name,
      color: selectedColor,
    };

    editProject(id, saveData)
      .then(() => {
        handleRefreshList();
        setIsSubmitted(false);
      })
      .catch(() => {
        // something went wrong
        setIsSubmitted(false);
      });
  };

  const handleClose = () => {
    handleProjectSettings();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">
        {name} - Settings
        <IconButton
          aria-label="close"
          size="small"
          className={classes.modalCloseButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form id="project-settings" onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={classes.formInput}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="name"
              id="name"
              defaultValue={name}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoFocus
                  label="Project Name"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type="text"
                />
              )}
            />
          </FormControl>

          <DialogContentText className={classes.colorTitle}>Choose Color</DialogContentText>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.colorsContainer}
          >
            <Grid item xs={2}>
              <Button
                className={classes.btnColor1}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR1)}
              >
                {selectedColor === ProjectColors.COLOR1 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor2}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR2)}
              >
                {selectedColor === ProjectColors.COLOR2 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor3}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR3)}
              >
                {selectedColor === ProjectColors.COLOR3 && <CheckIcon />}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.btnColor4}
                variant="contained"
                onClick={() => setSelectedColor(ProjectColors.COLOR4)}
              >
                {selectedColor === ProjectColors.COLOR4 && <CheckIcon />}
              </Button>
            </Grid>
          </Grid>
        </form>

        {hasSubscription && (
          <div className={classes.subscriptionContainer}>
            <DialogContentText className={classes.accountNotice}>
              If you have questions regarding your acount, please&nbsp;
              <a href="mailto:admin@dadabase.com">contact us</a>.
            </DialogContentText>
          </div>
        )}
        {!hasSubscription && (
          <div className={classes.subscriptionContainer}>
            <DialogContentText>
              This project doesn&apos;t have any active subscription. If you want to start using the
              project, you can activate the subscription by paying the subscription amount.
            </DialogContentText>
            <Button variant="contained" color="primary" startIcon={<LaunchIcon />}>
              Activate Subscription
            </Button>
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.updateActions}>
        <Button
          fullWidth
          type="submit"
          form="project-settings"
          className={classes.updateButton}
          variant="contained"
          color="primary"
          disabled={isSubmitted}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UpdateProject.propTypes = {
  handleProjectSettings: PropTypes.func.isRequired,
  handleRefreshList: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default UpdateProject;
