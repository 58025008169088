import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import modalStyle from '../../theme/styles/components/Modal';

const useStyles = makeStyles(modalStyle);

const SelectMarkers = ({ markers, onClose, selectedMarkers, handleChange }) => {
  const [localSelected, setLocalSelected] = useState(selectedMarkers);
  const classes = useStyles();
  const handleValueChange = (e) => {
    const copy = [...localSelected];
    if (e.target.checked === true) {
      copy.push(e.target.id);
    } else {
      const index = copy.indexOf(e.target.id);
      copy.splice(index, 1);
    }
    setLocalSelected(copy);
  };

  const saveChanges = (e) => {
    e.preventDefault();
    const updatedColsArr = [];
    markers.forEach((c) => {
      if (e.target[c.name].checked === true) {
        updatedColsArr.push(c.name);
      }
    });
    handleChange(updatedColsArr);
    onClose();
  };

  const handleSelectUnselectChange = () => {
    if (markers.length === localSelected.length) {
      setLocalSelected([]);
    } else {
      setLocalSelected(markers.map((m) => m.name));
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth classes={{ paper: classes.paper }}>
      <DialogTitle>Shared Markers</DialogTitle>
      <DialogContent className={classes.content}>
        <Box>
          <Typography style={{ marginBottom: 20 }}>
            Select the box next to the markers that will be shared with the video. You can create
            multiple Share Links, each with different Shared Markers.
          </Typography>
          <ListItem dense className={classes.rowItem}>
            <Checkbox
              classes={{ checked: classes.checkbox }}
              size="small"
              type="checkbox"
              checked={localSelected.length === markers.length}
              indeterminate={localSelected.length > 0 && localSelected.length !== markers.length}
              onChange={handleSelectUnselectChange}
            />
            <Typography variant="body2">Select/Unselect All</Typography>
          </ListItem>
          <Divider />
        </Box>
        {markers.length > 0 && (
          <form onSubmit={saveChanges} id="playlist-form">
            <List className="align-flex-start">
              {markers.map((col) => (
                <ListItem key={col.name} dense className={classes.rowItem}>
                  <Checkbox
                    classes={{ checked: classes.checkbox }}
                    size="small"
                    type="checkbox"
                    id={col.name}
                    name={col.name}
                    checked={localSelected.includes(col.name)}
                    onChange={handleValueChange}
                  />
                  <Typography>{col.displayName}</Typography>
                </ListItem>
              ))}
            </List>
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" className={classes.cancelButton}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={markers.length === 0}
          form="playlist-form"
          className={classes.saveButton}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SelectMarkers.propTypes = {
  markers: PropTypes.arrayOf().isRequired,
  selectedMarkers: PropTypes.arrayOf().isRequired,
  onClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};
export default SelectMarkers;
