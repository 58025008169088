// Utility for local storage
export const saveLocalData = (key, value, json = false) => {
  if (json) {
    window.localStorage.setItem(key, JSON.stringify(value));
  } else {
    window.localStorage.setItem(key, value);
  }
};

export const getLocalData = (key, json = false, defaultValue = '') => {
  const value = window.localStorage.getItem(key) || defaultValue;
  if (json) {
    return JSON.parse(value);
  }
  return value;
};
