import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Menubar from '../components/layout/Menubar';
import Footer from '../components/layout/Footer';
import wrapperStyle from '../theme/styles/layout/Projects';

const useStyles = makeStyles(wrapperStyle);

// Layout for projects page
const Projects = ({ pageHeading, children }) => {
  const classes = useStyles();

  useEffect(() => {
    window.document.title = `${process.env.REACT_APP_NAME} - Application`;
  }, []);

  return (
    <>
      <Menubar />
      <Grid className={classes.projectsContainer} container direction="column">
        <Typography color="primary" variant="h1" className={classes.projectsHeader}>
          {pageHeading}
        </Typography>
        <Grid container className={classes.projectsList}>
          {children}
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

Projects.propTypes = {
  children: PropTypes.element.isRequired,
  pageHeading: PropTypes.string.isRequired,
};

export default Projects;
