// Style for playlist section
const Style = () => ({
  mainPreviewPlayer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& .video-js, .playlist-container': {
      position: 'relative',
      minWidth: '300px',
      minHeight: '150px',
      height: 0,
    },
    '& .video-js': {
      flex: 3,
    },
    '& .playlist-container': {
      flex: 1,
      height: 'revert',
    },
    '& .vjs-playlist': {
      margin: 0,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});

export default Style;
