import firebase from 'firebase';
import 'firebase/firestore';

const clientPrjApp = {
  dbPrj: null,
  dbPrjCache: null,
  dbPrjAuth: null,
};

// Destruct remote app
const destructClientApp = () => {
  clientPrjApp.dbPrj = null;
  clientPrjApp.dbPrjCache = null;
  clientPrjApp.dbPrjAuth = null;
};

// Initialize remote app
const initializeClientApp = (conf, name) => {
  destructClientApp();

  try {
    const firebaseProjectApp = firebase.app(`prj-${name}`);
    const firebaseProjectAppCache = firebase.app(`prjCache-${name}`);

    // Initialize firebase services
    clientPrjApp.dbPrj = firebaseProjectApp.firestore();
    clientPrjApp.dbPrjCache = firebaseProjectAppCache.firestore();
    clientPrjApp.dbPrjAuth = firebaseProjectApp.auth();
  } catch (e) {
    const firebaseProjectApp = firebase.initializeApp(conf, `prj-${name}`);
    const firebaseProjectAppCache = firebase.initializeApp(conf, `prjCache-${name}`);

    // Initialize firebase services
    clientPrjApp.dbPrj = firebaseProjectApp.firestore();
    clientPrjApp.dbPrjCache = firebaseProjectAppCache.firestore();
    clientPrjApp.dbPrjAuth = firebaseProjectApp.auth();

    // Connect services with emulator
    if (window.location.hostname === 'localhost') {
      clientPrjApp.dbPrjAuth.useEmulator('http://localhost:8083');
      clientPrjApp.dbPrj.useEmulator('localhost', 8082);
      clientPrjApp.dbPrjCache.useEmulator('localhost', 8082);
    }

    clientPrjApp.dbPrjCache.enablePersistence().catch((err) => {
      if (err.code === 'failed-precondition') {
        // eslint-disable-next-line no-console
        // console.log(
        //   'Multiple tabs open, persistence can only be enabled in one tab at a a time.'
        // );
      } else if (err.code === 'unimplemented') {
        // eslint-disable-next-line no-console
        // console.log(
        //   'The current browser
        // does not support all of the features required to enable persistence.'
        // );
      }
    });
  }
};

export { initializeClientApp, clientPrjApp };
