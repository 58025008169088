const Style = (theme) => ({
  footer: {
    backgroundColor: '#000',
    [theme.breakpoints.down(theme.breakpoints.values.col)]: {
      marginTop: '60px',
    },
  },
  footerWrapper: {
    position: 'relative',
    display: 'flex',
    width: '85%',
    maxWidth: '1280px',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingTop: '120px',
    paddingBottom: '0px',
    WebkitBoxOrient: 'vertical',
    WebkitBoxDirection: 'normal',
    flexDirection: 'column',
    WebkitBoxAlign: 'stretch',
    alignItems: 'stretch',
    borderBottom: '1px solid #40454f',
  },
  footerUp: {
    display: 'grid',
    paddingBottom: '45px',
    gridAutoColumns: '1fr',
    gridColumnGap: '120px',
    gridRowGap: '16px',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    borderBottom: '1px solid #40454f',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
  footerDown: {
    display: 'flex',
    paddingTop: '40px',
    paddingBottom: '20px',
    WebkitBoxPack: 'justify',
    justifyContent: 'space-between',
    WebkitBoxAlign: 'start',
    alignItems: 'flex-start',
    color: 'hsla(0, 0%, 100%, 0.8)',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  footerLogoContainer: {
    display: 'flex',
  },
  footerLogo: {
    width: '228px',
    maxWidth: '100%',
  },
  footerLinks: {
    display: 'flex',
    WebkitBoxPack: 'end',
    WebkitJustifyContent: 'flex-end',
    MsFlexPack: 'end',
    justifyContent: 'flex-end',
    gridAutoColumns: '1fr',
    MsGridColumns: '1fr 1fr 1fr',
    gridTemplateColumns: '1fr 1fr 1fr',
    MsGridRows: 'auto',
    gridTemplateRows: 'auto',
  },
  footerLinksRow: {
    display: 'flex',
    marginRight: '40px',
    WebkitBoxOrient: 'vertical',
    WebkitBoxDirection: 'normal',
    flexDirection: 'column',
    WebkitBoxAlign: 'start',
    alignItems: 'flex-start',
  },
  footerLink: {
    display: 'block',
    marginBottom: '18px',
    WebkitTransition: 'color 200ms ease',
    transition: 'color 200ms ease',
    color: '#fff',
    fontSize: '16px',
    textDecoration: 'none',
    '&:hover': {
      color: '#c7cad1',
      textDecoration: 'none',
    },
  },
  socialIconsContainer: {
    display: 'flex',
    WebkitBoxPack: 'start',
    justifyContent: 'flex-start',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '8px',
    },
  },
  socialItem: {
    display: 'flex',
    marginRight: '28px',
    WebkitBoxOrient: 'vertical',
    WebkitBoxDirection: 'normal',
    flexDirection: 'column',
    WebkitBoxPack: 'center',
    justifyContent: 'center',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    transition: 'transform 200ms ease, -webkit-transform 200ms ease',
    '&:hover': {
      transform: 'translate(0px, -3px)',
    },
  },
});

export default Style;
