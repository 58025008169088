import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import projectStyle from '../../theme/styles/components/Project';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';

const useStyles = makeStyles(projectStyle);

// Project item section
const EmptyProject = ({ handleNewProject }) => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.projectItem} onClick={handleNewProject}>
        <CardContent className={classes.cardNameReset}>
          <Grid container className={classes.addButton}>
            <PlusIcon className={classes.addIcon} alt="plus icon" />
            <Typography color="textPrimary" variant="body1">
              Add Project
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

EmptyProject.propTypes = {
  handleNewProject: PropTypes.func.isRequired,
};

export default EmptyProject;
