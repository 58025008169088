/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import PublicIcon from '@material-ui/icons/Public';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { getSharedItems } from '../../../services/ShareItem';
import { getVideoMarkers } from '../../../services/Markers';
import { deleteTableData, getTableDetailsFromTableName } from '../../../services/Tables';
import EditShare from '../../share/EditShare';
import ConfirmDialog from '../../ConfirmDialog';
import formPopupStyle from '../../../theme/styles/components/ShareLink';
import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';

const useStyles = makeStyles(formPopupStyle);

const ShareRow = ({
  rowData,
  tableId,
  columns,
  isVideoShare,
  reloadListProp,
  openAddShare,
  isAddShareOpen,
}) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [reloadRows, setReloadRows] = useState(false);

  const [allMarkers, setAllMarkers] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [showEditShare, setShowEditShare] = useState(false);

  const [showDeleteShareConfirmation, setShowDeleteShareConfirmation] = useState(false);
  const [shareDeleteProcessing, setShareDeleteProcessing] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  useEffect(() => {
    setSelectedRow(null);
    setShowEditShare(false);
    getSharedItems(rowData.id, tableId).then((data) => {
      setRows(data);
    });

    if (isVideoShare) {
      getVideoMarkers(rowData.id).then((data) => {
        setAllMarkers(data);
      });
    }
  }, [reloadRows, reloadListProp]);

  const openShare = async (url) => {
    window.open(url);
  };

  const handleDeleteShare = (row) => {
    setSelectedRowId(row.id);
    setShowDeleteShareConfirmation(true);
  };

  const closeShareEdit = () => {
    setSelectedRow(null);
    setShowEditShare(false);
  };

  const successShareEdit = () => {
    setReloadRows(!reloadRows);
    setSelectedRow(null);
    setShowEditShare(false);
  };

  const deleteShareCallback = async () => {
    setShareDeleteProcessing(true);
    const shareTableConfig = await getTableDetailsFromTableName('sharedItems');
    await deleteTableData([selectedRowId], 'sharedItems', shareTableConfig[0].id);
    setShareDeleteProcessing(false);
    setSelectedRowId(null);
    setShowDeleteShareConfirmation(false);

    setReloadRows(!reloadRows);
  };

  return (
    <>
      {rows.length === 0 && (
        <Grid className={classes.noLinksContainer}>
          <OpenInNewIcon className={classes.noLinksIcon} />
          <Typography className={classes.noLinksTitle}>No Share Links Added</Typography>
          <Typography className={classes.noLinksDescription}>
            New share links will be shown here
          </Typography>
        </Grid>
      )}
      {rows.map((row) => (
        <Grid key={row.id} container className={classes.shareContainer}>
          <Grid item xs={9}>
            <Grid item xs={12}>
              <Typography className={classes.shareTitle}>{row.displayName}&#160;</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container alignItems="center" style={{ height: '100%' }}>
                    <Typography className={classes.sharedInfoValue}>
                      {row.sharedBy}
                      <span className={classes.sharedInfoDot}>·</span>03/02/2022
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid className={classes.chipSmallColumn}>
                    <IconButton aria-label="info" className={classes.shareColumns}>
                      <InfoIcon />
                      {row.sharedColumns.length}
                    </IconButton>
                    <IconButton aria-label="markers" className={classes.shareMarkers}>
                      <LocationOnIcon />
                      {row.sharedMarkers.length}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.shareToolsColumn}>
            <Grid container justify="flex-end">
              {row.privacy === 'Password' && <LockIcon className={classes.lockIcon} />}
              {row.privacy === 'Public' && <PublicIcon className={classes.publicIcon} />}
            </Grid>
            <Grid container justify="flex-end">
              <IconButton
                size="small"
                onClick={() => openShare(row.url)}
                className={classes.linkToolButton}
              >
                <OpenInNewIcon className={classes.linkTool} />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleDeleteShare(row)}
                className={classes.linkToolButton}
              >
                <TrashIcon className={classes.linkTool} />
              </IconButton>
              {showEditShare && selectedRow?.id === row.id && (
                <EditShare
                  shareData={row}
                  handleDismiss={closeShareEdit}
                  handleSuccess={successShareEdit}
                  columns={columns}
                  allMarkers={allMarkers}
                  rowData={rowData}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
      {!isAddShareOpen && (
        <Grid container className={classes.mobileAddShare}>
          <Typography className={classes.mobileAddShareTitle} onClick={openAddShare}>
            Add Share Link
          </Typography>
        </Grid>
      )}

      {showDeleteShareConfirmation && (
        <ConfirmDialog
          title="Delete Share"
          message="Are you sure you want to delete this marker? This action can not be undone."
          onClose={() => {
            setSelectedRowId(null);
            setShowDeleteShareConfirmation(false);
          }}
          onApprove={deleteShareCallback}
          approveProcessing={shareDeleteProcessing}
        />
      )}
    </>
  );
};

ShareRow.propTypes = {
  rowData: PropTypes.objectOf().isRequired,
  tableId: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf().isRequired,
  isVideoShare: PropTypes.bool.isRequired,
  reloadListProp: PropTypes.string.isRequired,
  openAddShare: PropTypes.func.isRequired,
  isAddShareOpen: PropTypes.bool.isRequired,
};
export default ShareRow;
