// Style for projects layout
const Style = (theme) => ({
  projectsContainer: {
    position: 'relative',
    bottom: '206px',
    display: 'flex',
    minHeight: '500px',
    marginTop: '80px',
    alignItems: 'center',
    color: '#fff',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(theme.breakpoints.values.col)]: {
      height: 'auto',
    },
  },
  projectsHeader: {
    marginBottom: '32px',
    color: '#000',
    fontSize: '32px',
    fontWeight: 'bold',
    boxSizing: 'border-box',
    WebkitFontSmoothing: 'auto',
  },
  bottom: {
    position: 'static',
    marginTop: '-78px',
  },
  projectsList: {
    display: 'grid',
    gridAutoColumns: '1fr',
    gridTemplateRows: 'auto auto',
    maxWidth: '1000px',
    minWidth: '1000px',
    gridColumnGap: '33px',
    gridRowGap: '48px',
    MsGridColumns: '1fr 1fr 1fr',
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down(theme.breakpoints.values.col)]: {
      minWidth: '0',
      display: 'flex',
      width: '660px',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      margin: 'auto',
    },
    [theme.breakpoints.down(theme.breakpoints.values.lowMd)]: {
      width: '330px',
    },
  },
});

export default Style;
