import { auth } from '../config/Firebase';

// Get project access role of current user
const getProjectAccess = (projectAccess) => {
  let access = '-';

  for (let i = 0; i < projectAccess.length; i += 1) {
    if (projectAccess[i].id === auth.currentUser.uid) {
      access = projectAccess[i].role;
      break;
    }
  }

  return access;
};

export default getProjectAccess;
