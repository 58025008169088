// Get date time utilities
export const getCurrentTimestamp = () => Math.ceil(Date.now() / 1000);

export const getDateFromTimestamp = (val) => new Date(val * 1000);

export const convertSecondsToTime = (val) => {
  const sec = parseInt(val, 10);
  if (sec <= 0) return '00:00:00';

  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;
  let finalValue = '00:';

  if (hours > 0) {
    finalValue = hours < 10 ? `0${hours}:` : `${hours}:`;
  }
  finalValue += minutes < 10 ? `0${minutes}` : `${minutes}`;
  finalValue += seconds < 10 ? `:0${seconds}` : `:${seconds}`;

  return finalValue;
};

export const createDateString = (date) => {
  if (!date) return '';

  const dateGmtStr = `${date.toString().substring(0, 15)} 00:00:00 GMT`;
  const dateStr = new Date(dateGmtStr).toISOString().substring(0, 10);

  return dateStr === '1970-01-01' ? '' : dateStr;
};
