import React from 'react';
import { Grid, makeStyles, Link } from '@material-ui/core';
import componentStyles from '../../theme/styles/layout/Footer';
import { ReactComponent as FacebookIcon } from '../../assets/images/fb.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/twitter.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/instagram.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/images/linkedin.svg';
import logoWhite from '../../assets/images/dadabase-white.png';

const styles = makeStyles(componentStyles);
const homeURL = '/';
const caseStudiesURL = '/';
const pricingURL = '/';
const contactURL = '/';
const privacyURL = '/';

const Footer = () => {
  const classes = styles();
  return (
    <Grid container className={classes.footer}>
      <Grid container className={classes.footerWrapper}>
        <Grid item className={classes.footerUp}>
          <Grid item className={classes.footerLogoContainer}>
            <Link href={homeURL}>
              <img src={logoWhite} alt="Dadabase logo" className={classes.footerLogo} />
            </Link>
          </Grid>
          <Grid item className={classes.footerLinks}>
            <Grid item className={classes.footerLinksRow}>
              <Link href={homeURL} className={classes.footerLink}>
                Home
              </Link>
              <Link href={caseStudiesURL} className={classes.footerLink}>
                Case Studies
              </Link>
              <Link href={pricingURL} className={classes.footerLink}>
                Pricing
              </Link>
              <Link href={contactURL} className={classes.footerLink}>
                Contact
              </Link>
              <Link href={privacyURL} className={classes.footerLink}>
                Privacy Policy
              </Link>
            </Grid>
            <Grid item className={classes.footerLinksRow}>
              <Link href="https://app.dadabase.com" className={classes.footerLink}>
                Login
              </Link>
              <Link href="https://app.dadabase.com/signup" className={classes.footerLink}>
                Sign Up
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.footerDown}>
          <Grid item>© All rights reserved. Dadabase, Inc.</Grid>
          <Grid item className={classes.socialIconsContainer}>
            <Link href="https://facebook.com" className={classes.socialItem}>
              <FacebookIcon loading="lazy" alt="" />
            </Link>
            <Link href="https://twitter.com" className={classes.socialItem}>
              <TwitterIcon loading="lazy" alt="" />
            </Link>
            <Link href="https://instagram.com" className={classes.socialItem}>
              <InstagramIcon loading="lazy" alt="" />
            </Link>
            <Link href="https://linkedin.com" className={classes.socialItem}>
              <LinkedInIcon loading="lazy" alt="" />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
