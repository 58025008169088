const styles = (theme) => ({
  paper: {
    background: 'white',
    boxShadow: '0px 4px 34px rgba(0, 0, 0, 0.35)',
    borderRadius: '4px',
    padding: '20px 20px 20px 10px',
  },
  content: {
    '&::-webkit-scrollbar': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#a4a4a4',
    },
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 4px 14px rgb(0 0 0 / 35%)',
    width: '90px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'white',
    filter: 'drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.25))',
    borderRadius: '4px',
    float: 'right',
    minWidth: '80px',
    paddingTop: '3px',
    paddingBottom: '3px',
    marginLeft: '20px',
    height: '44px',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  cancelButton: {
    backgroundColor: 'transparent',
    height: '44px',
    width: '90px',
    fontWeight: '500',
    fontSize: '14px',
    color: theme.palette.grey[800],
  },
  checkbox: {
    '&.Mui-checked': {
      color: '#18657D',
      '&:hover': {
        color: '#18657D',
      },
    },
  },
  rowItem: {
    paddingTop: 3,
    paddingBottom: 3,
    background: theme.palette.grey[100],
    marginBottom: 4,
    paddingLeft: 50,
    borderRadius: 4,
  },
});

export default styles;
