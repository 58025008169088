import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { getTableViews } from '../../services/TableViews';
import CreateView from './views/CreateView';
import RenameView from './views/RenameView';
import DeleteView from './views/DeleteView';
import tabViewOptionsStyle from '../../theme/styles/components/TableViewOptions';

const useStyles = makeStyles(tabViewOptionsStyle);

const TableViewOptions = ({ projectId, tableId, viewId }) => {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSingle, setAnchorElSingle] = useState(null);
  const [customViews, setCustomViews] = useState([]);
  const [currentTab, setCurrentTab] = useState('default');
  const [showAddForm, setShowAddForm] = useState(false);
  const [showRenameForm, setShowRenameForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSingle = (event) => {
    setAnchorElSingle(event.currentTarget);
  };
  const handleCloseSingle = () => {
    setAnchorElSingle(null);
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 'more-opt') {
      handleClick(event);
    } else if (newValue === 'default') {
      setCurrentTab(newValue);
      history.push(`/project/${projectId}/${tableId}/all`);
    } else {
      setCurrentTab(newValue);
      history.push(`/project/${projectId}/${tableId}/${newValue}`);
    }
  };

  useEffect(() => {
    getTableViews(tableId)
      .then((res) => {
        // When views is not passed but it exists then redirect to view tab
        if (viewId === null && res.length > 0) {
          history.push(`/project/${projectId}/${tableId}/${res[0].id}`);
        } else {
          setCustomViews(res);
          if (viewId === 'all') {
            setCurrentTab('default');
          } else {
            setCurrentTab(viewId || 'default');
          }
        }
      })
      .catch(() => {
        setCustomViews([]);
      });
  }, [tableId, viewId, reloadData]);

  const handleShowAddForm = () => {
    setShowAddForm(true);
    handleClose();
  };
  const handleCloseAddForm = () => {
    setShowAddForm(false);
  };
  const handleCloseAddFormSuccess = () => {
    setReloadData(!reloadData);
    setShowAddForm(false);
  };

  const handleShowRenameForm = () => {
    const selectedView = customViews.filter((view) => view.id === viewId);
    if (selectedView.length > 0) {
      setSelectedRecord(selectedView[0]);
      setShowRenameForm(true);
    }
    handleClose();
  };
  const handleCloseRenameForm = () => {
    setShowRenameForm(false);
    setSelectedRecord(null);
  };
  const handleCloseRenameFormSuccess = () => {
    setReloadData(!reloadData);
    setShowRenameForm(false);
    setSelectedRecord(null);
  };

  const handleShowDeleteForm = () => {
    const selectedView = customViews.filter((view) => view.id === viewId);
    if (selectedView.length > 0) {
      setSelectedRecord(selectedView[0]);
      setShowDeleteForm(true);
    }
    handleClose();
  };
  const handleCloseDeleteForm = () => {
    setShowDeleteForm(false);
    setSelectedRecord(null);
  };
  const handleCloseDeleteFormSuccess = () => {
    setReloadData(!reloadData);
    setShowDeleteForm(false);
    setSelectedRecord(null);
    history.push(`/project/${projectId}/${tableId}`);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={classes.root}
      >
        <Grid item xs={8} sm={9} md={10} className={classes.tabArea}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            value={currentTab}
            onChange={handleTabChange}
            classes={{
              flexContainer: classes.tabsFlexContainer,
              indicator: classes.tabsIndicator,
              scroller: classes.tabsScroller,
            }}
          >
            {customViews.map((object) => (
              <Tab
                label={<span>{object.displayName}</span>}
                value={object.id}
                className={classes.tabView}
                key={`tab-view-${object.id}`}
                icon={
                  <ArrowDropDownIcon className={classes.menuTabIcon} onClick={handleClickSingle} />
                }
              />
            ))}
            <Tab label="All" value="default" className={classes.tabViewAll} />

            <Tab
              icon={<AddIcon className={classes.moreTabIcon} />}
              value="more-opt"
              className={classes.moreTabOption}
              aria-controls="table-view-options"
              aria-haspopup="true"
            />
          </Tabs>
          <Menu
            id="table-view-options"
            anchorEl={anchorElSingle}
            keepMounted
            open={Boolean(anchorElSingle)}
            onClose={handleCloseSingle}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleShowRenameForm} disabled={currentTab === 'default'}>
              <ListItemIcon className={classes.menuItemIcon}>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Rename View</Typography>
            </MenuItem>
            <MenuItem onClick={handleShowDeleteForm} disabled={currentTab === 'default'}>
              <ListItemIcon className={classes.menuItemIcon}>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Delete View</Typography>
            </MenuItem>
          </Menu>

          <Menu
            id="table-view-options"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleShowAddForm}>
              <ListItemIcon className={classes.menuItemIcon}>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              <Typography>Add New View</Typography>
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          &nbsp;
        </Grid>
      </Grid>

      {showAddForm && (
        <CreateView
          tableId={tableId}
          handleSuccess={handleCloseAddFormSuccess}
          handleDismiss={handleCloseAddForm}
        />
      )}
      {showRenameForm && (
        <RenameView
          rowData={selectedRecord}
          handleSuccess={handleCloseRenameFormSuccess}
          handleDismiss={handleCloseRenameForm}
        />
      )}
      {showDeleteForm && (
        <DeleteView
          rowData={selectedRecord}
          handleSuccess={handleCloseDeleteFormSuccess}
          handleDismiss={handleCloseDeleteForm}
        />
      )}
    </>
  );
};

TableViewOptions.propTypes = {
  projectId: PropTypes.string.isRequired,
  tableId: PropTypes.string.isRequired,
  viewId: PropTypes.string.isRequired,
};

export default TableViewOptions;
