// Style for menubar
const Style = (theme) => ({
  header: {
    height: '300px',
    backgroundColor: 'gold',
  },
  headerContainer: {
    position: 'relative',
    maxWidth: '1280px',
    paddingTop: '20px',
    paddingBottom: '20px',
    width: '85%',
    marginRight: 'auto',
    marginLeft: 'auto',
    WebkitBoxPack: 'justify',
    justifyContent: 'space-between',
    WebkitBoxAlign: 'center',
    alignItems: 'flex-start',
    marginTop: '6px',
    [theme.breakpoints.down(theme.breakpoints.values.col)]: {
      width: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  appbar: {
    boxShadow: 'none',
    color: theme.palette.textOnM.main,
    background: theme.palette.primary.main,
  },
  appbarMain: {
    background: 'transparent',
    color: theme.palette.textOnL.main,
  },
  toolbar: {
    minHeight: 0,
  },
  title: {
    flexGrow: 1,
    fontSize: '1.1rem',
  },
  menuButton: {
    marginRight: 5,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  tableMenuButton: {
    marginRight: 5,
  },
  logoSmall: {
    marginRight: '20px',
  },
  logo: {
    objectFit: 'contain',
    width: '180px',
    marginRight: '50px',
    [theme.breakpoints.down(theme.breakpoints.values.noLogin)]: {
      width: '148px',
    },
    [theme.breakpoints.down(theme.breakpoints.values.verySmall)]: {
      width: '110px',
      marginRight: '20px',
    },
  },
});

export default Style;
